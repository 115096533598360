<template>
    <div class="overflow-auto">
        <b-carousel
            class="h-100"
            id="carousel-1"
            v-model="slide"
            :interval="0"
            :controls="images.length > 1"
            :indicators=indicators
            background="#cccccc"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            v-lazy-container="{ selector: 'img', loading: placeholderImage, error: placeholderImage }"
        >
            <b-carousel-slide v-for="(image, index) in images" v-bind:key="index">
                <img
                    v-if="image.type === 2"
                    slot="img"
                    class="d-block img-fluid w-100 h-100 video-preview"
                    width="368"
                    height="245"
                    :data-src="image.src.replace(/\.([a-z0-9]{3,5})$/, '.jpg')"
                    :alt="seller ? `${seller}'s task image ${index + 1}` : 'image slot'"
                    :data-mp4="image.mp4"
                    v-on:click="loadAssetVideo"
                    :id="index"
                >
                <img
                    v-else
                    slot="img"
                    class="d-block img-fluid h-100 w-100"
                    width="368"
                    height="245"
                    :data-src="image.src"
                    :alt="seller ? `${seller}'s task image ${index + 1}` : 'image slot'"
                    :id="index"
                >
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
import config from '../../config'

export default {
  name: 'Carousel',
  props: {
    video: String,
    images: Array,
    indicators: {
      required: false,
      type: Boolean,
      default: false
    },
    placeholderImage: {
      required: false,
      type: String,
      default: 'https://images.anytask.com/image/upload/c_scale,h_220,w_330/c_scale,e_blackwhite,l_urbiknsbdw7r5mpntg5w,o_35,w_150/v1580733311/uerbsfwhzr3lsgstyv0h.png'
    },
    taskHash: {
      require: true,
      type: String
    },
    seller: {
      require: false,
      type: String
    }
  },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
      this.pauseVideo()
    },
    onSlideEnd (slide) {
      this.sliding = false
      this.pauseVideo()
    },
    pauseVideo () {
      let x = document.getElementsByTagName('video')
      for (let i = 0; i < x.length; i++) {
        x[i].pause()
      }
    },
    loadAssetVideo (slide) {
      slide.stopPropagation()
      slide.preventDefault()

      if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'videoPlay'
        })
      }

      let video = document.createElement('video')
      video.setAttribute('id', 'video_' + this.taskHash + '_' + slide.target.id)
      video.src = slide.target.dataset.mp4
      video.autoplay = true
      video.controls = true
      video.addEventListener('ended', function (el) {
        el = el.target
        el.style.opacity = '0'
        setTimeout(function () {
          el.parentNode.removeChild(el)
        }, 500)
      }, false)
      video.addEventListener('click', function (el) {
        el.stopPropagation()
        el.preventDefault()
      }, false)
      video.addEventListener('play', function (slide) {
        let x = document.getElementsByTagName('video')
        for (let i = 0; i < x.length; i++) {
          if (x[i].id !== slide.target.id) {
            x[i].pause()
          }
        }
      }, false)
      slide.target.parentNode.appendChild(video)
    }
  }
}
</script>

<style lang="scss">

    video {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition: opacity 300ms linear;
    }
</style>

<style lang="scss" scoped>

    ::v-deep #carousel-1 {
      .carousel-inner {
        height: 100%;

        .carousel-item {
          height: 100%;
        }
      }
    }
</style>
