<template>
  <b-modal id="advanced-search" ref="advanced-search" size="xl" hide-header hide-footer no-close-on-backdrop>
    <div class="d-flex flex-column p-4" v-if="modalVisible">
      <h1-tangerine :title="$t('advanced_search.title')" class="px-0 px-md-3 mb-md-3"/>
      <div @click="$bvModal.hide('advanced-search')" class="close position-absolute cursor-pointer">
        <i class="fa-solid fa-xmark" aria-hidden="true"></i>
      </div>
      <!-- fields -->
      <b-container class="bv-example-row px-0 px-md-2" v-if="categories">

        <!--phrases-->
        <b-row class="d-flex flex-column flex-md-row align-items-center mb-4">
          <b-col cols="12" md="3">{{$t('advanced_search.phrases')}}</b-col>
          <b-col cols="12" md="9"><b-form-input v-on:change="getTotal" v-model="terms" type="text" class="d-flex w-100 py-2 px-2"/></b-col>
        </b-row>

        <!--words-->
        <b-row class="d-flex flex-column flex-md-row align-items-center mb-2">
          <b-col cols="12" md="3">{{$t('advanced_search.exact')}}</b-col>
          <b-col cols="12" md="9"><b-form-input type="text" v-model="phrase" class="d-flex w-100 py-2 px-2"/>
            <b-button class="addPhrase position-absolute py-1" @click="addNewExactOrPhrase" :class="shakeAnimation ? 'shake' : ''">{{$t('advanced_search.add')}}</b-button>
          </b-col>
        </b-row>
        <b-row class="d-flex flex-column flex-md-row align-items-center" :class="this.phrases.length === 0 ? 'mb-3' : 'mb-2'">
          <b-col cols="12" md="3"></b-col>
          <b-col cols="12" md="9">
            <ul class="d-flex flex-row m-0 p-0 wordOrPhrase">
              <transition-group name="wordOrPhrase-fade" mode="out-in">
                <li v-for="(phrase) in phrases" :key="phrase" class="d-flex flex-row align-items-center">
                  {{ phrase }}
                  <span class="cursor-pointer" @click="deleteRow(phrase)"><i class="fa-solid fa-xmark ml-1" aria-hidden="true"></i></span>
                </li>
              </transition-group>
            </ul>
          </b-col>
        </b-row>

        <!--categories-->
        <b-row class="d-flex flex-column flex-md-row align-items-center mb-4">
          <b-col cols="12" md="3">{{$t('advanced_search.category')}}</b-col>
          <b-col cols="12" md="9">
            <select class="d-flex w-100 py-2 px-2" v-model="parentCategoryResult" @change="categorySelect($event.target.selectedIndex)">
              <option :value="null">{{$t('advanced_search.default_category')}}</option>
              <option v-for="(category, index) in categoriesFiltered" :key="index" :value="category.parent.slug">
                {{category.parent.name}}
              </option>
            </select>
          </b-col>
        </b-row>

        <!--sub-categories-->
        <b-row class="d-flex flex-column flex-md-row align-items-center mb-4" v-if="selectedCategory">
          <b-col cols="12" md="3">{{$t('advanced_search.subcategory')}}</b-col>
          <b-col cols="12" md="9">
            <select class="d-flex w-100 py-2 px-2" v-on:change="getTotal" v-model="subCategoryResult" :disabled="selectedCategory.length === 0">
              <option v-for="(category, index) in selectedCategory" :key="index" :value="category.slug">
                {{category.name}}
              </option>
            </select>
          </b-col>
        </b-row>

        <!--location-->
        <b-row class="d-flex flex-column flex-md-row align-items-center mb-4">
          <b-col cols="12" md="3">{{$t('advanced_search.location')}}</b-col>
          <b-col cols="12" md="9">
            <b-form-select v-on:change="getTotal" v-model="country" :options="sellerLanguages" required />
          </b-col>
        </b-row>

        <!--languages-->
        <b-row class="d-flex align-items-center mb-4">
          <b-col cols="12" md="3">{{$t('advanced_search.language')}}</b-col>
          <b-col cols="12" md="9">
            <select class="d-flex w-100 py-2 px-2" v-model="language" v-on:change="getTotal">
              <option :value="null">{{$t('choose_language')}}</option>
              <option v-for="(location, index) in sellerLocations" :key="index" :value="location.code">
                {{location.name}}
              </option>
            </select>
          </b-col>
        </b-row>

      </b-container>
      <div class="d-flex flex-column mx-auto">
        <b-form-checkbox v-on:change="getTotal" v-if="$store.getters.hasGiftCard()" v-model="giftcard" id="checkbox-1" class="mt-4 mb-5" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
          {{$t('advanced_search.balance')}}
        </b-form-checkbox>
        <b-button v-on:click="onSubmitSearch" class="btn-primary d-flex px-5 py-2 mx-auto w-100 justify-content-center">{{ $t('search') }} {{totalSearch}}</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import apiService from '@/apiService'
import H1Tangerine from '../Global/H1Tangerine'

export default {
  name: 'AdvancedSearch',
  props: {
    categories: {
      type: Array,
      required: true
    },
    advancedSearchVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      categoriesFiltered: null,
      mainCategory: null,
      parentCategoryResult: null,
      selectedIndex: 0,
      subCategory: '',
      sellerLocations: '',
      sellerLanguages: '',
      country: null,
      phrases: [],
      phrase: '',
      shakeAnimation: false,
      modalVisible: false,
      terms: '',
      subCategoryResult: null,
      language: null,
      giftcard: false,
      totalSearch: null
    }
  },
  components: {
    H1Tangerine
  },
  created () {
    this.getAllLanguages()
    this.sellerLanguages = [{ value: null, text: this.$t('choose_location') }]
    for (let key in this.$t('country')) {
      this.sellerLanguages.push({ value: key, text: this.$t('country')[key] })
    }
    setTimeout(() => {
      this.modalVisible = true
    }, 1)
  },
  mounted () {
    this.categoriesFiltered = { ...this.categories }
    for (let i = 0; i < this.categoriesFiltered.length; i++) {
      this.categoriesFiltered[i].child = this.categoriesFiltered[i].child.filter((category) => !category.divider)
    }
  },
  methods: {
    categorySelect (selectedIndex) {
      this.mainCategory = this.parentCategoryResult !== null ? selectedIndex : null
      this.subCategoryResult = null
    },
    getAllLanguages () {
      apiService.getAllLanguages().then((data) => {
        this.sellerLocations = data.data['data']
      }).catch(() => {
      })
    },
    addNewExactOrPhrase () {
      this.shakeAnimation = false
      let phrase = this.phrase
      if (phrase === '' || this.phrases.includes(phrase)) {
        this.phrase = ''
        this.shakeAnimation = true
        setTimeout(() => {
          this.shakeAnimation = false
        }, 1000)
        return
      }
      this.phrases.push(phrase)
      this.phrase = ''
      this.getTotal()
    },
    deleteRow (phrase) {
      this.phrases = this.phrases.filter(item => item !== (phrase))
      this.getTotal()
    },
    getTotal () {
      apiService.getSearchTotal(this.buildUrl(false)).then(response => {
        this.totalSearch = '- ' + response.headers['x-total-count']
      }).catch(() => { })
    },
    buildUrl (short) {
      let params = []

      if (this.terms !== '') {
        params.push('terms=' + this.terms.replace(/[^\w\s+]/gi, ''))
      }

      if (this.phrases.length > 0) {
        if (short) {
          params.push('exact_words[]=' + this.phrases.join(','))
        } else {
          this.phrases.forEach(function (item) {
            params.push('exact_words[]=' + item)
          })
        }
      }

      if (this.subCategoryResult !== null) {
        params.push('categories=' + this.subCategoryResult)
      }

      if (this.country !== null) {
        params.push('seller_location[]=' + this.country)
      }

      if (this.language !== null) {
        params.push('languages=' + this.language)
      }

      if (this.giftcard === 'accepted') {
        params.push('with_balance=true')
      }

      return '?' + (params.join('&'))
    },
    onSubmitSearch () {
      this.$router.push('/search' + this.buildUrl(true))
      this.$bvModal.hide('advanced-search')
    }
  },
  computed: {
    selectedCategory () {
      return this.mainCategory !== null ? this.categoriesFiltered[this.mainCategory - 1].child : []
    }
  }
}
</script>

<style lang="scss">
@include md-up {
  #advanced-search {
    .modal-dialog {
      max-width: 100%;
      padding: 0 1rem;
    }
  }
}
</style>
<style lang="scss" scoped>

  input, select {
    border: $input-border !important;
    min-height: 42px;
  }

  .wordOrPhrase {
    span {
    display: flex;
    flex-wrap: wrap;
      li {
        background: $brand-sky;
        color: white;
        font-size: .75rem;
        padding: 3px 8px;
        border-radius: 10px;
        margin-right: 7px;
        margin-bottom: 7px;
      }
    }
  }
  .addPhrase {
    top: 4px;
    right: 19px;
    &.shake {
      animation: shaker 1s;
    }
  }

  .close {
    right: 25px;
  }

  .wordOrPhrase-fade-enter-active, .wordOrPhrase-fade-leave-active {
    transition: opacity .5s ease-in;
  }
  .wordOrPhrase-fade-enter, .wordOrPhrase-fade-leave-to {
    opacity: 0;
  }

  @include md-up {
    .btn {
      width: auto !important;
    }
  }

  @keyframes shaker {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

</style>
