import App from './App.vue'
import Axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import config from './config'
import router from './router'
import store from './store'
import 'v-owl-carousel'
import Vue from 'vue'
import LightTimeline from 'vue-light-timeline'
import VueI18n from 'vue-i18n'
import VueSweetAlert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueGtm from '@gtm-support/vue2-gtm'
import VueLazyload from 'vue-lazyload'
import VueSocialSharing from 'vue-social-sharing'
import { i18n } from './lang'
import './assets/CSS/style.css'
import './assets/CSS/custom.css'
import './assets/JS/px'
import VueSanitize from 'vue-sanitize'
import VueMoment from 'vue-moment'
import InstantSearch from 'vue-instantsearch'
import apolloProvider from './appsync'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Tracker from '/src/utilities/tracker'

let defaults = VueSanitize.defaults
defaults = {
  allowedTags: ['strong', 'em', 'p', 'u']
}
Vue.use(VueSanitize, defaults)
Vue.use(BootstrapVue)
Vue.use(LightTimeline)
Vue.use(VueSocialSharing)
Vue.use(VueI18n)
Vue.use(VueSweetAlert2)
Vue.use(InstantSearch)
Vue.use(VueReCaptcha, { siteKey: config.recapture })
Vue.use(Tracker)
Vue.use(VueLazyload, {
  adapter: {
    loaded ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
      // If we're a carousel, load all images in this set
      if (el.parentNode.classList.contains('carousel-item')) {
        const children = el.parentNode.parentNode.children
        for (let i = 0; i < children.length; i++) {
          let img = children[i].getElementsByTagName('img')[0]
          if (typeof img !== 'undefined' && img.getAttribute('lazy') !== 'loaded') {
            img.src = img.getAttribute('data-src')
            img.setAttribute('lazy', 'loaded')
          }
        }
      }
    }
  }
})

const moment = require('moment')
if (localStorage.getItem('language')) {
  switch (localStorage.getItem('language')) {
    case 'kh':
      moment.locale('km')
      break
    case 'zh':
      moment.locale('zh-cn')
      break
    case 'lk':
      moment.locale('si')
      break
    case 'vn':
      moment.locale('vi')
      break
    default:
      moment.locale(localStorage.getItem('language'))
  }
}
Vue.use(VueMoment, {
  moment
})
Vue.prototype.moment = moment
Vue.config.productionTip = false
Vue.prototype.$http = Axios
const token = localStorage.getItem('accessToken')
const isProd = process.env.NODE_ENV === 'production'

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

window.dataLayer = window.dataLayer || []
function gtag () { window.dataLayer.push(arguments) }
gtag('consent', 'default', {
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'ad_storage': 'denied',
  'analytics_storage': 'denied',
  'wait_for_update': 500
})

// Google Tag Manager
if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
  let gtmQueryParams = {}
  if (config.google_tag_manager_auth) {
    gtmQueryParams['gtm_auth'] = config.google_tag_manager_auth
  }
  if (config.google_tag_manager_preview) {
    gtmQueryParams['gtm_preview'] = config.google_tag_manager_preview
  }
  if (config.google_tag_manager_cookies_win) {
    gtmQueryParams['gtm_cookies_win'] = config.google_tag_manager_cookies_win
  }

  Vue.use(VueGtm, {
    id: config.google_tag_manager,
    queryParams: gtmQueryParams,
    debug: !isProd,
    vueRouter: router,
    ignoredViews: ['admin', 'admin_tasks', 'admin_task', 'admin_users', 'admin_user', 'admin_disputes', 'admin_dispute']
  })
}

new Vue({
  router: router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
