<template>
  <div>
    <Confetti :show="display"/>
    <b-modal ref="order-notification-modal" hide-backdrop centered hide-header hide-footer id="order-modal" class="d-flex justify-content-center" @hidden="display = false">
      <span class="close position-absolute" @click="hide()">
        <i class="fa-solid fa-xmark" aria-hidden="true"></i>
      </span>
      <div class="d-flex text-align-center mb-2 mt-4">
        <span class="icon mx-auto">{{ icon }}</span>
      </div>
      <div class="d-flex flex-column text-center my-2">
        <h1 class="font-weight-bold">{{ title }}</h1>
        <span>{{ content }}</span>
      </div>
      <div class="d-flex flex-column flex-sm-row justify-content-center pb-0 pb-sm-4 pb-md-2 pt-4 px-0 px-sm-2 px-md-5 buttons">
        <b-button class="mr-sm-2 mb-3 mb-sm-0" @click="hide()">{{ button.close }}</b-button>
        <b-button class="cta ml-sm-2" :to="link" @click="hide()">{{ button.view }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Confetti from '../Global/Confetti'

export default {
  name: 'OrderNotificationModal',
  components: { Confetti },
  data () {
    return {
      title: '',
      icon: '🎉',
      content: '',
      link: '',
      button: {
        close: this.$t('new_order_modal.dismiss'),
        view: this.$t('view_orders_button')
      },
      display: false
    }
  },
  mounted () {
  },
  methods: {
    hide () {
      this.display = false
      this.$refs['order-notification-modal'].hide()
    },
    show () {
      this.display = true
      this.$refs['order-notification-modal'].show()
    },
    setTitle (val) {
      this.title = val
    },
    setIcon (val) {
      this.icon = val
    },
    setContent (val) {
      this.content = val
    },
    setLink (val) {
      this.link = val
    },
    setButtons (dismiss, view) {
      this.button.close = dismiss
      this.button.view = view
    }
  }
}
</script>

<style lang="scss">
  #order-modal {
    .close {
      top: 20px;
      right: 20px;
      cursor: hand;
    }
    .modal-body {
      padding: 1rem;
      .cta {
        background: $brand-sky;
        border: $brand-sky;
      }
      .icon {
        font-size: 5rem;
        animation: heartbeat .5s linear
      }
      .buttons {
        .btn {
          width: 100%;
        }
      }
    }
    .modal-dialog {
      max-width: 600px;
    }
    .modal-content {
      border: 0;
      box-shadow: 0 10px 31px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
  }

  @include md-up {
    #order-modal {
      .modal-body {
        padding: 2.5rem;
      }
    }
  }
</style>
