<template>
  <div :class="['breadcrumb small', rootClass, positionAbsolute ? 'position-absolute' : '']">
    <ul :class="containerClass">
      <li v-for="(breadcrumb, idx) in breadcrumbList" :key="idx" :class="[{'linked': !!breadcrumb.link}, childClass]">
        <router-link :to="breadcrumb.link" v-if="!!breadcrumb.link" class="uppercase">
          {{ breadcrumb.name }}
        </router-link>
        <p class="m-0" v-else> {{ breadcrumb.name }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    breadcrumbList: Array,
    rootClass: String,
    containerClass: String,
    childClass: String,
    positionAbsolute: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      listItems: []
    }
  },
  metaInfo () {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': this.listItems
          }
        }
      ]
    }
  },
  mounted () {
    this.breadcrumbList.forEach((breadcrumb, i) => {
      if (breadcrumb.link) {
        this.listItems.push({
          '@type': 'ListItem',
          'position': i + 1,
          'name': breadcrumb.name,
          'item': window.location.protocol + '//' + window.location.hostname + breadcrumb.link
        })
      }
    })
  }
}
</script>

<style scoped>
  .breadcrumb {
    z-index: 3;
    background-color: transparent !important;
  }
  a, p, a:hover {
    text-decoration: unset !important;
    color: #888;
  }
  ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul > li {
    display: flex;
    float: left;
    height: 10px;
    width: auto;
    cursor: default;
    align-items: center;
    text-transform: uppercase;
  }
  ul > li:not(:last-child)::after {
    content: ' ';
    float: right;
    margin: 10px;
    cursor: default;
    width: 0;
    height: 0;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: 6px solid #888;
  }
  .linked {
    cursor: pointer;
  }
</style>
