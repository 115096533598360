import router from '../../router'
import apiService from '../../apiService'

export default {
  state: {
    loggingIn: false,
    loginError: null,
    loginStatus: null
  },
  getters: {
  },
  mutations: {
    loginStart: (state) => {
      state.loggingIn = true
      state.loginError = null
      state.loginStatus = 200
    },
    loginStop: (state, errorMessage) => {
      state.loggingIn = false
      state.loginError = errorMessage
    },
    loginStatus: (state, status) => {
      state.loginStatus = status
    }
  },
  actions: {
    doLoginAfterResetPassword ({ dispatch, commit }, response) {
      localStorage.setItem('accessToken', response.response.data.data.token)
      commit('updateAccessToken', response.response.data.data.token)
      localStorage.setItem('refreshToken', response.response.data.data.refresh_token)
      commit('updateRefreshToken', response.response.data.data.refresh_token)
      dispatch('processJwt')
      router.push('/#passwordRest')
    },
    doLogin ({ dispatch, commit }, loginData) {
      commit('loginStart')
      return apiService.loginCheck({ ...loginData })
        .then(async response => {
          localStorage.setItem('accessToken', response.data.token)
          localStorage.setItem('refreshToken', response.data.refresh_token)
          commit('loginStatus', response.status)
          commit('loginStop', null)
          commit('updateAccessToken', response.data.token)
          commit('updateRefreshToken', response.data.refresh_token)
          dispatch('processJwt')

          if (localStorage.getItem('utm')) {
            await apiService.patchUser(JSON.parse(localStorage.getItem('utm')), true).then(() => {
              localStorage.removeItem('utm')
            }).catch()
          }

          if (localStorage.getItem('route')) {
            router.push(localStorage.getItem('route'))
            localStorage.removeItem('route')
          }
        })
        .catch(error => {
          commit('loginStop', error.response.data.message)
          commit('loginStatus', error.response.status)
          commit('updateAccessToken', null)
          commit('updateRefreshToken', null)
        })
    },
    doLoginEtn ({ commit }) {
      return apiService.loginSso()
        .then(response => {
          localStorage.setItem('ssoReturnPath', window.location.pathname + window.location.search)
          window.location = response.data.data.login_url
        }).catch(() => { })
    },
    removeLoginError ({ commit }) {
      commit('loginStop', null)
    }
  }
}
