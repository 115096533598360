var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-pagination',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var currentRefinement = ref.currentRefinement;
  var nbPages = ref.nbPages;
  var pages = ref.pages;
  var isFirstPage = ref.isFirstPage;
  var isLastPage = ref.isLastPage;
  var refine = ref.refine;
  var createURL = ref.createURL;
return [_c('div',{staticClass:"d-flex justify-content-center"},[(nbPages > 1)?_c('div',{staticClass:"pb-4 d-flex"},[(!isFirstPage)?_c('b-button',{staticClass:"btn mx-2 border",attrs:{"href":createURL(0),"variant":"light"},on:{"click":[function($event){$event.preventDefault();return refine(0)},_vm.scrollToTop]}},[_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" ‹‹ ")])]):_vm._e(),(!isFirstPage)?_c('b-button',{staticClass:"btn mx-2 border",attrs:{"href":createURL(currentRefinement - 1),"variant":"light"},on:{"click":[function($event){$event.preventDefault();return refine(currentRefinement - 1)},_vm.scrollToTop]}},[_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" ‹ ")])]):_vm._e(),_c('span',{staticClass:"d-flex"},_vm._l((pages),function(page){return _c('b-button',{key:page,staticClass:"btn mx-2 border num",class:_vm.hideButtons(page, currentRefinement, isFirstPage, isLastPage),attrs:{"href":createURL(page),"variant":page === currentRefinement ? 'primary' : 'light'},on:{"click":[function($event){$event.preventDefault();return refine(page)},_vm.scrollToTop]}},[_c('p',{class:page === currentRefinement ? 'text-white mb-0' : 'text-primary mb-0'},[_vm._v(_vm._s(page + 1))])])}),1),(!isLastPage)?_c('b-button',{staticClass:"btn mx-2 border",attrs:{"href":createURL(currentRefinement + 1),"variant":"light"},on:{"click":[function($event){$event.preventDefault();return refine(currentRefinement + 1)},_vm.scrollToTop]}},[_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" › ")])]):_vm._e(),(!isLastPage)?_c('b-button',{staticClass:"btn mx-2 border",attrs:{"href":createURL(nbPages),"variant":"light"},on:{"click":[function($event){$event.preventDefault();return refine(nbPages)},_vm.scrollToTop]}},[_c('p',{staticClass:"text-primary mb-0"},[_vm._v(" ›› ")])]):_vm._e()],1):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }