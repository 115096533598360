<template>
  <b-modal
    id="edit"
    ref="editModal"
    :title="$t('edit_review')"
    hide-footer
    @show="resetModal"
    @hidden="resetModal"
  >
    <div class=" mx-3 pb-3">
      <div id="task-form-error-details" class=" p-1 alert alert-danger text-center m-3" v-if="validation_message_switch">
        <p v-if="validation_message !== ''" class="p-0 m-0">{{validation_message}}</p>
        <p v-if="validation_title !== ''" class="p-0 m-0">{{validation_title}}</p>
      </div>
      <div class="field-heading pt-2">{{$t('reviews_form_rating_heading')}}</div>
      <star-rating
        v-model="rating"
        v-bind:star-size="20"
        v-bind:increment="1"
      />

      <div class="field-heading pt-2 pb-3">{{$t('reviews_form_message_heading')}}</div>

      <b-form-input
          id="textarea"
          v-model="title"
          :placeholder="$t('reviews_placeholder_title')"
          class="m-0 mb-3"
          v-bind:class="{ 'border-danger': (validation_title_switch === true)}"
      />

      <b-form-textarea
        id="textarea"
        v-model="text"
        :placeholder="$t('reviews_placeholder')"
        rows="3"
        max-rows="6"
        class="m-0"
        v-bind:class="{ 'border-danger': (text_validation === true)}"
      />
      <div class="pt-2 small">{{ $t('characters_remaining') }}: <span v-bind:style=" text.length > 400 ? 'color: red;' : 'color: green;' ">{{ 400 - this.text.length }}</span></div>

      <b-form-checkbox
        id="portfolioPermission"
        v-model="portfolioPermission"
        :value="true"
        :unchecked-value="false"
        class="pt-3"
        v-if="buyer"
      >
        {{$t('reviews_portfolio_message')}}
      </b-form-checkbox>
      <b-button v-if="!submit" variant="primary" @click="submitEditReview" :disabled="text.length > 400" block class="w-100 mt-3">{{ $t('review_form_button') }}</b-button>
      <b-button v-else variant="primary" disabled="disabled" block class="w-100 mt-3">
        <b-spinner class="" small label="Small Spinner"/>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import apiService from '../../apiService.js'
import StarRating from 'vue-star-rating'

export default {
  name: 'editReviewModal',
  props: {
    hash: String,
    current_rating: Number,
    current_review: String,
    current_review_title: String,
    current_portfolio: Boolean,
    buyer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    StarRating
  },
  data () {
    return {
      rating: this.current_rating,
      submit: false,
      show: true,
      text: this.current_review,
      title: this.current_review_title,
      text_validation: false,
      validation_message: '',
      validation_rating: '',
      validation_message_switch: false,
      portfolioPermission: this.current_portfolio,
      validation_title_switch: false,
      validation_title: ''
    }
  },
  methods: {
    resetModal () {
      this.text = this.current_review
      this.submit = false
      this.title = this.current_review_title
      this.rating = this.current_rating
      this.portfolioPermission = this.current_portfolio
    },
    submitEditReview () {
      this.validation_message_switch = false
      this.text_validation = false
      this.submit = true
      if (this.text.length === 0) {
        this.text_validation = true
        this.validation_message = this.$t('message_validation')
        this.validation_message_switch = true
        this.submit = false
      } else if (this.rating === null || this.rating < 1 || this.rating > 5) {
        this.validation_message = this.$t('rating_validation')
        this.validation_message_switch = true
        this.submit = false
      } else {
        let uploadData = {
          'rating': this.rating,
          'message': this.text,
          'title': this.title
        }

        if (this.buyer) {
          uploadData.portfolio_permission = this.portfolioPermission ? 1 : 0
        }

        apiService.editReview(this.hash, uploadData).then((response) => {
          this.$bvModal.hide('edit')

          this.$swal({
            title: this.$t('review_edited'),
            text: this.$t('review_edited_successfully'),
            type: 'success',
            confirmButtonText: this.$t('close_button')
          }).then(() => {
            this.$emit('updateData', {
              title: response.data.data[0].title,
              message: response.data.data[0].message,
              rating: response.data.data[0].rating
            })
          })
        }).catch((error) => {
          let switchOn = false
          if (error.response.data.code === 'HTTP-400') {
            if (error.response.data.errors.children.message.errors) {
              this.validation_message = error.response.data.errors.children.message.errors[0]
              this.text_validation = true
              switchOn = true
            } else if (error.response.data.errors.children.title.errors) {
              this.validation_message = error.response.data.errors.children.title.errors[0]
              this.validation_title_switch = true
              switchOn = true
            } else if (error.response.data.errors.children.rating.errors) {
              this.validation_rating = error.response.data.errors.children.message.errors[0]
              switchOn = true
            }
          }
          if (error.response.data.error_code === 'HTTP-409') {
            this.validation_message = error.response.data.error_message
            switchOn = true
          }
          if (switchOn) {
            this.validation_message_switch = true
            this.submit = false
          }
        })
      }
    }
  }
}
</script>

<style>
  label[for=portfolioPermission] {
    font-weight: unset !important;
  }
</style>
