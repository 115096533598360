<template>
  <div>
    <b-navbar-toggle target="mySidenav" @click="openNav()" class="border-0 h-100 d-flex justify-content-center mt-1 d-lg-none">
      <template #default="{ expanded }">
        <i class="fa-solid fa-bars fa-lg text-secondary ml-1"></i>
      </template>
    </b-navbar-toggle>
    <div class="sidenav text-left px-4 d-flex justify-content-between flex-col d-none" id="mySidenav">
      <div>
        <div v-if="mobile_lang_select" class="lang-mobile-title position-absolute d-flex flex-row align-items-center" v-on:click="closeMobileLang()">
          <span class="mx-auto font-weight-bold py-2">{{ $t('select_language') }}</span>
        </div>
        <a v-if="mobile_lang_select" href="javascript:void(0)" class="closeLangBtn position-absolute" v-on:click="closeMobileLang()">&times;</a>
        <div v-else class="d-flex mb-4 justify-content-end align-items-center">
          <a  href="javascript:void(0)" class="closebtn" v-on:click="closeNav()">&times;</a>
        </div>
        <div v-if="categories.length > 0" class="d-flex">
          <NavigationTree @closeMenu="closeNav()" :title="$t('browse_categories')" :subTitles=categories :level=1 class="mb-0 text-secondary" />
        </div>
        <a class="block my-4" v-on:click="closeNav('/become-seller')">{{ $t('why_become_seller') }}</a>
        <a v-if="isLoggedIn() && hasRole('ROLE_SELLER')" class="block my-4" v-on:click="closeNav({ name: 'createTask' })">{{ $t('task_create') }}</a>
        <a class="block my-4" href="https://support.anytask.com" >{{ $t('help_support') }}</a>
        <template v-if="hasCurrencies">
          <MobileCurrencyButton />
        </template>
      </div>
      <div class="s-m-links">
        <ul class="d-flex justify-content-between mt-4 pb-2 pl-md-0">
          <li><a target="_blank" :href="'https://www.facebook.com/' + social.facebook"><i class="fa-brands fa-2x fa-facebook" aria-hidden="true"></i></a></li>
          <li><a target="_blank" :href="'https://twitter.com/' + social.twitter"><i class="fa-brands fa-2x fa-x-twitter" aria-hidden="true"></i></a></li>
          <li><a target="_blank" :href="'https://www.instagram.com/' + social.instagram"><i class="fa-brands fa-2x fa-instagram" aria-hidden="true"></i></a></li>
          <li><a target="_blank" :href="'https://www.linkedin.com/' + social.linkedin"><i class="fa-brands fa-2x fa-linkedin" aria-hidden="true"></i></a></li>
          <li><a target="_blank" :href="'https://www.youtube.com/' + social.youtube"><i class="fa-brands fa-2x fa-youtube" aria-hidden="true"></i></a></li>
          <li><a target="_blank" :href="'https://www.pinterest.co.uk/' + social.pinterest"><i class="fa-brands fa-2x fa-pinterest" aria-hidden="true"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationTree from '../Global/NavigationTree'
import MobileCurrencyButton from './MobileCurrencyButton'
import { mapGetters } from 'vuex'
import config from '../../config'

export default {
  name: 'MobileSideNav',
  components: {
    NavigationTree,
    MobileCurrencyButton
  },
  props: {
    categories: Array
  },
  data () {
    return {
      mobile_lang_select: false,
      social: config.social
    }
  },
  methods: {
    closeMobileLang () {
      this.mobile_lang_select = false
    },
    openNav () {
      document.getElementById('app').classList.add('sidebar-open')
      document.getElementsByTagName('body')[0].classList.add('no-scroll')
    },
    closeNav (path) {
      document.getElementById('app').classList.remove('sidebar-open')
      document.getElementsByTagName('body')[0].classList.remove('no-scroll')
      if (path) {
        // We need to allow for the close transition of 500ms
        const router = this.$router
        setTimeout(function () {
          router.push(path)
        }, 500)
      }
    }
  },
  computed: {
    ...mapGetters(['getCurrencyRates', 'isLoggedIn', 'hasRole']),
    hasCurrencies () {
      return Object.keys(this.getCurrencyRates()).length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.sidenav {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: -100%;
  background-color: #f6f6f6;
  overflow-x: hidden;
  transition: 0.5s;
  font-size: 1.1rem;

  ul {
    list-style: none;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #818181;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #f1f1f1;
    }
  }

  .closebtn, .closeLangBtn {
    font-size: 52px;
  }
  .s-m-links {
    .fa-brands {
      font-size: 2rem;

      &:hover {
        color: #44aae1;
      }
    }
  }
}

@media screen and (max-height: 450px) {
  .sidenav { padding-top: 15px; }
  .sidenav a { font-size: 18px; }
}
</style>
