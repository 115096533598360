<template>
  <div :class="{ 'h-100': !profilePage }">
    <div class="card w-100" v-bind:class="{ 'opacity': selected === false & saveTasksPage, 'profilePage': profilePage, 'h-100': !profilePage, 'featured-task': isFeaturedTask }">
      <router-link :to="{name:'task_info', params:{username: username, slug:slug, queryID: queryID}}" target="_blank" rel="noopener" v-on:click.native="googleTagClickEvent()" class="h-100">
        <!-- card body -->
        <div class="body d-flex flex-row flex-md-column h-100 w-100" :class="{ 'featured-task': isFeaturedTask }">

          <!-- assets -->
          <div class="assets order-2 order-sm-1">
            <!-- assets carousel-->
            <Carousel class="w-100" :class="isFeaturedTask && 'isFeaturedTask'" :images="getImagesFromAssets(carouselImage)" :taskHash="hash" :seller="username"/>
          </div>

          <!-- Task Content -->
          <div class="content d-flex flex-grow-1 flex-column flex-wrap order-1 order-sm-2" :class="isFeaturedTask && 'order-2'">
            <!-- username/avatar M-2nd D-1st -->
            <div v-if="!tasksWidget" class="user text-nowrap flex-nowrap d-flex px-sm-3 pl-3 pt-1 pt-sm-1 flex-row w-100 align-items-center mb-1 mb-sm-2" :class="profilePage ? 'profilePage' : ''">
              <!-- avatar -->
              <div class="avatar d-flex" :class="savedRecentWidget && 'saved-recent-widget'">
                <img v-if="imageIcon" :data-src="formatImageIcon(imageIcon)" :src="formatImageIcon(imageIcon)" alt="" class="user-icon">
                <div v-if="!imageIcon" class="fa-stack icon-placeholder d-flex justify-content-center align-items-center" :class="savedRecentWidget && 'saved-recent-widget'" style="z-index: 1">
                  <i class="fa-solid fa-user text-light"/>
                </div>
              </div>
              <!-- username -->
              <div class="username-container d-flex flex-grow-1 align-items-center justify-content-between">
                  <router-link :to="{name:'profile', params:{username: username}}" class="username ml-1 font-weight-bold text-left" :class="{'no-link': onDashboard}" target="_blank"
                               rel="noopener">
                    {{ username }}
                  </router-link>
                <div v-if="!profilePage && !tasksWidget && !savedRecentWidget" class="actions mr-2 mr-sm-0 d-none d-sm-flex" :class="isFeaturedTask && 'd-flex'">
                  <i v-if="!saveTasksPage" class="fa-solid fa-heart cursor" v-bind:class="{ 'save-toggle' : selected }" v-b-popover.hover.top="popUpText" v-on:click="toggle"></i>
                  <i v-else class="fa-solid fa-heart" v-bind:class="{ 'fa-xmark' : selected }" v-b-popover.hover.top="popUpText" v-on:click="toggle"></i>
                </div>
              </div>
            </div>
            <div v-if="!profilePage" class="d-sm-none position-absolute fav-mob actions bg-white px-2 py-2 rounded-circle align-items-center justify-content-center" :class="[isFeaturedTask ? 'd-none' : 'd-flex d-sm-none', savedRecentWidget ? 'd-flex d-sm-flex' : '']">
              <i v-if="!saveTasksPage" class="fa-solid fa-heart cursor" v-bind:class="{ 'save-toggle' : selected }" v-b-popover.hover.top="popUpText" v-on:click="toggle"></i>
              <i v-else class="fa-solid fa-heart" v-bind:class="{ 'fa-xmark' : selected }" v-b-popover.hover.top="popUpText" v-on:click="toggle"></i>
            </div>
            <!-- labels/reviews M-3rd D-2nd -->
            <div class="labels d-flex align-items-center flex-wrap px-2" :class="[tasksWidget && 'tasks-widget', savedRecentWidget ? 'px-sm-2' : 'px-sm-3']">
            <!--labels-->
              <span v-if="user_online" class=" ml-1 user-online badge badge-success mr-1 mt-1 mt-sm-0" :class="savedRecentWidget ? 'ml-sm-1' : 'ml-sm-0'">{{ $t('online') }}</span>
              <objectLabel v-if="isHeroTask" type="hero" :object="{}" class="mr-1 ml-1 overflow-hidden mt-1 mt-sm-0" :class="savedRecentWidget ? 'ml-sm-1' : 'ml-sm-0'"/>
              <ObjectLabel type="new" class="mt-1 ml-1 mt-sm-0" :class="savedRecentWidget ? 'ml-sm-1' : 'ml-sm-0'" :object="{'review_average': this.rating, 'review_count': this.count, 'user': { 'review_average': this.user_rating, 'review_count': this.user_count }}"></ObjectLabel>
            </div>
            <!-- description M-1st D-3rd -->
            <div class="task-description w-100 d-flex flex-column mb-sm-2 mt-1 mt-sm-0 pl-2 pr-1 px-sm-3" :class="savedRecentWidget || tasksWidget ? 'justify-content-sm-start align-items-sm-start mt-sm-1' : 'align-items-sm-center justify-content-sm-center'" >
              <div class="d-flex justify-content-sm-center copy w-100 px-1 px-sm-0" :class="[tasksWidget ? 'align-items-start' : 'align-items-center', isFeaturedTask && 'justify-content-center', savedRecentWidget && 'justify-content-sm-start']">
                <p class="m-0 bg-xs-danger text-left" :class="[isHeroTask || user_online || count === 0 ? 'line-clamp-2' : '', savedRecentWidget || tasksWidget ? 'text-sm-left' : 'text-sm-center', isFeaturedTask && 'text-center']">{{ description }}</p>
              </div>
            </div>
          </div>

          <!-- reviews and price etc -->
          <div class="delivery-price d-flex flex-row justify-content-between align-items-center pt-1 pt-sm-2 px-3 w-100 order-2" :class="[profilePage ? 'pb-0' : 'pb-2']">
            <!-- reviews -->
            <div class="reviews text-left d-flex align-items-center justify-content-start" v-if="count > 0">
              <div class="fa-solid fa-star checked"></div>
              <div class="rate font-weight-bold p-0">{{ rating.toFixed(1) }}&nbsp;({{ reviewLength() }})</div>
            </div>

            <!-- delivery/pricing -->
            <div class="d-flex flex-row text-right justify-content-end ml-auto">
              <!-- delivery -->

              <div class="delivery d-flex justify-content-start align-items-center font-weight-bold mr-1">
                <i class="fa-solid fa-truck-fast mr-1"></i>
                {{ deliveryTime / 24 }} {{ deliveryTime === 24 ? $t('delivery_time_day') : $t('delivery_time_days') }} /
              </div>
              <!-- pricing -->
              <div class="d-flex price flex-column font-weight-bold text-right" v-if="!requirementsPage">
                <span class="task-price">
                  {{ getDisplayPrice() }}
                </span>
              </div>
              <div v-else>
                <span class="card-price float-right task-price mb-0">{{ getDisplayPrice() }}</span>
              </div>
            </div>

          </div>
          <div v-if="profilePage || tasksWidget" class="d-flex w-100 order-3 px-3 pb-2 justify-content-end mt-auto">
            <div class="text-dark font-weight-normal etn-price">{{ $t('estimated') }} {{ etn }} ETN</div>
          </div>

          <!-- login for saving tasks -->
          <LoginModal v-if="uniqueId !== ''" :reason="$t('login_reason_favourite')" :id="uniqueId + 'loginFavourite'"/>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import LoginModal from '../Global/Login'
import Carousel from '../Global/CardCarousel'
import apiService from '../../apiService.js'
import ObjectLabel from '../Global/Label'
import helper from '../../helper'
import config from '../../config'
import { mapActions } from 'vuex'

export default {
  name: 'Card',
  components: { Carousel, LoginModal, ObjectLabel },
  props: {
    imageIcon: String,
    username: String,
    description: String,
    rating: Number,
    count: Number,
    user_rating: Number,
    user_count: Number,
    user_online: Boolean,
    isHeroTask: Boolean,
    price: Number,
    carouselImage: Object,
    taskImage: String,
    deliveryTime: Number,
    isFeaturedTask: Boolean,
    queryID: {
      type: String,
      required: false
    },
    select: {
      type: Boolean,
      required: false,
      default: false
    },
    imageSizeType: {
      type: Number,
      required: false,
      default () {
        return 2
      }
    },
    index: {
      type: [Number, String],
      required: false,
      default: null
    },
    etn: {
      type: String,
      required: false,
      default: null
    },
    slug: String,
    hash: String,
    saveTasksPage: {
      type: Boolean,
      required: false,
      default: false
    },
    profilePage: {
      type: Boolean,
      required: false,
      default: false
    },
    requirementsPage: {
      type: Boolean,
      required: false,
      default: false
    },
    tasksWidget: {
      type: Boolean,
      required: false,
      default: false
    },
    savedRecentWidget: Boolean,
    sellerInfo: Object,
    position: Number,
    location: String,
    primaryCategorySlug: {
      type: String,
      required: false,
      default: null
    },
    triggerTrackingEvent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    this.textToggle()
    this.uniqueId = this._uid
    this.localUsername = localStorage.getItem('username')
    this.googleTagOnLoad()
  },
  data () {
    return {
      selected: this.select,
      popUpText: '',
      uniqueId: '',
      localUsername: '',
      onDashboard: this.$route.name === 'myTasks',
      window: {
        width: 0,
        height: 0
      },
      assetsCounter: [
        0,
        0
      ],
      placeholderImage: 'https://images.anytask.com/image/upload/c_scale,h_220,w_330/c_scale,e_blackwhite,l_urbiknsbdw7r5mpntg5w,o_35,w_150/v1580733311/uerbsfwhzr3lsgstyv0h.png'
    }
  },
  methods: {
    ...mapActions([
      'addFavourite',
      'removeFavourite'
    ]),
    googleTagOnLoad () {
      if (config.google_tag_manager !== '' && !this.triggerTrackingEvent && config.tracking_enabled === 'true') {
        let vm = this
        this.carouselImage.assets.forEach(function (item) {
          vm.assetsCounter[(item.type - 1)]++
        })
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'eec.impressionView',
          'ecommerce': {
            'impressions': [{
              'id': this.hash,
              'name': this.description,
              'category': this.primaryCategorySlug,
              'list': this.location,
              'brand': this.sellerInfo.hash,
              'position': this.position,
              'dimension5': this.sellerInfo.country_iso,
              'dimension6': this.sellerInfo.member_since,
              'metric2': this.assetsCounter[0],
              'metric3': this.assetsCounter[1],
              'metric4': this.count,
              'metric5': this.rating
            }]
          }
        })
      }
    },
    googleTagClickEvent () {
      if (config.google_tag_manager !== '' && !this.triggerTrackingEvent && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'eec.impressionClick',
          'ecommerce': {
            'click': {
              'actionField': {
                'list': this.location
              },
              'products': [{
                'id': this.hash,
                'name': this.description,
                'category': this.primaryCategorySlug,
                'brand': this.sellerInfo.hash,
                'position': this.position,
                'dimension5': this.sellerInfo.country_iso,
                'dimension6': this.sellerInfo.member_since,
                'metric2': this.assetsCounter[0],
                'metric3': this.assetsCounter[1],
                'metric4': this.count,
                'metric5': this.rating
              }]
            }
          }
        })
      }
    },
    formatImageIcon (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    changeSelected (value) {
      this.selected = value
    },
    getImagesFromAssets (media) {
      if (media.assets.length === 0) {
        return [{
          'type': 1,
          'src': this.placeholderImage
        }]
      }
      let images = []
      media.assets.forEach(function (asset) {
        let img = {}
        img['type'] = asset.type

        if (asset.type === 2) {
          // Movie
          if (this.imageSizeType === 2) {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'movie_large')
          } else {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'movie')
          }

          img['mp4'] = helper.formatCloudinaryUrl(asset.path, 'movie_mp4')
        } else {
          // Image
          if (this.imageSizeType === 2) {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'task_large')
          } else {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'task')
          }
        }
        images.push(img)
      }.bind(this.imageSizeType))
      return images
    },
    toggle (event) {
      event.stopPropagation()
      event.preventDefault()
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show(this.uniqueId + 'loginFavourite')
      } else {
        if (this.selected) {
          this.$swal({
            title: this.$t('remove_task_title'),
            text: this.$t('remove_task_text'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('remove_task_confirm'),
            cancelButtonText: this.$t('remove_task_cancel')
          }).then((result) => {
            if (result.value === true) {
              this.selected = false
              this.textToggle()
              return apiService.removeFavouriteTask(this.hash).then(() => {
                this.removeFavourite(this.hash)
                this.$emit('removedSavedTask', this.index)
              }).catch(() => {
                this.selected = true
                this.textToggle()
                this.$swal(this.$t('remove_task_error_title'), this.$t('remove_task_error_text'), 'error')
              })
            }
          })
        } else {
          this.selected = true
          this.textToggle()
          apiService.addFavouriteTask(this.hash).then(() => {
            this.addFavourite(this.hash)
          }).catch(() => {
            this.selected = false
            this.textToggle()
            this.$swal(this.$t('remove_task_error_title'), this.$t('remove_task_error_text'), 'error')
          })
        }
      }
    },
    textToggle () {
      if (this.selected) {
        this.popUpText = this.$t('favourite_task_unselect')
      } else {
        this.popUpText = this.$t('favourite_task_select')
      }
    },
    reviewLength () {
      const num = this.count
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    },
    getDisplayPrice (currency) {
      return helper.getDisplayPrice(this.price, currency)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .carousel {
  overflow: hidden;
  .carousel-control-next, .carousel-control-prev {
    background: #ffffff;
    height: 30px;
    width: 30px;
    margin-inline: 8px;
    border-radius: 50%;
    opacity: 0.7 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    -webkit-box-shadow: 6px 6px 10px 0 rgba(0,0,0,0.3);
    box-shadow: 0px 0px 10px 0 rgba(0,0,0,0.3);
    &:hover {
      opacity: 1 !important;
    }
    .carousel-control-next-icon {
      margin-right: -2px;
      opacity: 0.7 !important;
      filter: brightness(0%);
      width: 15px;
    }
    .carousel-control-prev-icon {
      margin-left: -2px;
      opacity: 0.7 !important;
      filter: brightness(0%);
      width: 15px;
    }
  }
}

.card {
  border-radius: 1rem !important;
  -webkit-box-shadow: 6px 6px 10px 0 rgba(0,0,0,.06);
  box-shadow: 6px 6px 10px 0 rgba(0,0,0,.06);
  overflow: visible;
  @include sm-up {
    overflow: hidden;
  }
  &.featured-task {
    overflow: hidden;
  }
  &.profilePage {
    border-radius: 0 !important;
  }

  .labels {
    &.tasks-widget {
      margin-top: 10px;
    }
  }

  .fav-mob {
    right: -6px;
    top: -10px;
    width: 32px;
    height: 32px;
    -webkit-box-shadow: 6px 6px 10px 0 rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0 rgba(0,0,0,0.2);
  }
  .body {
    &.featured-task {
      .assets {
        width: 100%;
      }

      .content {
        width: 100%;
        min-height: 128px;
      }

      .task-description, .copy {
        min-height: 48px;
      }
    }

    .assets {
      width: 50%;
    }

    .content {
      width: 50%;
    }
  }

  a {
    color: $brand-navy;
    transition: .2s all;

    &:hover {
      transition: .2s all;
      text-decoration: none;
    }
  }

  .content {
    z-index: 1;

    .user {
      &.profilePage {
        display: none !important;
      }
    }

    .avatar {
      margin-top: -18px;
      margin-left: -8px;

      img, .fa-stack {
        border: 2px solid white;
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
      @include sm-up {
        margin-top: -32px;
        img, .fa-stack {
          border: 3px solid white;
          width: 60px;
          height: 60px;
        }
        &.saved-recent-widget {
          margin-top: -18px;
          img, .fa-stack {
            width: 45px;
            height: 45px;
          }
        }
      }
      .fa-stack {
        background: $brand-navy;
        font-size: 22px;
      }
    }
  }

  .delivery {
    .delivery-icon {
      width: 16px;
    }
  }

  .reviews {
    height: 14px;

    .rate {
      font-size: 14px;
      width: 60px;
    }

    .checked {
      margin-right: 3px;
      color: $star-yellow;
      font-size: 14px;
    }
  }

  .task-description {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 22px;
      font-size: 3.7vw;
      width: 100%;
      &.line-clamp-2 {
        -webkit-line-clamp: 2 !important;
        @media (min-width: 403px) {
          -webkit-line-clamp: 3 !important;
        }
        @include sm-up {
          -webkit-line-clamp: 2 !important;
        }
      }
      @include sm-up {
        font-size: unset;
      }
    }
    @include sm-up {
      height: 60px;
    }
    &.featured-task {
      .copy {
        min-height: 48px;
      }
    }
  }

  .delivery-price {
    align-self: end;
    margin-top: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    flex-wrap: nowrap;
    font-size: 14px;
  }

  .etn-price {
    font-size: 10px;
  }

  .user-online.badge {
    position: relative;
  }

  .actions {
    .fa-heart {
      color: #c3c3c3;
      transition: all .2s;

      &:hover {
        color: $brand-scarlet;
        transition: all .2s;
      }

      &.save-toggle {
        color: $brand-scarlet;
        opacity: 1;
      }
    }

    span {
      font-size: 1.5rem;
    }

    .fa-xmark {
      font-size: 1.25rem;
      color: #c3c3c3;

      &:hover {
        color: $brand-scarlet;
      }
    }
  }
  .user {
    .username-container {
      overflow: hidden;
      text-wrap: nowrap;
      .username {
        color: $light-grey;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@include mobile-mid-up {
  .card {
    .labels {
      display: flex !important;
    }
  }
}

@include sm-up {
  .card {
    .body {
      .content {
        min-height: 128px;
      }

      .assets {
        width: 100%;
      }

      .content {
        width: 100%;

        .user {
          &.profilePage {
            display: flex !important;
          }
        }
      }
    }

    .task-description, .copy {
      min-height: 10px;
    }

    .mobile-labels {
      display: none !important;
    }
  }
}

@include md-up {
  .card {
    .task-description {
      p {
        line-height: 24px;
      }
    }
  }
}

@include md-down {
  .b-popover {
    display: none !important;
  }
}

</style>
