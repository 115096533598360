<template>
  <ais-sort-by
    class="d-lg-none py-0 mobile-sort-by text-left ml-auto order-1"
    :items="[
                        { value: config.advanced_search.index_name, label: $t('tasks_sort_relevance')},
                        { value: config.advanced_search.index_name + '_price_asc', label: $t('tasks_sort_lowest_price')},
                        { value: config.advanced_search.index_name + '_price_desc', label: $t('tasks_sort_highest_price')},
                        { value: config.advanced_search.index_name + '_reviews_desc', label: $t('tasks_sort_best_reviewed')},
                        { value: config.advanced_search.index_name + '_last_active_desc', label: $t('tasks_sort_last_active')}
                      ]"
  >
    <template v-slot="{ items, currentRefinement, refine }">
      <b-dropdown size="lg" no-flip right class="rounded-pill" :variant="items && items.findIndex(item => item.isRefined) > 0 ? 'primary' : 'link'" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <div :class="items && items.findIndex(item => item.isRefined) > 0 ? 'white-text' : 'text-dark'" class="d-flex align-items-center">
            <h5 class="mb-0 text-left mr-1 d-none d-md-block">{{ $t('tasks_sort_by') }}</h5>
            <h5 class="mb-0 text-left">{{ getCurrentSortBy(items, currentRefinement) }}</h5>
            <i class="fa-solid fa-chevron-down fa-xs ml-2"></i>
          </div>
        </template>
        <b-dropdown-item v-for="(item) in items"
                         :key="item.value"
                         href="#"
                         @click.prevent="refine(item.value)"
                         class="d-flex align-items-center"
        >
          <div class="d-flex align-items-center">
            <i v-if="item.label === getCurrentSortBy(items, currentRefinement)" class="fa-solid fa-square-check fa-lg mr-4"></i>
            <i v-else class="fa-regular fa-square fa-lg mr-4"></i>
            {{ item.label }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </ais-sort-by>
</template>

<script>
import config from '../../config'

export default {
  name: 'SortByHolder',
  methods: {
    getCurrentSortBy (items, currentRefinement) {
      const currentItem = items.filter(item => item.value === currentRefinement)
      return currentItem[0].label
    }
  },
  computed: {
    config () {
      return config
    }
  }
}
</script>
