<template>
  <div class="info-box-simple">
    <b-card>
      <b-card-text class="info">
        <div class=" box-title bottomBorder mb-3" v-if="heading !== ''">
            <div class="inner"><h2 class="mb-0 mt-0">{{heading}}</h2>
            </div>
        </div>
        <div v-if="type === 'text' && !editable">
          <ul class="text-left pl-0 mb-0 pb-0 mt-2 pl-0">
              <li><i class="fa-solid fa-location-dot pr-1 pb-2" aria-hidden="true"></i> {{$t("from")}} <strong style="float: right">{{$t('country')[location]}}</strong></li>
              <li><i class="fa-solid fa-user pr-1 pb-2" aria-hidden="true"></i> {{$t("member_since")}} <strong style="float: right">{{join}}</strong></li>
          </ul>
        </div>
        <b-form v-on:submit.prevent="saveChangedValues">
          <div v-if="type === 'text' && editable">
              <ul class="text-left pt-2 pl-0 mb-0 pb-0 mt-2">
                  <span>
                      <b-form-group>
                          <span><i class="fa-solid fa-location-dot pr-1 pb-0" aria-hidden="true"/> {{$t('from')}}
                              <span class="pull-right">
                                  <b-form-select
                                          v-model="country"
                                          :options="$t('country')"
                                          required
                                          size="sm"
                                          class="pull-right input-country"
                                          @change="saveChangedValues"
                                  />
                              </span>
                          </span>
                      </b-form-group>
                  </span>
              </ul>
              <ul class="text-left pt-2 pl-0 mb-0 pb-0 mt-2">
                  <li><i class="fa-solid fa-user pr-1" aria-hidden="true"></i> {{$t('member_since')}} <strong style="float: right">{{join}}</strong></li>
              </ul>
          </div>
          <div v-if="editable && type === 'list'">
              <div v-for="(LanguageRow, index) in LanguageRowsData" :key="index">
                  <span class="float-left pb-3 languageSelect" v-if="LanguageRow.code">
                      <select name="language-group" :id="'language-group-' + userDataType + index" class="form-control" ref="language" @change="refreshData">
                          <option selected="selected" value="default">{{ $t('select')}}{{ $t('language') }}...</option>
                          <option v-for="language in languageOptions" v-bind:key="language.hash" :value="language.code" :selected="language.code === LanguageRow.code">{{ language.name_local }}</option>
                      </select>
                  </span>
                  <span class="float-left pb-3 languageSelect" v-else>
                      <select name="language-group" :id="'language-group-' + userDataType + index" class="form-control" ref="language" @change="refreshData">
                          <option selected="selected" value="default">{{ $t('select')}}{{ $t('language') }}...</option>
                          <option v-for="language in languageOptions" v-bind:key="language.hash" :value="language.code" :selected="language.code === LanguageRow">{{ language.name_local }}</option>
                      </select>
                  </span>
                  <span class="float-right p-1">
                      <a :id="'language-delete-' + userDataType + index" v-on:click="deleteLanguage('language-group-' + userDataType + index)"><i class="fa-solid fa-trash p-2" aria-hidden="true"></i></a>
                  </span>
              </div>
              <span class="pr-2 pr-md-1">
                  <b-button variant="outline-primary" @click="addLanguage" size="sm">{{ $t('add_language') }}</b-button>
              </span>
          </div>
          <div v-if="type === 'list' && !editable">
              <ul class="pl-4 languages-list">
                  <li class="pb-1" v-for="(item, index) in content" :key="index">
                    {{ item.name }}
                    {{ item.name_local ? '(' + item.name_local + ')' : '' }}
                  </li>
              </ul>
          </div>
          <div v-if="type === 'phoneNumber' && editable">
            <input id="phoneNumber" v-on:change="saveChangedValues" placeholder="+107007007" v-model="phoneNumber"  type="tel" class="form-control" pattern="^($|(\+(\d|\s)+)$)"/>
            <p class="text-dark font-weight-normal text-left mobile-support pt-3">{{$t('mobile_number_support')}}</p>
          </div>
          <div v-if="type === 'awayDate' && editable && dateLoaded">
            <b-form-radio-group
              id="away-input"
              v-model="awayValue"
              class="mb-2"
              v-on:input="changeAway"
            >
              <b-form-radio class="pr-3" value="away">{{$t('away.option1')}}</b-form-radio>
              <b-form-radio value="active">{{$t('away.option2')}}</b-form-radio>
            </b-form-radio-group>

            <p class="text-dark font-weight-normal font-left pt-1 mb-1">{{$t('away.subheading')}}</p>
            <b-input-group>
              <b-form-input id="date-input"
                            v-model="dateValue"
                            type="text"
                            :placeholder="$t('away.placeholder')"
                            autocomplete="off"/>
              <b-input-group-append>
                <b-form-datepicker v-model="dateValue" @context="saveChangedValues" button-only right :hide-header="true" :value-as-date="false" />
              </b-input-group-append>
            </b-input-group>
            <a v-if="dateValue !== originalDate" size="sm" class="float-left font-weight-normal mobile-support text-left pl-1 pt-1" @click="resetDate()"><u>{{$t('away.reset')}}</u></a>
            <br/>
            <p class="text-dark font-weight-normal text-left mobile-support pt-1">{{$t('away.support')}}</p>
          </div>
          <!-- notifications -->
          <div v-if="type === 'notification' && editable">

            <!-- app notifications -->
            <b-form-checkbox-group id="app-notifications" class="text-left w-100" v-model="app_notifications_data" switches size="lg" v-if="seller">
              <table class="table" data-filter="#filter" data-filter-text-only="true">
                <thead>
                  <tr>
                    <th>{{ $t('notifications') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(notification, index) in notifications" :key="index">
                    <td data-title="name">
                      <div class="d-flex">
                        <b-form-checkbox :key="index" class="d-flex" :value="notification.hash" size="lg">
                        {{ notification.display_name }}
                        </b-form-checkbox>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-form-checkbox-group>

            <!-- marketing notifications -->
            <b-form-checkbox-group id="marketing-notifications" class="text-left w-100" v-model="marketing_notifications_data" switches size="lg">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>{{ $t('anytask_marketing_options_title') }}</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(option, index) in defaultMarketing" :key="index">
                  <td data-title="name">
                    <div class="d-flex">
                      <b-form-checkbox :id="'notification-' + index" :key="index" class="d-flex" :value="option.type"  size="lg">
                        {{ option.title }}
                        </b-form-checkbox>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-form-checkbox-group>
            <div class="text-left mb-0 mt-3 marketing-note"><small>{{$t("notifications_info_text")}}</small></div>
          </div>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import apiService from '../../apiService'
export default {
  name: 'InfoBoxSimple',
  props: {
    heading: {
      type: String,
      required: false,
      default: ''
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    type: String,
    content: [String, Array],
    location: {
      type: String,
      required: false
    },
    join: {
      type: String,
      required: false
    },
    LanguageRows: {
      type: Array,
      required: false
    },
    userDataType: {
      type: String,
      required: false,
      default: ''
    },
    number: {
      type: [Number, String],
      required: false,
      default: ''
    },
    date: {
      type: [Number, String],
      required: false,
      default: ''
    },
    awayStatus: {
      type: String,
      required: false
    },
    userNotifications: {
      type: Array,
      required: false
    },
    marketing: {
      type: Array,
      required: false
    },
    seller: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      show: true,
      LanguageRowsData: this.LanguageRows,
      languageOptions: [],
      newLanguages: [],
      country: this.location,
      changedValues: '',
      phoneNumber: this.number,
      originalDate: '',
      dateValue: '',
      awayValue: this.awayStatus,
      dateLoaded: false,
      app_notifications_data: [],
      notifications: [],
      userNotificationsHash: [],
      allHashes: [],
      selected: [], // Must be an array reference!
      marketing_notifications_data: [],
      currentMarketing: [],
      defaultMarketing: [
        { 'title': this.$t('marketing_type.1'), type: 1 },
        { 'title': this.$t('marketing_type.2'), type: 2 }
      ]
    }
  },
  mounted () {
    this.getAllLanguages()
    this.checkDateAway()
    this.getNotifications()
    this.getMarketing()
  },
  watch: {
    LanguageRowsData: {
      handler: function () {
        this.saveChangedValues()
      }
    },
    app_notifications_data: {
      handler: function () {
        this.saveChangedValues()
      }
    },
    marketing_notifications_data: {
      handler: function () {
        this.saveChangedValues()
      }
    },
    seller (seller) {
      this.getNotifications()
    }
  },
  methods: {
    getMarketing () {
      if (this.type === 'notification') {
        let currentMarketing = []
        this.marketing.forEach(function (item) {
          if (item.enabled === true) {
            currentMarketing.push(item.type)
          }
        })
        this.currentMarketing = currentMarketing
        this.marketing_notifications_data = currentMarketing
      }
    },
    resetDate () {
      this.dateValue = this.originalDate
      this.awayValue = this.awayStatus
      this.changedValues.reset = true
      this.emitChanges()
    },
    checkDateAway () {
      if (this.type === 'awayDate') {
        let date = this.date
        if (date !== null && date !== '') {
          let returningDate = new Date(date)
          let currentDate = new Date()

          if (returningDate > currentDate) {
            let day = returningDate.getDate()
            let month = returningDate.getMonth() + 1
            let displayDate = returningDate.getFullYear() + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day)
            this.originalDate = displayDate
            this.dateValue = displayDate
          } else {
            this.originalDate = ''
            this.dateValue = ''
            this.awayValue = 'active'
          }
        }
        this.dateLoaded = true
      }
    },
    addLanguage: function () {
      document.createElement('div')
      this.LanguageRowsData.push('')
    },
    deleteLanguage: function (index) {
      let languageList = []
      this.$refs.language.map((m) => {
        if (String(m.id) !== String(index)) {
          languageList.push(m.value)
        }
      })
      this.LanguageRowsData = languageList
    },
    getAllLanguages () {
      if (this.type === 'list') {
        apiService.getAllLanguages().then((data) => {
          this.languageOptions = data.data['data']
        }).catch(() => { })
      }
    },
    getNotifications () {
      if (this.type === 'notification' && this.seller === true) {
        apiService.getNotifications().then((data) => {
          this.notifications = data.data.data
          this.extractNotifications()
        }).catch(() => {
        })
      }
    },
    extractNotifications () {
      let hash = []
      let allHashes = []
      const uNotifications = this.userNotifications
      this.notifications.forEach(function (item) {
        allHashes.push(item.hash)
        if (!uNotifications.includes(item.internal_name)) {
          hash.push(item.hash)
        }
      })
      this.allHashes = allHashes
      this.userNotificationsHash = this.app_notifications_data = hash
    },
    refreshData () {
      this.addLanguage()
      this.deleteLanguage(1)
    },
    changeAway () {
      if (this.dateLoaded) {
        this.changedValues.away = this.awayValue === 'away' ? 1 : 0
        if (this.awayValue === 'active') {
          this.changedValues.returning_date = {
            'date': {
              'year': 0,
              'month': 0,
              'day': 0
            },
            'time': {
              'hour': '0',
              'minute': '0'
            }
          }
          this.dateValue = ''
        }
        this.emitChanges()
      }
    },
    saveChangedValues () {
      let changedData = {}

      if (this.currentMarketing !== this.marketing_notifications_data && this.type === 'notification') {
        changedData.marketing = this.marketing_notifications_data
      }

      if (this.LanguageRowsData !== this.LanguageRows) {
        changedData.languages = this.LanguageRowsData
      }

      if (this.country !== this.location) {
        changedData.country = this.country
      }

      if (this.phoneNumber !== this.number) {
        changedData.mobile = this.phoneNumber
      }

      if (this.dateValue !== this.originalDate && this.dateValue !== '') {
        changedData.away = 1
        this.awayValue = 'away'
        let date = new Date(this.dateValue)
        changedData.returning_date = {
          'date': {
            'year': date.getFullYear(),
            'month': date.getMonth() + 1,
            'day': date.getDate()
          },
          'time': {
            'hour': '0',
            'minute': '0'
          }
        }
      }

      if (JSON.stringify(this.userNotificationsHash) !== JSON.stringify(this.app_notifications_data)) {
        changedData.notifications = this.allHashes.filter(x => this.app_notifications_data.indexOf(x) === -1)
      }

      this.changedValues = changedData
      if (Object.keys(changedData).length !== 0) {
        this.emitChanges()
      }
    },
    emitChanges () {
      this.$emit('updateLanguageProfile', this.changedValues)
    }
  }
}
</script>

<style lang="scss">

.custom-switch.b-custom-control-lg {
  .custom-control-label {
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 3px 0px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $brand-sky !important;
    background-color: $brand-sky !important;
  }
}

#app-notifications, #marketing-notifications {
  .table {
    border: 1px solid $grey-border-and-text;
    th, td {
      border: 0;
    }
    thead {
      tr {
        background: white !important;
      }
    }
    tr:nth-child(odd) {
      background: $grey-bg;
    }
  }
}

</style>
<style scoped lang="scss">
  .languageSelect {
      width: 80% !important;
  }
  .input-country {
      width: 70% !important;
      margin-top: -1.60rem;
  }
  h3 {
      color: #555;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
  }
  ul {
      list-style-type: none;
      font-size: 14px;
      text-align: left;
  }

  .languages-list li {
    list-style-type: circle;
  }
  .description {
      font-size: 14px;
      line-height: 140%;
      text-align: left;
      color: #62646a;
  }
  .bottomBorder {
      border-bottom: 1px solid #ddd;
  }
  .box-title h2 {
    text-transform: none;
    font-size: 18px;
  }
  .mobile-support, .subheading {
    font-size: 14px ;
  }
  .marketing-note {
    line-height: 18px;
  }

</style>
