<template>
  <ul class="navigation-tree" style="cursor: pointer;">
    <li :class="showCollapse ? 'collapsed' : null" :aria-expanded="showCollapse ? 'true' : 'false'" :aria-controls="mobile-menu" @click="showCollapse = !showCollapse">
      {{ title }}
      <i class="fa-solid fa-chevron-down fa-fw pull-right ml-1" :hidden="showCollapse"></i>
      <i class="fa-solid fa-chevron-up fa-fw pull-right ml-1" :hidden="!showCollapse"></i>
    </li>
    <li>
      <b-collapse id="mobile-menu" v-model="showCollapse" class="mt-2">
        <ul class="child-categories pl-4">
          <li class="my-4" v-for="(subTitle, index) in subTitles" :key="index">
            <NavigationTree @closeMenu="$emit('closeMenu')" v-if="level === 1" :level=2 :title="subTitle.parent.name" :titleEnglish="subTitle.parent.name_en" :subTitles="subTitle.child"/>
            <a @click="setCategoryFilter({name: subTitle.name, nameEnglish: subTitle.name_en, slug: subTitle.slug, parentCategory: title, parentCategoryEnglish: titleEnglish}), setSearchOverlay(true), closeMenu()" v-if="level === 2 && !subTitle.divider" rel="noopener">
              {{ subTitle.name }}
            </a>
          </li>
        </ul>
      </b-collapse>
    </li>
  </ul>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NavigationTree',
  props: {
    title: {
      type: String,
      required: true
    },
    titleEnglish: {
      type: String,
      required: false
    },
    subTitles: {
      type: Array,
      required: true
    },
    level: Number
  },
  data () {
    return {
      showCollapse: false,
      menu: '',
      mobile: ''
    }
  },
  methods: {
    ...mapActions([
      'setSearchOverlay',
      'setCategoryFilter'
    ]),
    closeMenu () {
      this.showCollapse = false
      this.$emit('closeMenu')
    }
  }
}
</script>

<style lang="scss" scoped>

  ul {
    padding-left: 0;

    li a {
      &:hover,
      &.active:hover,
      &.active.focus,
      &.active:focus,
      &.focus,
      &.focus:active,
      &:active:focus,
      &:focus {
        color: black !important;
        text-decoration: none;
      }
    }

    &.child-categories {
      list-style-type: none;
      font-size: 1rem;

      li a {
        word-wrap: break-word;
        color: #575757 !important;
      }
    }
  }

  #mobile-menu {
    margin-top: 0 !important;
  }
</style>
