<template>
  <ais-pagination>
    <template
      v-slot="{
      currentRefinement,
      nbPages,
      pages,
      isFirstPage,
      isLastPage,
      refine,
      createURL
    }"
    >
      <div class="d-flex justify-content-center">
        <div class="pb-4 d-flex" v-if="nbPages > 1">
          <b-button
            :href="createURL(0)"
            @click.prevent="refine(0)"
            @click="scrollToTop"
            class="btn mx-2 border"
            variant="light"
            v-if="!isFirstPage"
          >
            <p class="text-primary mb-0">
              ‹‹
            </p>
          </b-button>
          <b-button
            :href="createURL(currentRefinement - 1)"
            @click.prevent="refine(currentRefinement - 1)"
            @click="scrollToTop"
            class="btn mx-2 border" variant="light" v-if="!isFirstPage"
          >
            <p class="text-primary mb-0">
              ‹
            </p>
          </b-button>
          <span class="d-flex">
          <b-button
            v-for="page in pages" :key="page"
            :href="createURL(page)"
            @click.prevent="refine(page)"
            @click="scrollToTop"
            :class="hideButtons(page, currentRefinement, isFirstPage, isLastPage)"
            class="btn mx-2 border num" :variant="page === currentRefinement ? 'primary' : 'light'"
          >
            <p :class="page === currentRefinement ? 'text-white mb-0' : 'text-primary mb-0' ">{{ page + 1 }}</p>
          </b-button>
          </span>
          <b-button
            :href="createURL(currentRefinement + 1)"
            @click.prevent="refine(currentRefinement + 1)"
            @click="scrollToTop"
            class="btn mx-2 border" variant="light" v-if="!isLastPage"
          >
            <p class="text-primary mb-0">
              ›
            </p>
          </b-button>
          <b-button
            :href="createURL(nbPages)"
            @click.prevent="refine(nbPages)"
            @click="scrollToTop"
            class="btn mx-2 border"
            variant="light"
            v-if="!isLastPage"
          >
            <p class="text-primary mb-0">
              ››
            </p>
          </b-button>
        </div>
      </div>
    </template>
  </ais-pagination>
</template>

<script>
import config from '../../config'

export default {
  name: 'AdvancedSearchPagination',
  data () {
    return {
      maxDisplayedPages: parseInt(config.task_results.max_displayed_pages)
    }
  },
  methods: {
    scrollToTop () { window.scrollTo({ top: 0, behavior: 'smooth' }) },
    hideButtons (page, currentRefinement, isFirstPage, isLastPage) {
      if (isLastPage || isFirstPage) {
        return page >= currentRefinement - 2 && page <= currentRefinement + 2 ? 'd-block' : 'bv-d-xs-down-none'
      }
      return page >= currentRefinement - 1 && page <= currentRefinement + 1 ? 'd-block' : 'bv-d-xs-down-none'
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

::v-deep .btn:focus {
  box-shadow: none !important;
}

</style>
