<template>
  <div>
    <b-button id="currency-popover" class="pl-0 pr-1 bg-transparent border-0 text-secondary">
      <i class="fa-solid fa-globe fa-lg"></i>
      {{ getCurrency() }}
    </b-button>
    <b-popover ref="currency" target="currency-popover" id="currency-selector-desktop" triggers="click blur" placement="topleft">
      <currency-selector @updated="closeCurrency()" class="px-2 pt-2 pb-0"/>
    </b-popover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CurrencySelector from '../Navbar/CurrencySelector'
export default {
  name: 'CurrencyButton',
  components: { CurrencySelector },
  watch: {
    isLoggedIn (to, from) {
      if (!this.isLoggedIn && localStorage.getItem('user-currency')) {
        this.setCurrency(localStorage.getItem('user-currency'))
      }
    }
  },
  methods: {
    ...mapActions([
      'setCurrency'
    ]),
    closeCurrency () {
      this.$refs.currency.$emit('close')
    }
  },
  computed: {
    ...mapGetters(['getCurrency']),
    isLoggedIn () {
      return this.$store.state.accessToken !== null
    }
  }
}
</script>
<style lang="scss" scoped>
.btn:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
#currency-selector-desktop {
  min-width: 360px;
}
</style>
