<template>
    <div>
        <b-modal :id="'text-modal_' + type" :title="title"  hide-footer>
            <div class="p-1 p-sm-3">
                <p>{{message}}</p>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
  name: 'TextModal',
  props: {
    type: String,
    title: String,
    message: String
  }
}
</script>

<style scoped>
    .width{
        width: auto;
    }
</style>
