<template>
  <div class="task-reviews w-100" :class="orderReview ? 'orderReview mb-0 pt-1' : 'mb-4 pt-4'">
    <div class="review-box d-flex flex-column flex-md-row w-100">
      <!-- Avatar -->
      <div class="user-image d-flex pl-0 pr-4 mb-2 mb-md-0" v-lazy-container="{ selector: 'img' }">
        <img v-if="photo_path" :data-src="formatProfilePhoto(photo_path)" :alt="username">
        <div v-else class="avatar-fallback d-flex align-items-center justify-content-center">
            <i class="fa-solid fa-user text-light"></i>
        </div>
      </div>
      <!-- body -->
      <div class="content text-left w-100">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="checked p-0">
              <i v-bind:class="{'fa-regular' : (getRating < 0.5), 'fa-solid' : ( 0.5 <= getRating )}" class="fa-star" aria-hidden="true"></i>
              <i v-bind:class="{'fa-regular' : (getRating < 1.5), 'fa-solid' : ( 1.5 <= getRating )}" class="fa-star" aria-hidden="true"></i>
              <i v-bind:class="{'fa-regular' : (getRating < 2.5), 'fa-solid' : ( 2.5 <= getRating )}" class="fa-star" aria-hidden="true"></i>
              <i v-bind:class="{'fa-regular' : (getRating < 3.5), 'fa-solid' : ( 3.5 <= getRating )}" class="fa-star" aria-hidden="true"></i>
              <i v-bind:class="{'fa-regular' : (getRating < 4.5), 'fa-solid' : ( 4.5 <= getRating )}" class="fa-star" aria-hidden="true"></i>
          </div>
          <div class="time">
            <time>{{ dateFormat(timePosted) }}</time>
          </div>
        </div>
        <div v-if="title" class="headline font-weight-bold mb-1">{{ getTitle }}</div>
        <div class="comment"><p class="m-0 p-0">{{ getMessage }}</p></div>
        <div class="username"><i>- {{ username }}</i></div>
      </div>
    </div>
    <!-- edit -->
    <div v-if="username === loggedInUser && ordersPage && !dispute" class="d-flex justify-content-end">
      <b-button variant="primary" size="sm" class="float-right" v-b-modal.edit>{{ $t('edit_review') }}</b-button>
    </div>
    <edit-review :hash="hash" v-if="username === loggedInUser && ordersPage && !dispute" :current_portfolio="portfolio" :current_review_title="getTitle" :current_rating="getRating" :current_review="getMessage" :buyer="buyer" v-on:updateData="setData"/>
  </div>
</template>

<script>
import editReview from '../Modal/EditReviewModal'
import helper from '../../helper'
export default {
  name: 'TaskReviews',
  components: {
    editReview
  },
  props: {
    timePosted: {
      type: [Date, String],
      required: true,
      default: new Date()
    },
    comment: String,
    title: {
      type: String,
      required: false,
      default: null
    },
    username: String,
    rating: Number,
    photo_path: String,
    hash: String,
    ordersPage: {
      type: Boolean,
      required: false,
      default: false
    },
    portfolio: {
      type: Boolean,
      required: false,
      default: false
    },
    buyer: {
      type: Boolean,
      required: false,
      default: false
    },
    dispute: {
      type: Boolean,
      required: false,
      default: false
    },
    orderReview: Boolean
  },
  data () {
    return {
      loggedInUser: localStorage.getItem('username'),
      message: this.comment,
      rate: this.rating,
      reviewTitle: this.title
    }
  },
  methods: {
    dateFormat (date) {
      const duration = this.moment.duration(this.moment().diff(this.moment(date)))
      if (duration.days() > 7 || duration.months() >= 1 || duration.years() >= 1) {
        const newDate = new Date(date)
        return newDate.toLocaleDateString()
      } else {
        return this.moment(date).fromNow()
      }
    },
    formatProfilePhoto (path) {
      return helper.formatCloudinaryUrl(path, 'profile_small')
    },
    setData (data) {
      this.reviewTitle = data.title
      this.message = data.message
      this.rate = data.rating
    }
  },
  computed: {
    getTitle () {
      return this.reviewTitle
    },
    getMessage () {
      return this.message
    },
    getRating () {
      return this.rate
    }
  }
}
</script>

<style lang="scss" scoped>

.task-reviews {
  border-top: 1px solid $task-grey-border;
  &.orderReview {
    border-top: none;
  }
  .review-box {
    .user-image {
      img, span, .avatar-fallback {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        box-shadow: $message-ui-profile-shadow;
      }
      .avatar-fallback {
        background: $brand-navy;
      }
    }
    .content {
      flex: 1;
      .checked {
        color: #ffbf00;
        font-size: 1.25rem;
      }
      .headline {
        font-size: 18px;
      }
      .comment, .username, .time {
        font-size: 15px;
      }
      .time, .username {
        color: $grey-text;
      }
    }
  }
}

</style>
