<template>
  <b-modal id="signup" :centered="!isMobile" ref="my-modal" size="xl" @shown="resetData" @hide="resetData" @hidden="$store.dispatch('removeSignUpErrors')" hide-footer hide-header>
    <template #default="{ close }">
      <div class="d-flex">
        <div class="left d-none d-lg-flex pt-5 text-white position-relative">
          <div class="text pt-5">
            <h2 class="mx-5 px-5 mb-5">{{ $t('signup_popup.left.heading') }}</h2>
            <div class="d-flex flex-col align-items-center mx-4 points">
              <div class="d-flex ml-3">
                <i class="fa-solid fa-check fa-lg mt-3"></i>
                <h4 class="ml-3 mb-3 text-left">{{ $t('signup_popup.left.points.one') }}</h4>
              </div>
              <div class="d-flex ml-3">
                <i class="fa-solid fa-check fa-lg mt-3"></i>
                <h4 class="ml-3 mb-3 text-left">{{ $t('signup_popup.left.points.two') }}</h4>
              </div>
              <div class="d-flex ml-3">
                <i class="fa-solid fa-check fa-lg mt-3"></i>
                <h4 class="ml-3 mb-3 text-left">{{ $t('signup_popup.left.points.three') }}</h4>
              </div>
            </div>
          </div>

          <img class="w-100 mx-auto" v-lazy="require('../../assets/signup-popup-image.jpg')" alt="person with laptop">
        </div>
        <div class="right d-flex flex-col p-2 p-sm-3 p-lg-3">
          <div @click="close()" class="close position-absolute cursor-pointer"><i class="fa-solid fa-xmark"></i></div>
          <div class="p-2 p-lg-4 h-100 d-flex flex-col">
            <h2 class="font-weight-bold mt-4 pt-1">{{ stage === 1 ? $t('signup_popup.right.stage_1.create_account') : stage === 2 ? $t('signup_popup.right.stage_2.join_anytask') : stage === 3 ? $t('signup_popup.right.stage_3.last_step') : $t('signup_popup.right.stage_4.lets_get_started') }}</h2>
            <div class="d-flex justify-content-center" v-if="stage === 1">
              <p class="">{{ $t('signup_popup.right.stage_1.already_have_account') }}</p>
              <a v-on:click="toggleModal('login')" class="ml-2 underline"><u>{{ $t('sign_in') }}</u></a>
            </div>
            <div class="border-bottom w-75 my-3 mx-auto"></div>
            <div v-if="$store.state.register.signUpErrors !== null && $store.state.register.signUpErrors.length > 0">
              <div v-for="(error, index) in $store.state.register.signUpErrors" :key="index" class="alert alert-danger p-2 mb-1">
                <div v-if="error === 'HTTP-401'" class="d-flex align-items-center p-1">
                  <i class="fa-solid fa-triangle-exclamation fa-lg ml-2 mr-3"></i>
                  <p class="small mb-0">
                    {{ $t('sign_up_email_error') }} <a class="" v-on:click="toggleModal('forgotPassword')"> {{ $t('sign_up_forgotten_password') }}</a>
                  </p>
                </div>
                <div v-else class="d-flex align-items-center p-1">
                  <i class="fa-solid fa-triangle-exclamation fa-lg ml-2 mr-3"></i>
                  <p class="small mb-0">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="stage === 1">
              <b-form class="d-flex flex-col align-items-center mt-2" @submit="submit">
                <div>
                  <b-form-checkbox
                    id="terms"
                    v-model="form.terms"
                    type="checkbox"
                    value="accepted"
                    :disabled="stage === 3"
                    class="mb-2"
                  >
                    <small v-html="$t('accept_terms', { terms_link: '/terms-and-conditions' })"/>
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="general"
                    v-model="form.general"
                    type="checkbox"
                    value="accepted"
                    :disabled="stage === 3"
                    class="mb-3"
                  >
                    <small v-html="$t('signup_popup.right.stage_1.tickBoxes.marketing')"/>
                  </b-form-checkbox>
                </div>
              </b-form>
              <b-form-group id="input-group-4" class="m-0 mt-1 mx-auto">
                <div>
                  <b-button @click="removeSignUpErrors(), form.terms === 'accepted' ? stage = 2 : $store.state.register.signUpErrors.push($t('join_validator_terms'))" class="rounded-pill w-100 py-2 mt-2" variant="primary">
                    {{ $t('signup_popup.right.stage_1.continue_with_email') }}
                  </b-button>
                  <div class="col">
                    <div class="or"><span> {{ $t('signup_popup.right.stage_1.or') }}</span></div>
                  </div>
                  <div class="d-flex flex-col align-items-center">
                    <div v-if="showSpinnerEtn" class="etn btn rounded-pill d-flex align-items-center justify-content-center w-100">
                      <b-spinner small label="Large Spinner"></b-spinner>
                    </div>
                    <div v-else class="etn btn rounded-pill w-100" @click="loginSubmitEtn">
                      <span>{{ $t('sign_in_electroneum') }}</span>
                      <img :src="getImg('electroneum-powered.png')" :alt="$t('sign_in_electroneum_full')" class="ml-2">
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div v-if="stage === 2">
              <b-form class="d-flex flex-col align-items-center mt-2" @submit="submit">
                <div class="w-100 email-and-password">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      v-model="form.email"
                      type="email"
                      required
                      :placeholder="$t('enter_email')"
                    />
                  </b-form-group>
                  <b-form-group
                    id="input-group-3" label-for="input-3" class="text-center"
                    :description="$t('password_description')" >
                    <b-input-group class="my-3">
                      <b-form-input id="input-3"
                                    v-model="form.password"
                                    :type="!showPassword ? 'password' : 'text'"
                                    required
                                    @input="applyPasswordStrength(form.password)"
                                    :placeholder="$t('choose_password')"/>
                      <b-input-group-append>
                        <b-button variant="outline-secondary" class="eyeBorder" v-on:click="switchPassword()">
                          <i class="fa-solid fa-fw" v-bind:class="{'fa-eye' : !showPassword, 'fa-eye-slash' : showPassword }" aria-hidden="true"/>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <meter v-if="$store.state.register.signUpErrors && $store.state.register.signUpErrors.includes($t('join_validator_password_weak'))" min="0" max="4" id="password-strength-meter" ref="passwordStrengthMeter"/>
                    <p v-if="stage === 2 && $store.state.register.signUpErrors &&  $store.state.register.signUpErrors.includes($t('join_validator_password_weak'))" id="password-strength-text" class="mb-0" ref="passwordStrengthText" />
                  </b-form-group>
                </div>
                <b-form-group id="input-group-4" class="m-0 mt-1 mx-auto">
                  <div v-if="showSpinner && !showContinueButton">
                    <b-button type="submit" class="w-100 py-2 rounded-pill" variant="primary" disabled="disabled">
                      <b-spinner class="" small label="Large Spinner"/>
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button type="submit" class="w-100 py-2 rounded-pill" variant="primary">
                      {{ $t('signup_popup.right.stage_2.join_now') }}
                    </b-button>
                  </div>
                </b-form-group>
              </b-form>
              <div class="text-center mt-auto px-1 pt-2 px-md-3 mx-3 pb-2 pb-md-4">
                <small v-html="$t('join_recaptcha')"></small>
              </div>
            </div>

            <div v-if="stage === 3">
              <b-form class="d-flex flex-col align-items-center mt-2" @submit="submit">
                <b-form-input
                  id="username-field"
                  v-model="username"
                  type="text"
                  required
                  :placeholder="$t('choose_username')"
                  class="mt-2 mb-3"
                  @keypress="usernameInputCheck"
                  v-on:blur="changeText"
                ></b-form-input>
                <p class="mt-2">
                  {{ $t('suggestion') }}
                  <span v-if="!showSuggestions" class="spinner-border spinner-border-sm ml-2"/>
                  <span class="pr-1" v-else v-for="(username, index) in usernameSuggestionArray" :key="index">
                  <a v-on:click="addUsername(username)" class="text-primary">{{ username }}</a>
                  {{ index !== 2 ? ' , ' : '' }}
                </span>
                </p>
                <b-form-group id="input-group-4" class="m-0 mt-1 mx-auto">
                  <div v-if="showSpinner && !showContinueButton">
                    <b-button type="submit" class="w-100 py-2 rounded-pill" variant="primary" disabled="disabled">
                      <b-spinner class="" small label="Large Spinner"/>
                    </b-button>
                  </div>
                  <div v-else-if="!showContinueButton" class="mb-2">
                    <h4 class="field-heading text-center mt-2 mb-2 font-weight-bold" v-if="displayAccountType">{{ $t('account_type.header') }}</h4>
                    <b-button type="submit" @click="account_type = 1" class="w-100 py-2 my-2 rounded-pill" variant="primary">
                      {{ $t('signup_popup.right.stage_3.hire_a_freelancer') }}
                    </b-button>
                    <b-button @click="showContinueButton = true" class="w-100 py-2 my-2 rounded-pill" variant="primary">
                      {{ $t('signup_popup.right.stage_3.work_as_freelancer') }}
                    </b-button>
                  </div>
                </b-form-group>
                <div v-if="showContinueButton" class="mt-3">
                  <b-button @click="showContinueButton = false" class="py-2 mb-1 bg-transparent text-dark border-0 d-flex align-items-center">
                    <i class="fa-solid fa-chevron-left fa-xs mr-1"></i>Back
                  </b-button>
                  <b-alert show><p class="crypto-asset text-dark text-center pt-3">
                    {{ $t('signup_popup.right.stage_3.crypto_asset') }}
                  </p></b-alert>
                </div>
                <b-button v-if="showContinueButton" type="submit" @click="account_type = 0" class="w-100 py-2 my-2 rounded-pill" variant="primary">
                  <b-spinner v-if="showSpinner" class="" small label="Large Spinner"/>
                  <div v-else>{{ $t('signup_popup.right.stage_3.continue') }}</div>
                </b-button>
              </b-form>
            </div>

            <div v-if="stage === 4">
              <ais-instant-search :index-name="config.advanced_search.index_name + '_query_suggestions'" :search-client="getSearchClient()" :routing="routing" :insights="true">
                <div class="d-flex w-100 flex-col align-items-center mt-2">
                  <p class="text-center">{{ $t('signup_popup.right.stage_4.what_kind_of_task') }}</p>
                  <div class="flex flex-col w-100 flex-grow-1" v-if="!selectionMade">
                    <ais-search-box class="mx-lg-3 flex-grow-1 mb-2">
                      <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                        <div class="d-flex w-100 align-items-center justify-content-start rounded-pill overflow-hidden border rounded-pill py-2">
                          <i class="fa-solid fa-magnifying-glass fa-md ml-4 text-secondary"></i>
                          <input
                            class="border search-box border-0 px-3 w-100 bg-transparent"
                            v-model="selectedSuggestion"
                            :placeholder="$t('signup_popup.right.stage_4.search_here')"
                            @input="refine($event.currentTarget.value)"
                            maxlength="100"
                          >
                        </div>
                      </template>
                    </ais-search-box>
                    <ais-hits>
                      <template v-slot="{ items, sendEvent }">
                        <ul v-if="items.length" class="suggestions-holder border mx-lg-3 pl-0">
                          <li class="py-2 text-center cursor-pointer" :class="items.length > 1 || items[0].objectID.replace(/\s/g, '') !== selectedSuggestion.replace(/\s/g, '') ? 'border-bottom' : ''" v-if="selectedSuggestion">
                            <p class="mb-0" @click="selectionMade = true"><span>{{ $t('signup_popup.right.stage_4.use') }}</span> '{{ selectedSuggestion }}'</p>
                          </li>
                          <li class="text-center cursor-pointer" :class="index < items.length -1 && item.objectID.replace(/\s/g, '') !== selectedSuggestion.replace(/\s/g, '') ? 'border-bottom' : ''" v-for="(item, index) in items" :key="item.objectID">
                            <p class="mb-0 py-2" v-if="item.objectID.replace(/\s/g, '') !== selectedSuggestion.replace(/\s/g, '')" @click="selectedSuggestion = item.objectID, selectionMade = true, suggested = true">{{ item.objectID }}</p>
                          </li>
                        </ul>
                        <ul v-else class="suggestions-holder border mx-lg-3 pl-0">
                          <li class="py-2 text-center cursor-pointer" v-if="selectedSuggestion">
                            <p class="mb-0" @click="selectionMade = true"><span>{{ $t('signup_popup.right.stage_4.use') }}</span> '{{ selectedSuggestion }}'</p>
                          </li>
                        </ul>
                      </template>
                    </ais-hits>
                  </div>
                  <div v-else class="flex flex-col align-items-center">
                    <b-button pill variant="dark" class="px-4 mb-5" @click="selectedSuggestion = '', selectionMade = false">{{ selectedSuggestion }}<i class="fa-solid fa-xmark ml-2"></i></b-button>
                    <p class="text-center">{{ $t('signup_popup.right.stage_4.either_search_or_list') }}</p>
                    <b-form-group id="input-group-4" class="m-0 mt-1 mx-auto mt-2">
                      <div class="flex flex-col">
                        <b-button variant="primary" pill class="px-4" @click="listAJob()">{{ $t('signup_popup.right.stage_4.list_job') }}</b-button>
                        <div class="col">
                          <div class="or"><span> {{ $t('signup_popup.right.stage_1.or') }}</span></div>
                        </div>
                        <b-button variant="primary" pill class="px-4" @click="searchForTask()">{{ $t('signup_popup.right.stage_4.search_for_task') }}</b-button>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </ais-instant-search>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ApiService from '../../apiService'
import config from '../../config'
import store from '../../store'
import algoliaSearch from 'algoliasearch'
import { history as historyRouter } from 'instantsearch.js/es/lib/routers'
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings'

export default {
  name: 'signupPopup',
  data () {
    return {
      isMobile: window.innerWidth < 575,
      suggested: false,
      selectionMade: false,
      selectedSuggestion: '',
      searchClient: null,
      routing: {
        router: historyRouter({
          push (url) {
            window.history.replaceState(window.location.href, '', url)
          }
        }),
        stateMapping: singleIndexMapping(config.advanced_search.index_name)
      },
      form: {
        email: '',
        password: '',
        terms: false,
        general: false,
        news: false,
        capture: ''
      },
      displayAccountType: true,
      username: '',
      account_type: null,
      showPassword: false,
      result: 0,
      show: true,
      strength: {
        0: this.$t('password-strength-worst'),
        1: this.$t('password-strength-bad'),
        2: this.$t('password-strength-weak'),
        3: this.$t('password-strength-good'),
        4: this.$t('password-strength-strong')
      },
      submitButton: document.getElementById('sign-up'),
      showSpinner: false,
      showSpinnerEtn: false,
      stage: 1,
      usernameSuggestionArray: [],
      showSuggestions: false,
      showContinueButton: false
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', () => {
      if (this.stage === 3 && !this.showSpinner) {
        store.dispatch('signOut')
      }
    })
    this.$root.$on('bv::modal::show', () => {
      sessionStorage.setItem('signupPopupShown', 'true')
    })
  },
  watch: {
    'form.general': function (val) {
      this.form.news = val
    },
    'cookieConsentGiven' () {
      this.showSignupPopup()
    }
  },
  methods: {
    ...mapActions([
      'processJwt',
      'removeSignUpErrors',
      'doLoginEtn',
      'setBuyerSignupSuggestion',
      'setCategoryFilter'
    ]),
    listAJob () {
      this.$tracker.tagEvent('buyerSignupGetStarted', {
        'chosenAction': 'ListAJob',
        'userInput': this.selectedSuggestion,
        'suggested': this.suggested
      })
      this.setBuyerSignupSuggestion(this.selectedSuggestion)
      this.$router.push({ name: 'job-create', hash: '#buyer-signup' })
      this.$bvModal.hide('signup')
    },
    searchForTask () {
      this.$tracker.tagEvent('buyerSignupGetStarted', {
        'chosenAction': 'SearchForTask',
        'userInput': this.selectedSuggestion,
        'suggested': this.suggested
      })
      sessionStorage.setItem('searchQuery', this.selectedSuggestion)
      this.setCategoryFilter(null)
      this.$router.push({ name: 'home', query: { query: this.selectedSuggestion } })
      this.$bvModal.hide('signup')
    },
    getSearchClient () {
      const options = this.getAdvanceSearch()

      if (this.searchClient) {
        return this.searchClient
      }

      const algoliaClient = algoliaSearch(options.app_id, options.public_key)
      this.searchClient = {
        ...algoliaClient,
        search (requests) {
          return algoliaClient.search(requests)
        }
      }

      return this.searchClient
    },
    getImg (pic) {
      return require('../../assets/' + pic)
    },
    loginSubmitEtn () {
      this.showSpinnerEtn = true
      this.doLoginEtn().then(() => {
        setTimeout(() => {
          this.showSpinnerEtn = false
        }, 120000)
      })
    },
    resetData () {
      this.form = {
        email: '',
        password: '',
        terms: false,
        general: false,
        news: false
      }
      this.username = ''
      this.stage = 1
      this.showSpinner = false
      this.account_type = null
      this.showContinueButton = false
      this.selectionMade = false
      this.selectedSuggestion = ''
    },
    switchPassword () {
      this.showPassword = this.showPassword !== true
    },
    addUsername (username) {
      this.username = username
    },
    usernameInputCheck (evt) {
      if (/[^A-Za-z0-9]+/g.exec(evt.key)) {
        evt.preventDefault()
      } else if (this.username.length === 15) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changeText () {
      this.username = this.username.toLowerCase()
    },
    usernameSuggestions () {
      ApiService.getUsernameSuggestions().then((data) => {
        this.usernameSuggestionArray = data.data.data.slice(0, 3)
        this.showSuggestions = true
      })
    },
    validateForm () {
      this.$store.state.register.signUpErrors = []

      if (this.form.email.length === 0) {
        this.$store.state.register.signUpErrors.push(this.$t('join_validator_email'))
      }

      if (this.form.password.length > 0 && this.checkPasswordStrength(this.form.password) < 2) {
        this.$store.state.register.signUpErrors.push(this.$t('join_validator_password_weak'))
      }

      if (!this.form.terms) {
        this.$store.state.register.signUpErrors.push(this.$t('join_validator_terms'))
      }
      return this.$store.state.register.signUpErrors.length === 0
    },
    submit (e) {
      e.preventDefault()
      if (this.stage === 2) {
        this.register()
      } else {
        this.updateUser()
      }
    },
    patchValidation () {
      this.$store.state.register.signUpErrors = []
      if (this.username.length === 0) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_username_null'))
      } else if (this.username.length < 3) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_username_too_short'))
      } else if (this.username.length > 15) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_username_too_long'))
      }

      if (this.account_type === null && this.displayAccountType) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_account_type_not_selected'))
      }

      return this.$store.state.register.signUpErrors.length === 0
    },
    updateUser () {
      if (this.patchValidation()) {
        this.showSpinner = true
        ApiService.patchUser({ username: this.username, account_type: this.account_type }).then((data) => {
          this.$tracker.tagEvent('registrationCompletion', {
            'registeredIntent': !this.account_type || this.account_type === '0' ? 'seller' : 'buyer',
            'location': this.$route.name
          })

          if (config.tracking_enabled === 'true') {
            window.__at_px.fire('register', !this.account_type || this.account_type === '0' ? 'seller' : 'buyer')
          }

          if (config.tracking_enabled === 'true') {
            let el = document.createElement('img')
            el.src = 'https://tags.w55c.net/rs?id=b53fd3098e2044a5b75179dd870a2c45&t=marketing&sku=' + (!this.account_type || this.account_type === '0' ? 'seller' : 'buyer')
            document.body.append(el)
          }

          localStorage.setItem('accessToken', data.data.data.token)
          this.processJwt()

          this.$store.dispatch('removeLoginError').then(() => {
            if (this.account_type === 0) {
              this.$router.push({ name: 'seller-welcome' })
            } else if (localStorage.getItem('signUpType') === 'purchase') {
              this.$store.state.register.signUpComplete.push('purchase')
            } else if (localStorage.getItem('signUpTypeCustom') === 'custom-quote') {
              this.$store.state.register.signUpComplete.push('custom-quote')
            }
            if (this.account_type === 1 && this.$route.name === 'home' && this.getAdvanceSearch().top) {
              this.stage = 4
            } else {
              this.$bvModal.hide('signup')
            }
          })
        }).catch((error) => {
          let errors = error.response.data.errors.children
          if (errors.username.errors) {
            errors.username.errors.forEach(function (child) {
              this.$store.state.register.signUpErrors.push(child)
            }.bind(this))
          }
          if (errors.account_type.errors) {
            errors.account_type.errors.forEach(function (child) {
              this.$store.state.register.signUpErrors.push(child)
            }.bind(this))
          }
          this.showSpinner = false
        })
      }
    },
    async register () {
      if (this.validateForm()) {
        this.showSpinner = true
        this.refreshState()

        await this.$recaptcha('signup').then((token) => {
          this.form.capture = token
        })

        const payload = {
          ...{
            email: this.form.email,
            password: {
              first: this.form.password,
              second: this.form.password
            },
            marketing_general: (this.form.general ? 1 : 0),
            marketing_news: (this.form.news ? 1 : 0),
            terms_accepted: (this.form.terms ? 1 : 0),
            capture: this.form.capture
          },
          ...JSON.parse(localStorage.getItem('utm'))
        }

        this.$store.dispatch('register', payload)
          .then(() => {
            if (this.$store.state.accessToken !== null) {
              localStorage.removeItem('utm')
              this.stage = 3
              this.usernameSuggestions()
            }
            this.showSpinner = false
          })
      }
    },
    toggleModal (modal) {
      if (localStorage.getItem('signUpType') === 'purchase') {
        this.$bvModal.hide('signup')
        localStorage.setItem('signUpType', 'purchaseTask')
      } else {
        this.$bvModal.hide('signup')
        localStorage.setItem('signUpTypeCustom', 'custom-quote')
      }
      this.$bvModal.show(modal)
    },
    scorePassword (password) {
      let score = 0

      if (!password) {
        return score
      }

      let letters = {}
      for (let i = 0; i < password.length; i++) {
        letters[password[i]] = (letters[password[i]] || 0) + 1
        score += 5.0 / letters[password[i]]
      }

      let variations = {
        digits: /\d/.test(password),
        lower: /[a-z]/.test(password),
        upper: /[A-Z]/.test(password),
        nonWords: /\W/.test(password)
      }

      let variationCount = 0
      for (let check in variations) {
        variationCount += (variations[check]) ? 1 : 0
      }
      score += (variationCount - 1) * 10
      return Math.trunc(score)
    },
    checkPasswordStrength (password) {
      let score = this.scorePassword(password)
      let idx = 0
      if (score > 90) {
        idx = 4
      } else if (score > 70) {
        idx = 3
      } else if (score >= 40) {
        idx = 2
      } else if (score >= 20) {
        idx = 1
      }
      return idx
    },
    applyPasswordStrength (password) {
      if (this.$store.state.register.signUpErrors.includes(this.$t('join_validator_password_weak'))) {
        this.result = this.checkPasswordStrength(password)
        let meter = this.$refs.passwordStrengthMeter
        let text = this.$refs.passwordStrengthText
        meter.value = this.result

        if (password !== '') {
          text.innerHTML = this.$t('password-strength') + ': ' + '<strong>' + this.strength[this.result] + '</strong>' + '<span class=\'feedback\'></span>'
        } else {
          text.innerText = ''
        }
      }
    },
    refreshState () {
      this.$store.state.register.signUpErrors = null
    },
    showSignupPopup () {
      setTimeout(() => {
        if (!sessionStorage.getItem('signupPopupShown') && !this.isLoggedIn()) {
          this.$bvModal.show('signup')
        }
      }, 15000)
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getAdvanceSearch']),
    ...mapState({
      signingUp: state => state.register.signingUp,
      signUpErrors: state => state.register.signUpErrors,
      cookieConsentGiven: state => state.user.cookieConsentGiven,
      config () {
        return config
      }
    })
  }
}
</script>

<style lang="scss" scoped>

::v-deep .modal-content {
  border-radius: 15px !important;
  overflow: clip;
  max-width: 1000px !important;
  margin-inline: auto;
}

.btn:focus, .btn:active {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.modal-body {
  .left {
    width: 50%;
    font-weight: bold !important;
    background: #566bb6;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .text {
      position: absolute;
      top: 0;
      h2  {
        font-size: 2.3rem;
        font-weight: bold;
      }

      .points {
        overflow-y: visible;
        h4 {
          font-size: 1.4rem;
          width: 300px;
        }
      }
    }
  }
  @include lg-up {
    .left {
      height: 730px;
    }
  }

  .right {
    width: 100%;
    overflow-y: visible;

    .crypto-asset {
      font-size: 0.85rem;
      line-height: 1.2;
      color: #999;
    }
  }
  @include lg-up {
    .right {
      width: 50%;
      height: 730px;
      overflow-y: scroll;
    }
  }
}

.close {
  right: 15px;
  top: 10px;
}

.eyeBorder,
.eyeBorder:active,
.eyeBorder:hover,
.eyeBorder:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}

small {
  color: #999;
}

.suggestions-holder {
  overflow: clip;
  border-radius: 15px;

  li:hover {
    background: #ededed;
  }
}

.search-box:focus-visible {
  outline: none;
}

span.footer-member {
  color: #999;
  font-weight: 300;
}

.etn img {
  height: 2rem;
  padding-bottom: 2px;
}

.col {
  width: 100%;
  padding: 0;
}

.col a.btn {
  width: 100%;
}

span hr {
  height: 1px;
  border-radius: 5%;
  border: none;
  background-color: #999;
  margin-top: 10px;
  width: 100%;
}

span small {
  text-align: center;
  color: #999;
  font-weight: 400;
}

#input-group-4 {
  width: 300px;
  margin-left: auto;
  button {
    img, i {
      text-align: center;
      width: 24px;
      margin-right: 10px;
      margin-left: 20px;
    }
  }

  .etn {
    background-color: #0c3547;
    color: white;
    height: 46px;

    &:hover {
      filter: brightness(70%);
      color: white;
    }

    img {
      height: 2rem;
      padding-bottom: 2px;
    }
  }

  .or {
    overflow: visible;
    border-bottom: 1px solid #ddd;
    text-align: center;
    height: 8px;
    margin: 18px 0 22px 0;

    span {
      background-color: #fff;
      font-size: 14px;
      line-height: 100%;
      padding: 0 12px;
      color: #404145;
      position: relative;
      top: -5px;
    }
  }
}

meter {
  margin: 0.8em auto 0.4em;
  width: 100%;
  height: .5em;
}

meter[value="1"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbdb0f', endColorstr='#fbdb0f', GradientType=0);
  /* IE6-9 */
}

meter[value="2"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="3"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="4"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="1"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbdb0f', endColorstr='#fbdb0f', GradientType=0);
  /* IE6-9 */
}

meter[value="2"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="3"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="4"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}
</style>
