<template>
  <div class="gradient-bg"></div>
</template>

<script>
export default {
  name: 'AnimatedGradientBackground'
}
</script>

<style scoped lang="scss">
  @keyframes blob {
    0% {
      translate: 0 0;
      rotate: 0deg;
    }
    30% {
      rotate: 20deg;
    }
    50% {
      transform: translate(40%, 50%) scale(1.5);
    }
    80% {
      rotate: -20deg;
    }
  }
  .gradient-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $brand-frozen-blue;

    &::before {
      position: absolute;
      content: '';
      right: 0%;
      top: 10%;
      height: 60%;
      width: 60%;
      transform-origin: 60%;
      background: linear-gradient(to bottom right, rgba($brand-flamingo, 0.8), rgba($brand-octopus, 1));
      border-radius: 200px;
      filter: blur(100px) brightness(125%);
      animation: blob 15s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }

    &::after {
      position: absolute;
      content: '';
      right: 10%;
      top: 10%;
      height: 60%;
      width: 60%;
      transform-origin: 60%;
      background: linear-gradient(to bottom right, rgba($brand-frozen-blue, 1), rgba($brand-sky, 0.6));
      border-radius: 200px;
      filter: blur(100px) brightness(125%);
      animation: blob 20s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045) reverse;
    }
}
</style>
