<template>
  <div class="d-flex justify-content-center flex-col">
    <div v-if="!cannotContinue">
      <div class="row">
        <div class="col-md-12 justify-content-center text-center">
          <b-img v-if="task.user.photo_path" rounded="circle" :src='formatProfilePhoto(task.user.photo_path)'/>
          <span v-else class="fa-stack fa-2x" style="z-index: 1">
              <i class="fa-solid fa-circle fa-stack-2x text-gray"/>
              <i class="fa-solid fa-user fa-stack-1x text-light"/>
          </span>
          <p class="username font-weight-bold">{{ task.user.username }}</p>
          <p>{{ $t('custom_task_description') }}</p>
          <p><span class="username font-weight-bold">{{ task.user.username }}</span> {{ $t('custom_task_intro_text') }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-alert v-if="validationsErrorSwitch" show variant="danger">
            <ul>
              <li v-for="(message, index) in validationErrors" v-bind:key="index">
                {{ message }}
              </li>
            </ul>
          </b-alert>
          <form v-on:submit.prevent="handleSubmit(task.hash)">
            <label>{{ $t('delivery_time_heading') }}</label>
            <select
              v-model="deliveryDays"
              class="mb-2 form-control">
              <option v-for="(day, index) in $t('daysOptions')" v-bind:key="index" :value="index">{{ day }}</option>
            </select>
            <label class="text-capitalize">{{ $t('budget') }} <small class="text-lowercase">{{ $t('estimate_spend') }}</small></label>
            <b-input-group prepend="$">
              <b-form-input
                v-model="budget"
                :value="budget"
                type="number"
                step="00.01"
                :max=500.00
                maxvalue="500.00"
                aria-valuemax=500.00
                aria-maxlength="6"
                maxlength="6"
                :class="budget_validation ? 'border-danger' : ''">
              </b-form-input>
            </b-input-group>
            <label class="mt-2">{{ $t('task_requirements') }}</label>
            <b-form-textarea
              id="textarea"
              v-model="additionalInfo"
              :value="additionalInfo"
              name=""
              rows="3"
              max-rows="6"
              aria-valuemax="500"
              maxlength="500"
              :class="additionalInfo_validation ? 'border-danger mb-2' : 'mb-2'">
            </b-form-textarea>
            <p class="text-right">
              <small><span v-bind:class="{ 'text-red': charactersRemaining <= 10 }">{{ charactersRemaining }}</span> {{ $t('characters_remaining') }}</small>
            </p>
            <div class="d-flex justify-content-center">
              <b-button v-if="loadingCustom" type="submit" class="w-100 task-purchase btn mt-4 mb-0" variant="primary" disabled="disabled">
                <b-spinner class="" small label="Large Spinner"/>
              </b-button>
              <b-button v-else v-on:click="handleSubmit(task.hash)" class="w-100 task-purchase btn m-0" variant="primary">
                {{ $t('send_custom_quote') }}
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <b-alert show>{{ alertMessage }}</b-alert>
    </div>
  </div>
</template>

<script>
import APIService from '../../apiService'
import helper from '../../helper'
import config from '../../config'

export default {
  name: 'CustomTaskForm',
  props: {
    task: {}
  },
  mounted () {
    localStorage.removeItem('signUpTypeCustom')
  },
  data () {
    return {
      additionalInfo: '',
      maxCharacters: 500,
      deliveryDays: this.$props.task.packages[0].delivery,
      budget: '',
      profileProps: { width: 70, height: 70, class: 'm2' },
      upload_data: null,
      budget_validation: false,
      additionalInfo_validation: false,
      validationMessage: '',
      cannotContinue: false,
      alertMessage: '',
      loadingCustom: false,
      validationErrors: [],
      validationsErrorSwitch: false
    }
  },
  methods: {
    formatProfilePhoto (img) {
      return helper.formatCloudinaryUrl(img, 'profile_small')
    },
    handleSubmit (hash) {
      this.loadingCustom = true
      if (this.validateForm()) {
        this.createTaskApi(hash)
      }
      if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'contactedSeller'
        })
      }
    },
    validateForm () {
      this.additionalInfo_validation = false
      this.budget_validation = false
      this.validationErrors = []
      this.validationsErrorSwitch = false
      this.revisions = parseFloat(this.revisions).toFixed(0)

      // Set the form data
      let formData = {
        'delivery': this.deliveryDays,
        'additional_information': this.additionalInfo
      }
      // Validation checks
      if (this.budget !== '') {
        this.budget = parseFloat(this.budget).toFixed(2)
        if (this.budget > 500) {
          this.budget_validation = true
          this.validationErrors.push(this.$t('your_max_budget_500'))
        } else if (this.budget < 1 || isNaN(this.budget)) {
          this.budget_validation = true
          this.validationErrors.push(this.$t('please_enter_max_budget'))
        } else {
          formData['budget'] = this.budget
        }
      }

      if (this.additionalInfo.length === 0) {
        this.additionalInfo_validation = true
        this.validationErrors.push(this.$t('please_enter_task_requirements'))
      }

      if (this.validationErrors.length > 0) {
        this.validationsErrorSwitch = true
        this.loadingCustom = false
        return false
      }

      this.upload_data = formData
      return true
    },
    createTaskApi (hash) {
      APIService.createCustomTask(hash, this.upload_data).then((resp) => {
        setTimeout(() => {
          this.$router.push('/my-account/inbox')
        }, 250)
      }).catch((error) => {
        if (error.response.data.errors !== undefined) {
          this.loadingCustom = false
          this.validationsErrorSwitch = true
          this.additionalInfo_validation = true
          this.validationErrors.push(error.response.data.errors.children.additional_information.errors[0])
        } else if (error.response.status === 400) {
          this.loadingCustom = false
          this.validationsErrorSwitch = true
          this.validationErrors.push(error.response.data.error_message)
        } else {
          this.cannotContinue = true
          this.validationErrors.push(this.$t('already_requested'))
        }
        return false
      })
    }
  },
  computed: {
    charactersRemaining: function () {
      return this.maxCharacters - this.additionalInfo.length
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
}

.text-red {
  color: $brand-scarlet;
}

.username {
  color: #212529;
}

.username img.circle {
  -webkit-box-shadow: 0 2px 2px 0 rgba(85, 85, 85, 0.29), 0 0 1px 0 #888;
  box-shadow: 0 2px 2px 0 rgba(85, 85, 85, 0.29), 0 0 1px 0 #888;
  border-radius: 24px;
  height: 70px;
  width: 70px;
}

li, ul {
  list-style-type: unset;
}

.border-danger {
  border-width: 2px;
  border-style: solid;
}

</style>
