import apiService from '../../apiService'

export default {
  state: {
    signUpComplete: [],
    signingUp: false,
    signUpErrors: []
  },
  getters: {
    getSignUpCompleteAction: (state) => () => {
      return state.signUpComplete
    },
    getRegisterState: (state) => () => {
      return state
    }
  },
  mutations: {
    signUpStart (state) {
      state.signingUp = true
      state.signUpError = []
    },
    signUpStop (state, errorMessages) {
      state.signingUp = false
      state.signUpErrors = errorMessages
    },
    setSignUpCompleteAction: (state, data) => {
      state.signUpComplete = data
    }
  },
  actions: {
    register ({ dispatch, commit }, user) {
      commit('signUpStart')

      return apiService.register(user)
        .then(response => {
          localStorage.setItem('accessToken', response.data.data.token)
          localStorage.setItem('refreshToken', response.data.data.refresh_token)
          commit('updateAccessToken', response.data.data.token)
          commit('updateRefreshToken', response.data.data.refresh_token)
          commit('signUpStop', null)
          dispatch('processJwt')
        }).catch(error => {
          commit('updateAccessToken', null)
          commit('updateRefreshToken', null)

          let errors = []
          let emailErrors = error.response.data.errors.children.email.errors
          let passwordFirstErrors = error.response.data.errors.children.password.children.first.errors
          let passwordSecondErrors = error.response.data.errors.children.password.children.second.errors
          let termsErrors = error.response.data.errors.children.terms_accepted.errors
          let takenEmail = error.response.data.code

          if (emailErrors && takenEmail === 'HTTP-401') {
            errors.push(takenEmail)
          }
          if (emailErrors && takenEmail !== 'HTTP-401') {
            emailErrors.forEach(function (error) {
              errors.push(error)
            })
          }
          if (passwordFirstErrors) {
            passwordFirstErrors.forEach(function (error) {
              errors.push(error)
            })
          }
          if (passwordSecondErrors) {
            passwordSecondErrors.forEach(function (error) {
              errors.push(error)
            })
          }
          if (termsErrors) {
            termsErrors.forEach(function (error) {
              errors.push(error)
            })
          }
          commit('signUpStop', errors)
        })
    },
    setSignUpCompleteAction ({ commit }, data) {
      commit('setSignUpCompleteAction', data)
    },
    removeSignUpErrors ({ commit }) {
      commit('signUpStop', [])
    }
  }
}
