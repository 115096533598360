<template>
  <div class="user-profile">
    <b-card>
      <div>
        <div class="description mb-0 text-right">
          <a href="#" v-b-popover.hover.top="$t('share_heading')" class="fa-solid fa-share-nodes p-0" :id="popover_id" onclick="return false"/>
          <b-popover :target="popover_id" triggers="focus" placement="bottom">
            <template slot="title">{{ $t('share_profile') }}</template>
            <ShareNetwork
              network="facebook"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-facebook-square fa-lg ml-3" style=" color: #3b5998; cursor: pointer;" />
            </ShareNetwork>
            <ShareNetwork
              network="linkedin"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-linkedin fa-lg ml-3 " style="cursor: pointer;" />
            </ShareNetwork>
            <ShareNetwork
              network="twitter"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-square-x-twitter fa-lg ml-3" style="color: black; cursor: pointer;" />
            </ShareNetwork>
            <ShareNetwork
              network="whatsapp"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-whatsapp fa-lg ml-3" style="color: #25d366; cursor: pointer;" />
            </ShareNetwork>
            <ShareNetwork
              network="reddit"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-reddit fa-lg ml-3" style="color: #ff5700; cursor: pointer;" />
            </ShareNetwork>
          </b-popover>
          <b-button v-if="!isOwnProfile" variant="link" v-on:click="report()" v-b-modal.modal-report-1 class="p-0 pl-2" v-b-popover.hover.top="$t('report_type_user')">
            <i class="fa-solid fa-flag" aria-hidden="true" />
          </b-button>
        </div>
        <div v-if="!editable" class="profile-image mb-2 cursor-pointer" @click="goToUser">
          <b-img v-if="image_path" rounded="circle" :src='formatImageIcon(image_path)' />
          <span v-if="!image_path" class="fa-stack fa-5x" style="z-index: 1">
              <i class="fa-solid fa-circle fa-stack-2x text-gray" />
              <i class="fa-solid fa-user fa-stack-1x fa-inverse text-light" />
          </span>
        </div>
        <div v-else class="profile-image edit-image mx-auto mb-2 w-100">
          <profile-update v-model="avatar" v-bind:imageUrl="image_path ? formatImageIcon(image_path) : image_path">
            <div slot="activator">
              <div onclick="return false;" class="add position-absolute">
                <i class="fa-solid fa-circle-plus" aria-hidden="true" />
              </div>
            </div>
          </profile-update>
        </div>
        <div class="user-label">
          <div class="username">
            <h3 class="mb-1 font-weight-bold cursor-pointer" @click="goToUser">{{ username }}</h3>
          </div>
        </div>

        <div class="mt-1 mb-3">
          <div class="d-flex flex-column holder">
            <div v-if="online || rating <= 0" class="d-flex justify-content-center align-items-center">
              <span v-if="online" class="user-online position-relative badge badge-success mx-1">{{ $t('online') }}</span>
              <ObjectLabel v-if="rating <= 0" type="new" class="mx-1" :object="this.task"></ObjectLabel>
            </div>
          </div>
        </div>

        <div class="ratings-box stats">
          <div class="d-flex flex-column holder">
            <div v-if="rating > 0" class="ratings-box-top" :class="(sellerResponse !== null && sellerResponse !== '0') || (sellerDelivery !== null && sellerDelivery !== '0') || (sellerAcceptance !== null && sellerAcceptance !== '0') || (sellerQueue !== null && sellerQueue !== '0') ? '' : 'rounded'">
              <div  class="rating-stars d-flex justify-content-center align-items-center">
                <ul class="mb-0 pl-0">
                  <li>
                    <i v-bind:class="{'fa-regular fa-star' : (rating < 0.5), 'fa-regular fa-star-half-stroke' : ( 0.5 <= rating & rating < 1 ) , 'fa-solid fa-star' : ( 1 <= rating )}" aria-hidden="true"/>
                  </li>
                  <li>
                    <i v-bind:class="{'fa-regular fa-star' : (rating < 1.5), 'fa-regular fa-star-half-stroke' : ( 1.5 <= rating & rating < 2 ) , 'fa-solid fa-star' : (2 <= rating )}" aria-hidden="true"/>
                  </li>
                  <li>
                    <i v-bind:class="{'fa-regular fa-star' : (rating < 2.5), 'fa-regular fa-star-half-stroke' : ( 2.5 <= rating & rating < 3 ) , 'fa-solid fa-star' : (3 <= rating )}" aria-hidden="true"/>
                  </li>
                  <li>
                    <i v-bind:class="{'fa-regular fa-star' : (rating < 3.5), 'fa-regular fa-star-half-stroke' : ( 3.5 <= rating & rating < 4 ) , 'fa-solid fa-star' : ( 4 <= rating )}" aria-hidden="true"/>
                  </li>
                  <li>
                    <i v-bind:class="{'fa-regular fa-star' : (rating < 4.5), 'fa-regular fa-star-half-stroke' : ( 4.5 <= rating & rating < 5 ) , 'fa-solid fa-star' : ( 5 <= rating )}" aria-hidden="true"/>
                    <strong> {{Number(rating).toFixed(1)}} </strong>
                    ({{ reviews.toLocaleString() }} {{ $t("reviews") }})
                  </li>
                </ul>
              </div>
            </div>

            <div class="ratings-box-btm pb-3" :class="rating <= 0 ? 'rounded-top' : ''" v-if="(sellerResponse !== null && sellerResponse !== '0') || (sellerDelivery !== null && sellerDelivery !== '0') || (sellerAcceptance !== null && sellerAcceptance !== '0') || (sellerQueue !== null && sellerQueue !== '0')">

              <!-- stat1 sellerResponse -->
              <div v-if="sellerResponse !== null && sellerResponse !== '0'" class="d-flex flex-row justify-content-start align-items-center px-3 pt-3 w-100 rating-stat">
                <div class="rating-icon d-flex justify-content-center align-items-center"><i class="fa-regular fa-comment-dots" aria-hidden="true"></i></div>
                <div class="d-flex flex-column full rating-title pb-1 position-relative">
                  <div class="d-flex flex-row justify-content-between pl-2">
                    <div class="">{{ $t('response_rate') }}</div>
                    <div class="font-weight-bold">{{sellerResponse}}%</div>
                  </div>
                  <div class="rating-bars position-relative mr-2">
                    <div class="rating-bar position-absolute track"></div>
                    <div class="rating-bar position-absolute ml-2" v-bind:class="{zero: !animationStart, red: sellerResponse > 0 && sellerResponse <= 33, yellow: sellerResponse >= 34 && sellerAcceptance <= 66, green: sellerResponse >= 67}" :style="'width: '+sellerResponse + '%;'"></div>
                  </div>
                </div>
              </div>
              <!-- stat2 sellerDelivery -->
              <div v-if="sellerDelivery !== null && sellerDelivery !== '0'" class="d-flex flex-row justify-content-start align-items-center px-3 pt-3 w-100 rating-stat">
                <div class="rating-icon d-flex justify-content-center align-items-center"><i class="fa-regular fa-clock" aria-hidden="true"></i></div>
                <div class="d-flex flex-column full rating-title pb-1 position-relative">
                  <div class="d-flex flex-row justify-content-between pl-2">
                    <div>{{ $t('order_delivered_on_time') }}</div>
                    <div class="font-weight-bold">{{sellerDelivery}}%</div>
                  </div>
                  <div class="rating-bars position-relative mr-2">
                    <div class="rating-bar position-absolute track"></div>
                    <div class="rating-bar position-absolute ml-2" v-bind:class="{zero: !animationStart, red: sellerDelivery >= 0 && sellerDelivery <= 33, yellow: sellerDelivery >= 34 && sellerDelivery <= 66, green: sellerDelivery >= 67}" :style="'width: '+sellerDelivery + '%;'"></div>
                  </div>
                </div>
              </div>
              <!-- stat3 sellerAcceptance -->
              <div v-if="sellerAcceptance !== null && sellerAcceptance !== '0'" class="d-flex flex-row justify-content-start align-items-center px-3 pt-3 w-100 rating-stat">
                <div class="rating-icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-check" aria-hidden="true"></i></div>
                <div class="d-flex flex-column full rating-title pb-1 position-relative">
                  <div class="d-flex flex-row justify-content-between pl-2">
                    <div>{{ $t('order_acceptance') }}</div>
                    <div class="font-weight-bold">{{sellerAcceptance}}%</div>
                  </div>
                  <div class="rating-bars position-relative mr-2">
                    <div class="rating-bar position-absolute track"></div>
                    <div class="rating-bar position-absolute ml-2" v-bind:class="{zero: !animationStart, red: sellerAcceptance > 0 && sellerAcceptance < 33, yellow: sellerAcceptance > 34 && sellerAcceptance < 66, green: sellerAcceptance > 67}" :style="'width: '+sellerAcceptance + '%;'"></div>
                  </div>
                </div>
              </div>
              <!-- stat3 sellerQueue-->
              <div v-if="sellerQueue !== null && sellerQueue !== '0'" class="d-flex flex-row justify-content-start align-items-center px-3 pt-3 w-100 rating-stat">
                <div class="rating-icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-users" aria-hidden="true"></i></div>
                <div class="d-flex flex-column full rating-title position-relative">
                  <div class="d-flex flex-row justify-content-between pl-2">
                    <div>{{ $t('orders_in_queue') }}</div>
                    <div class="font-weight-bold">{{sellerQueue}}</div>
                  </div>
                </div>
              </div>

              <!-- stat4 disputeQue Admin-->
              <div v-if="disputeQueue !== null && admin" class="d-flex flex-row justify-content-start align-items-center px-3 pt-3 w-100 rating-stat">
                <div class="rating-icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-hourglass-start" aria-hidden="true"></i></div>
                <div class="d-flex flex-column full rating-title position-relative">
                  <div class="d-flex flex-row justify-content-between pl-2">
                    <div>{{$t('total_disputes')}}</div>
                    <div class="font-weight-bold">{{disputeQueue}}</div>
                  </div>
                </div>
              </div>
              <!-- stat5 disputeAgainst Admin-->
              <div v-if="disputeAgainst !== null && admin" class="d-flex flex-row justify-content-start align-items-center px-3 pt-3 w-100 rating-stat">
                <div class="rating-icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-gavel" aria-hidden="true"></i></div>
                <div class="d-flex flex-column full rating-title position-relative">
                  <div class="d-flex flex-row justify-content-between pl-2">
                    <div>{{$t('total_disputes_against')}}</div>
                    <div class="font-weight-bold">{{disputeAgainst}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.$sanitize(this.shortDescription) != 'null'" class="user-description mt-1 pb-1 mb-0 info">
          <div class="description pt-3" v-if="!editable">
            <p class="mb-1 prewrap" v-html="this.$sanitize(shortDescription) === 'null' ? '' : this.$sanitize(shortDescription)"/>
          </div>
          <div class="mt-3" v-else>
            <div class="box-title bottomBorder mb-3">
              <div class="inner">
                <h2 class="my-0 ">{{$t('description_of_skills')}}</h2>
              </div>
            </div>
            <vue-editor id="textarea-state" v-model="description" :state="validation" :editorToolbar="customToolbar" class="text-left"/>
          </div>
        </div>
        <b-button v-if="messageSwitchCheck()" block variant="primary" @click="setQuickMessageBoxOpen(true)" class="contact-me d-none d-sm-block">{{$t('contact_me')}}</b-button>
        <b-button v-if="messageSwitchCheck()" block variant="primary" @click="contact" class="contact-me d-sm-none">{{$t('contact_me')}}</b-button>
        <InfoBox class="mb-0 profile-description" type="text" :location="country" :join="join"/>
        <MessagePopup v-if="messageSwitchCheck()" id="contactModal" :user-name="username" :user-image="image_path" :user-hash="hash" :user-last-active="lastActive"/>
      </div>

    </b-card>
    <LoginModal :reason="$t( 'login_reason_contact')" id="loginContact"/>
    <Report :type="$t('user').toLowerCase()" :refId="screen + hash" :options="$t('user_report_reasons')" :hash="hash"/>

  </div>
</template>

<script>
import MessagePopup from '../Modal/MessagePopup.vue'
import Report from './Report.vue'
import InfoBox from './InfoBoxSimple'
import ProfileUpdate from '../ImageUpload/ProfileUpload'
import LoginModal from '../Global/Login'
import helper from '../../helper'
import { VueEditor } from 'vue2-editor'
import ObjectLabel from '../Global/Label'
import { mapActions } from 'vuex'

export default {
  name: 'UserTaskProfile',
  props: {
    hash: String,
    username: String,
    join: String,
    country: String,
    shortDescription: String,
    sellerResponse: String,
    sellerDelivery: String,
    sellerAcceptance: String,
    sellerQueue: Number,
    statsLoaded: Boolean,
    task: Object,
    loadPage: Boolean,
    rating: Number,
    reviews: Number,
    online: Boolean,
    verify: Number,
    screen: {
      type: String,
      required: false,
      default: 'desktop'
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    buttons: {
      type: Boolean,
      required: false,
      default: false
    },
    admin: {
      type: Boolean,
      required: false,
      default: false
    },
    hasTasks: {
      type: Boolean,
      required: false,
      default: false
    },
    image_path: String,
    popover_id: String,
    disputeQueue: {
      type: Number,
      required: false,
      default: 0
    },
    disputeAgainst: {
      type: Number,
      required: false,
      default: 0
    },
    lastActive: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    Report,
    MessagePopup,
    ProfileUpdate,
    ObjectLabel,
    LoginModal,
    VueEditor,
    InfoBox
  },
  data () {
    return {
      animationStart: false,
      customToolbar: [['bold', 'italic', 'underline']],
      descriptionContent: this.shortDescription ? this.shortDescription : 'Check out my AnyTask profile',
      description: this.$sanitize(this.shortDescription) === 'null' ? '' : this.$sanitize(this.shortDescription),
      avatar: null,
      saved: false,
      saving: false,
      changedValues: [],
      isOwnProfile: localStorage.getItem('username') === this.username,
      validation: null,
      MessageSwitch: true,
      route: window.location.protocol + '//' + window.location.host + '/' + this.username
    }
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false
        this.saveChangedValues()
      },
      deep: true
    },
    description: {
      handler: function () {
        if (this.shortDescription !== this.$sanitize(this.description)) {
          this.saveChangedValues()
        }
      }
    }
  },
  mounted () {
    helper.reorderMetas()
    this.runStatAnimation()
  },
  methods: {
    ...mapActions(['setQuickMessageBoxOpen']),
    runStatAnimation () {
      this.animationStart = false
      setTimeout(() => { this.animationStart = true }, 2000)
    },
    messageSwitchCheck () {
      return !this.isOwnProfile
    },
    formatImageIcon (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    goToUser () {
      this.$router.push('/' + this.username)
    },
    saveChangedValues () {
      if (this.description !== this.shortDescription && this.avatar !== null) {
        this.changedValues.splice(0, 1)
        this.changedValues.push({ description: this.description, image: this.avatar.s3ObjectLocation })
      } else if (this.description !== this.shortDescription && this.avatar === null) {
        this.changedValues.splice(0, 1)
        this.changedValues.push({ description: this.description })
      } else if (this.avatar !== null) {
        this.changedValues.splice(0, 1)
        this.changedValues.push({ image: this.avatar.s3ObjectLocation })
      } else if (this.description === this.shortDescription && this.avatar === null) {
        this.changedValues = []
      }

      this.emitChanges()
      if (this.shortDescription !== this.description) {
        this.validation = this.description.length > 0
      } else {
        this.validation = null
      }
    },
    emitChanges () {
      this.$emit('updateProfile', this.changedValues)
    },
    report () {
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show('loginReport')
      } else {
        this.$bvModal.show(this.screen + this.hash)
      }
    },
    contact () {
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show('loginContact')
      } else {
        this.$bvModal.show('contactModal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .prewrap {
    white-space: pre-wrap;
  }

  .add {
    z-index: 1000;
    bottom: 12px;
    right: 14px;
  }

  .fa-circle-plus {
    background-color: #ffffff;
    color: #44aae1;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    display: block;
    font-size: 150%;
  }

  .fa-facebook-square {
    color: #3b5998;
    cursor: pointer;
  }

  .fa-linkedin {
    color: #0976b4;
    cursor: pointer;
  }

  .fa-twitter-square {
    color: #55acee;
    cursor: pointer;
  }

  .fa-whatsapp {
    color: #25d366;
    cursor: pointer;
  }

  .fa-reddit {
    color: #ff5700;
    cursor: pointer;
  }

  .edit-image {
    position: relative;
  }

  .profile-image img {
    width: 100%;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    border: $light-border;
    box-shadow: 0 0 20px -5px rgba(177, 177, 177, .5);
  }

  .profile-avatar {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
  }

  .no-link {
    cursor: default !important;
  }

  .rating-stars {
    padding: .75rem 0;
    &.no-border {
      border: 0;
    }
    ul {
      display: inline-block;
      li {
        float: left;
        padding-left: 2px;
        color: #62646a !important;
        i {
          color: $star-yellow;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    font-size: 14px;
  }

  .info ul li {
    padding-bottom: 10px;
    color: #62646a !important;
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .description {
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #62646a;
    button {
      color: inherit;
      text-decoration: unset;
    }
  }

  .box-title h2 {
    text-transform: none;
  }

  .contact-me {
    margin-top: 1.25rem;
  }

  .fa-share-nodes {
    color: #62646a;
    cursor: pointer;
    margin-left: 12px;
    font-size: 1.2em;
    text-decoration: none !important;
    &:hover {
      color: #44aae1;
      opacity: 1;
    }
  }

  .ratings-box {
    .holder {
      .ratings-box-top {
        background: #fbfbfb;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid $task-light-grey;
      }
      .ratings-box-btm {
        background: #fbfbfb;
        border-radius: 5px;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-left: 1px solid $task-light-grey;
        border-right: 1px solid $task-light-grey;
        border-bottom: 1px solid $task-light-grey;
        &.rounded-top {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-top: 1px solid $task-light-grey;
        }
      }
      .badge + .ratings-box-btm {
        border-top: 1px solid #e1e1e1;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .rating-stat {
        font-size: .8rem;
        .rating-icon {
          width: 30px;
          height: 30px;
          background: $brand-denim;
          border-radius: 8px;
          color: white;
          font-size: 1.25rem;
        }
        .full {
          flex: 1;
        }
        .rating-bar {
          height: 3px;
          border-radius: 10px;
          background: green;
          transition: all 2s;
          &.zero {
            width: 0 !important;
          }
          &.track {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            left: .5rem;
            background: $breadcrumbs-grey;
          }
          &.green {
            background: $green !important;
          }
          &.yellow {
            background: $star-yellow;
          }
          &.red {
            background: $brand-scarlet;
          }
        }
      }
    }
  }

</style>
<style lang="scss">
  .info-box-simple {
    margin-top: 1.25rem;
    .card-body {
      padding: 1rem;
    }
  }
</style>
