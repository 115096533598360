<template>
  <div>
    {{ list.parent }}
    <div class="d-none d-md-block">
      <h4 class="mb-0 text-left">{{ heading }}</h4>
      <ul v-if="type === 'category'">
        <li v-for="item in list" v-bind:key="item.parent.slug" class="pb-2">
          <router-link :to="url + item.parent.slug">{{ item.parent.name }}</router-link>
        </li>
      </ul>
      <ul v-else>
        <li v-for="(item, index) in list " v-bind:key="index" class="pb-2">
          <router-link v-if="'slug' in item" :to="url + item['slug']">{{ item.name }}</router-link>
          <a v-else :href="item['link']" target="_blank">{{ item.name }}</a>
        </li>
      </ul>
    </div>
    <div class=" d-block d-md-none">
      <b-button :class="showCollapse ? 'collapsed' : null"
                :aria-expanded="showCollapse ? 'true' : 'false'"
                :aria-controls="heading"
                @click="showCollapse = !showCollapse"
                class="m-1 p-1 w-100 bg-transparent border-0 text-left button">
        <h4 class="pb-0 text-left">{{ heading }} <i v-if="!showCollapse" class="fa-solid fa-chevron-down pr-2" style="float: right" aria-hidden="true"></i>
          <i v-if="showCollapse" class="fa-solid fa-chevron-up pr-2" style="float: right" aria-hidden="true"></i>
        </h4></b-button>
      <b-collapse :id="heading" v-model="showCollapse">
        <ul v-if="type === 'category'" class="m-1 p-1 text-left">
          <li v-for="item in list" v-bind:key="item.parent.slug" class="pb-2">
            <router-link :to="url + item.parent.slug">{{ item.parent.name }}</router-link>
          </li>
        </ul>
        <ul v-else class="m-1 p-1 ">
          <li v-for="(item, index) in list " v-bind:key="index" class="pb-2 text-left">
            <router-link v-if="'slug' in item" :to="url + item['slug']">{{ item.name }}</router-link>
            <a v-else :href="item['link']" target="_blank">{{ item.name }}</a>
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsibleList',
  props: {
    list: Array,
    heading: String,
    url: String,
    type: {
      type: String,
      required: false,
      default: 'links'
    }
  },
  data () {
    return {
      showCollapse: false
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 15px;
  color: #555;
  font-weight: bold;
  padding-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  line-height: 120%;
  color: #777 !important;
  cursor: pointer;
}

li:hover {
  text-decoration: underline;
}

b-button {
  width: 100%;
}

.button:focus, .button:active, btn-secondary {
  outline: none !important;
  box-shadow: none !important;
}
</style>
