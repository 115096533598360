import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Config from '../config'
import en from './en'

export const languages = {
  en: en
}

Vue.use(VueI18n)
const messages = Object.assign(languages)

export const i18n = new VueI18n({
  locale: Config.locale_default,
  fallbackLocale: Config.locale_default,
  messages
})
