import Router from 'vue-router'
import Meta from 'vue-meta'
import Vue from 'vue'
import config from '../config'
import { i18n } from '../lang'

import routes from './routes'
import store from '../store'

Vue.use(Router)
Vue.use(Meta, {
  refreshOnceOnNavigation: true
})

let settingTimer = null

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savePosition) {
    return { x: 0, y: 0 }
  },
  routes: routes
})

// Taken from https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

router.beforeEach(async (to, from, next) => {
  // Ensure we have config settings
  await buildSettings()
  clearInterval(settingTimer)
  settingTimer = setInterval(() => {
    buildSettings()
  }, 600000)

  let lang = localStorage.getItem('language')

  if (lang === 'undefined' || !config.locale_translated.hasOwnProperty(lang)) {
    lang = config.locale_default
    localStorage.setItem('language', lang)
  } else {
    lang = lang.toLowerCase()
  }

  if (i18n.locale !== lang) {
    import('@/lang/' + lang + '.json').then((msgs) => {
      i18n.setLocaleMessage(lang, msgs.default || msgs)
      i18n.locale = lang
      document.documentElement.setAttribute('lang', lang)
      return next()
    })
  }
  authCheck(to, next)
})

function authCheck (to, next) {
  if (to.matched.some(record => record.meta.requiresAuth) && store.state.accessToken === null) {
    localStorage.setItem('route', to.path)
    next('#login')
  } else if (store.state.accessToken !== null && store.state.user.hash === null && to.meta.authorize !== undefined) {
    store.dispatch('processJwt').then(function () {
      if (store.getters.processRoles(to.meta.authorize, to.params)) {
        next()
      } else {
        next('/')
      }
    })
  } else if (store.state.accessToken !== null && store.state.user.hash === null && to.meta.unavailableToSeller !== undefined) {
    store.dispatch('processJwt').then(function () {
      if (to.meta.unavailableToSeller && store.getters.hasRole('ROLE_SELLER')) {
        next({ name: 'myTasks' })
      } else {
        next()
      }
    })
  } else if (to.meta.authorize !== undefined) {
    store.dispatch('processJwt').then(function () {
      if (store.getters.processRoles(to.meta.authorize, to.params)) {
        next()
      } else {
        next('/')
      }
    })
  } else if (to.meta.unavailableToSeller && store.getters.hasRole('ROLE_SELLER')) {
    next({ name: 'myTasks' })
  } else if (to.meta.requiresBand && store.state.accessToken !== null) {
    store.dispatch('processJwt').then(function () {
      if (!store.getters.getBand) {
        next({ name: 'accountProfile' })
      } else {
        next()
      }
    })
  } else if (localStorage.getItem('signUpType') === 'firstTimeSeller') {
    localStorage.removeItem('signUpType')
    next({ name: 'accountLimits' })
  } else {
    next()
  }
}

function buildSettings () {
  return store.dispatch('buildSettings').then(() => {
    if (store.getters.isExpired()) {
      return store.dispatch('callSettings')
    }
  })
}

export default router
