<template>
    <b-modal
            id="contactModal"
            ref="modal"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleSubmit"
            size="lg"
            hide-footer
            centered
    >
        <template v-slot:modal-title>
          <div class="d-flex align-items-center text-left" :style="{ 'line-height: 1': !isUserOnline(userLastActive) }">
            <div>
              <b-img v-if="userImage" rounded="circle" :src='formatImageIcon(userImage)' class="mr-1 border" width="60px" />
              <span v-else class="fa-stack" style="z-index: 1; font-size: 1.50em;">
                  <i class="fa-solid fa-circle fa-stack-2x text-gray"/>
                  <i class="fa-solid fa-user fa-stack-1x text-light"/>
              </span>
            </div>
            <div class="ml-2">
              <span class="d-block font-weight-bold">
                {{ $t('contact_title') }} <span>{{ userName }}</span>
              </span>
              <template v-if="userLastActive">
                <span v-if="isUserOnline(userLastActive)" class="user-online position-relative badge badge-success">{{ $t('online') }}</span>
                <span v-else class="small">
                  {{ $t('last_seen') }} {{ displayLastSeen(userLastActive) }}
                </span>
              </template>
            </div>
          </div>
        </template>
        <form class="row p-0 p-sm-3" ref="form" @submit.stop.prevent="handleSubmit">
          <div class="col-12">
                <b-form-group :state="messageState" :invalid-feedback="messageError">
                    <b-form-textarea
                        id="Message"
                        v-model="message"
                        :state="messageState"
                        required
                        rows="5"
                        :placeholder="$t('message_input_placeholder')"
                    />
                </b-form-group>
                <div class=" mr-0 ml-0 text-right">
                    <b-button v-if="loading" class="btn-primary text-white border-0">
                      <b-spinner small/>
                    </b-button>
                    <b-button v-else class="btn-primary text-white border-0" @click="handleSubmit">
                      <i class="fa-solid fa-paper-plane" aria-hidden="true"/>
                    </b-button>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
import ApiService from '../../apiService'
import helper from '../../helper'
import config from '../../config'

export default {
  name: 'MessagePopup',
  data () {
    return {
      message: null,
      messageState: null,
      messageError: null,
      loading: false
    }
  },
  props: {
    userName: {
      type: String,
      required: true
    },
    userHash: {
      type: String,
      required: true
    },
    userImage: String,
    userLastActive: {
      type: String,
      required: false,
      default: null
    },
    postType: {
      type: Object,
      required: false,
      default () {
        return {
          type: 'direct',
          hash: null
        }
      }
    }
  },
  methods: {
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.messageState = !!valid
      this.messageError = this.$t('message_validation')
      return valid
    },
    resetModal () {
      this.message = null
      this.messageState = null
      this.messageError = null
      this.loading = false
    },
    handleSubmit (bvModalEvt) {
      this.loading = true

      // Prevent modal from closing
      bvModalEvt.preventDefault()

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        this.loading = false
        return
      }

      ApiService.sendContactMessage(this.userHash, this.message, this.postType).then(response => {
        if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'contactedSeller'
          })
        }

        if (config.tracking_enabled === 'true') {
          window.__at_px.fire('contact')
        }

        // Hide the modal
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })

        // Show success model
        this.$swal({
          type: 'success',
          title: this.$t('success'),
          text: this.$t('contact_success'),
          confirmButtonText: this.$t('close_button'),
          confirmButtonClass: 'btn-primary',
          position: 'top',
          imageWidth: 300
        })
        this.$emit('message-pop-up-success')
      }).catch((e) => {
        this.loading = false
        this.messageState = false

        if (e.response.data.error_message) {
          this.messageError = e.response.data.error_message
        } else {
          let errors = e.response.data.errors.children
          if (errors.message.errors) {
            this.messageError = errors.message.errors[0]
          } else if (errors.user.errors) {
            this.messageError = errors.user.errors[0]
          }
        }
      })
    },
    formatImageIcon (img) {
      return helper.formatCloudinaryUrl(img, 'profile_small')
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    },
    displayLastSeen (date) {
      if (helper.displayStaticLastSeen(date)) {
        return this.$t('last_seen_static')
      }
      return this.$moment(date).from()
    }
  }
}
</script>

<style>
    #contactModal .modal-dialog.modal-lg {
      max-width: 500px !important;
    }
</style>
