<template>
  <div class="filter-holder px-lg-0">
    <b-button :class="activeFilters.length ? 'btn-primary' : 'btn-dark'" class="w-100 mr-2 px-4 rounded-pill filter-button flex-grow-1 text-white border-0 d-lg-none" @click="openMobileFilters()">{{ $t('filter_results_button') }}</b-button>
    <div id="mobile-filters" class="bg-white mobile-filters rounded-pill px-3 px-lg-2 pb-5 pb-lg-0 mb-3 w-100" :class="filtersOpen ? 'open' : 'closed'">
      <AdvancedSearchFilters />
    </div>
  </div>
</template>

<script>
import AdvancedSearchFilters from './AdvancedSearchFilters'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AdvancedSearchFiltersHolder',
  components: { AdvancedSearchFilters },
  methods: {
    ...mapActions(['setFiltersOpen']),
    openMobileFilters () {
      document.getElementsByTagName('body')[0].classList.add('no-scroll')
      this.setFiltersOpen(true)
    }
  },
  computed: {
    ...mapState({ filtersOpen: state => state.search.filtersOpen, activeFilters: state => state.search.activeFilters })

  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/CSS/_variables.scss';

::v-deep .btn:focus {
  box-shadow: none !important;
}

::v-deep .btn:hover {
  border: $brand-sky 1px solid;
  font-weight: normal;
}

.filter-holder {
  padding: 0 !important;
}

.apply-button-container {
  border-top: 1.5px #F5F5F5 solid;
  height: 12vh;
  max-height: 100px;
  &.apply-closed {
    display: none!important;
  }
}

@media (max-width: 991px) {
  .mobile-filters {
    opacity: 0;
    animation: none;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: #F5F5F5 !important;
    border-radius: unset !important;

    &.open {
      opacity: 1;
      transition: all .25s;
      animation: fadeIn .25s ease-in-out;
    }

    &.closed {
      margin-top: -25px;
      opacity: 0;
      overflow: hidden;
      max-height: 0;
      transition: all .25s;
      animation: fadeOut .12s ease-in-out;
      z-index: 0;
    }
  }
}
</style>
