<template>
<div>
  <div class="reviews bg-white">
    <div class="card px-3 pt-3">
      <div class="top-review-header">
        <div class="box-title d-flex flex-column flex-sm-row justify-content-center align-items-center">
          <h4 class="review-count font-weight-bold m-0 mr-2">{{ reviewLength() }}&nbsp;{{ $t('reviews') }}</h4>
          <h4 class="review-average font-weight-bold m-0 ml-2">{{ averageRating }} {{ $t('average') }}</h4>
        </div>
      </div>
      <b-collapse visible id="reviews" class="mt-3">
        <TaskReviews v-for="review in allReviews" :key="review.id"
                    :time-posted="review.date_created"
                    :title="review.title"
                    :comment="review.message"
                    :username="review.author_user.username"
                    :rating="review.rating"
                    :photo_path="review.author_user.photo_path"
        />
      </b-collapse>
    </div>
  </div>
  <div class="load-more mx-auto" v-if="this.reviews.length > this.reviewLimit">
    <b-button variant="outline-primary" class="w-100" v-on:click="increaseReviews">Load more</b-button>
  </div>
</div>
</template>

<script>
import TaskReviews from './ReviewItem'

export default {
  data () {
    return {
      reviewLimit: 5,
      showCollapse: false,
      selected: 'a',
      options: [
        { value: 'a', text: 'Most Relevant' },
        { value: 'b', text: 'Most Recent' },
        { value: 'c', text: 'Positive Reviews' },
        { value: 'd', text: 'Negative Reviews' }
      ]
    }
  },
  name: 'Reviews',
  components: { TaskReviews },
  props: {
    averageRating: String,
    reviewCount: Number,
    reviews: Array,
    reviewsTitle: {
      type: String,
      required: false,
      default: 'Task Reviews'
    }
  },
  methods: {
    reviewLength () {
      const num = this.reviewCount
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    },
    increaseReviews: function () {
      this.reviewLimit += 5
    }
  },
  computed: {
    allReviews () {
      return this.reviewLimit ? this.reviews.slice(0, this.reviewLimit) : this.reviews
    }
  }
}
</script>

<style lang="scss" scoped>
.load-more {
  margin-top: 2rem;
  width: 100%;
  .btn {
    box-shadow: none !important;
  }
}
.review-count {
  border-right: 0;
  padding-right: 0;
}
.top-review-header {
  text-align: left;
  font-weight: normal;
  font-size: 19px;
  position: relative;
}

@include sm-up {
  .load-more {
    width: 30%;
  }
  .review-count {
    border-right: 1px solid $task-grey-border;
    padding-right: 20px;
  }
}
</style>
