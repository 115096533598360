<template>
  <div>
    <b-modal
      id="forgotPassword"
      ref="my-modal"
      size="md"
      :title="$t('forgot_password')"
      hide-footer
      @show="resetModal"
      @hidden="resetModal"
    >
      <div>
        <div class="p-4">
          <div v-if="forgotPasswordErrors !== null">
            <p class="alert alert-danger p-2">
              <span>
                 <i class="fa-solid fa-triangle-exclamation"></i>
                 {{ forgotPasswordErrors }}
              </span>
            </p>
          </div>
          <div id="success" v-if="success">
            <p>
              {{ $t('forgotten_password_success.paragraph_1') }}
            </p>
            <div class="alert alert-info d-flex">
              <div class="align-self-center mr-3">
                <i class="fa-solid fa-2x fa-circle-info"/>
              </div>
              <div class="small">
                <p class="m-0">{{ $t('forgotten_password_success.paragraph_2') }}</p>
              </div>
            </div>
            <div class="text-right">
              <button @click="$bvModal.hide('forgotPassword')" class="btn btn-primary">{{ $t('close_button') }}</button>
            </div>
          </div>
          <b-form @submit="requestPasswordChange" class="pt-2" v-if="show">
            <b-form-group id="input-group-1" label-for="input-1">
              <p>
                {{ $t('forgotten_password_support') }}
              </p>
              <b-form-input
                id="input-1"
                v-model="email"
                type="email"
                :placeholder="$t('enter_email')"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-4" class="m-0 mt-3">
              <div v-if="showSpinner">
                <b-button type="submit" class="w-100 py-2" variant="primary" disabled="disabled">
                  <b-spinner class="" small label="Large Spinner"></b-spinner>
                </b-button>
              </div>
              <div v-else>
                <b-button type="submit" class="w-100 py-2" variant="primary">
                  {{ $t('submit') }}
                </b-button>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import apiService from '../../apiService.js'

export default {
  name: 'ForgottenPassword',
  data () {
    return {
      email: '',
      showSpinner: false,
      show: true,
      forgotPasswordErrors: null,
      success: false
    }
  },
  mounted () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'forgotPassword') {
        localStorage.removeItem('signUpType')
      }
    })
  },
  methods: {
    resetModal () {
      this.email = ''
      this.showSpinner = false
      this.show = true
      this.forgotPasswordErrors = null
      this.success = false
    },
    toggleModal () {
      this.$bvModal.hide('signup')
      this.$bvModal.show('login')
    },
    requestPasswordChange (e) {
      e.preventDefault()
      this.showSpinner = true
      this.forgotPasswordErrors = null
      if (this.email === '') {
        this.forgotPasswordErrors = this.$t('join_validator_email')
        this.showSpinner = false
      } else {
        apiService.sendResetPasswordEmail(this.email).then((response) => {
          this.showSpinner = false
          this.show = false
          this.success = true
        }).catch((error) => {
          this.forgotPasswordErrors = error.response.data.error_message
          this.showSpinner = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
