<template>
    <div class="categories-nav bg-light px-2 border-bottom position-relative">
        <b-container fluid>
            <b-row>
                <b-col cols="12" class="px-3 py-0" id="nav" ref="content">
                    <CategoryDropDownButton v-for="category in getCategories" :key="category.id" :categoryName=category.parent.name :categoryNameEnglish=category.parent.name_en :categoryHref="category.parent.slug" :subCategories=category.child />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import CategoryDropDownButton from './CategoryDropdownButton'
import { mapActions } from 'vuex'

export default {
  name: 'CategoriesNav',
  components: { CategoryDropDownButton },
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      hideButtons: false
    }
  },
  mounted () {
    this.setWidth()
    window.addEventListener('resize', this.setWidth)
  },
  destroyed () {
    window.addEventListener('resize', this.setWidth)
  },
  methods: {
    ...mapActions(['setCategoryBarWidth']),
    setWidth () {
      this.setCategoryBarWidth(this.$refs.content.offsetWidth)
    }
  },
  computed: {
    getCategories () {
      return this.categories.slice(0, 9)
    }
  }
}
</script>

<style scoped>
    .categories-nav {
        border-top: 1px solid #ddd;
    }
</style>
