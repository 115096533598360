<template>
  <b-modal id="newsletter" ref="newsletter" size="lg" hide-header hide-footer no-close-on-backdrop>
    <div class="d-flex flex-column flex-lg-row">
      <div class="col-12 col-lg-5 d-none d-sm-flex image">
      </div>
      <div class="col-12 col-lg-7 d-flex flex-column p-1 p-sm-4">
        <h2 class="font-weight-bold mt-3 mb-0">{{ $t('newsletter_modal.title') }}</h2>
        <h3 class="font-weight-bold mb-4">{{ $t('newsletter_modal.sub_title') }}</h3>

        <b-form @submit="submit" class="pt-2">
          <div class="d-flex mx-auto mb-3 font-weight-normal">
            <b-form-checkbox id="checkbox-1" v-model="general" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
              {{ $t('newsletter_modal.option_one') }}
            </b-form-checkbox>
          </div>
          <div class="d-flex mx-auto mb-3">
            <b-form-checkbox id="checkbox-2" v-model="news" name="checkbox-2" value="accepted" unchecked-value="not_accepted">
              {{ $t('newsletter_modal.option_two') }}
            </b-form-checkbox>
          </div>
          <div class="d-flex justify-content-center">
            <b-button type="submit" @click="submitNoData = true" variant="link" class="w-100 mx-auto px-5 mt-3 mb-0 mb-md-3" v-if="!showSpinner">
              <span>{{ $t('no_thanks') }}</span>
            </b-button>
            <b-button type="submit" @click="submitNoData = false" variant="primary" class="w-100 mx-auto px-5 mt-3 mb-0 mb-md-3" v-if="!showSpinner">
              <span>{{ $t('submit') }}</span>
            </b-button>

            <b-spinner v-if="showSpinner"></b-spinner>
          </div>
        </b-form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import apiService from '../../apiService'
import { mapActions } from 'vuex'

export default {
  name: 'Newsletter',
  data () {
    return {
      general: '',
      news: '',
      marketing: [],
      showSpinner: false,
      submitNoData: false
    }
  },
  methods: {
    ...mapActions([
      'processJwt'
    ]),
    submit (e) {
      e.preventDefault()
      this.showSpinner = true

      if (!this.submitNoData) {
        if (this.general) {
          this.marketing.push(1)
        }
        if (this.news) {
          this.marketing.push(2)
        }
      }

      apiService.patchUser({ marketing: this.marketing }).then((data) => {
        localStorage.setItem('accessToken', data.data.data.token)
        this.processJwt()
        this.$bvModal.hide('newsletter')
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
#newsletter {
  .modal-content {
    overflow: hidden !important;
    border-radius: 10px !important;
    .modal-body {
      font-size: 16px;
      .image {
        background: url('~@/assets/modal-image.jpg');
        background-size: cover;
        min-height: 270px;
      }
      label {
        font-weight: normal;
      }
      .close {
        right: 10px;
        top: 10px;
        z-index: 9999;
      }
    }
  }

  @include md-up {
    .modal-content {
      .modal-body {
        .close {
          right: 10px;
          top: 10px;
        }
        button {
          width: auto !important;
        }
      }
    }
  }
}
</style>
