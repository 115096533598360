<template>
    <div>
        <b-modal
                :id="refId"
                ref="modal"
                :title="$t('report') +' ' + type"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                hide-footer
        >
            <div class="p-2 p-sm-3">
                <h3>{{ $t('report_heading')}}</h3>
                <p>{{ $t('report_promise') }}</p>
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group
                            label=""
                            label-for="type-input"
                            :invalid-feedback="$t('report_validation')"
                            :state="nameState"
                    >
                        <b-form-radio-group
                                v-model="reason"
                                :options="options[0]"
                                name="radios-stacked"
                                stacked
                        ></b-form-radio-group>
                    </b-form-group>
                    <h3 class="pb-3">{{$t('extra_info_report')}}</h3>
                    <b-form-group
                            label=""
                            label-for="Message"
                    >
                        <b-form-textarea
                                id="Message"
                                v-model="message"
                                required
                                rows="3"
                        ></b-form-textarea>
                    </b-form-group>
                </form>
                <div class=" m-0 p-0" v-if="!saving">
                    <b-button class="mt-3 p-1 mb-1 mb-sm-3 mr-auto ml-auto w-50 btn-primary text-white border-0" block @click="handleOk">{{ $t('submit') }}</b-button>
                </div>
                <div v-else class=" m-0 p-0">
                    <b-button class="mt-3 p-1 mb-1 mb-sm-3 mr-auto ml-auto w-50 btn-primary text-white border-0" block @click="handleOk">
                        <b-spinner small label="Large Spinner"></b-spinner>
                    </b-button>
                </div>

            </div>
        </b-modal>

        <Modal :title="$t('report_response_title')"
               :message="$t('report_response_message')"
               :type="refId.toLowerCase()"
        />
    </div>
</template>

<script>
import Modal from '../Modal/TextModal.vue'
import ApiService from '../../apiService.js'

export default {
  components: {
    Modal
  },
  props: {
    type: String,
    options: Array,
    refId: String,
    hash: String
  },
  data () {
    return {
      reason: '',
      nameState: null,
      saving: false,
      message: ''
    }
  },
  methods: {
    checkFormValidity () {
      const valid = !!this.reason
      this.nameState = valid
      return valid
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal () {
      this.message = ''
      this.saving = false
      this.reason = ''
    },
    handleSubmit () {
      this.saving = true
      if (!this.checkFormValidity()) {
        this.saving = false
        return
      }
      this.$nextTick(() => {
        let data = {}
        if (this.message.length > 0) {
          data = {
            'resource_url': window.location.href,
            'hash': this.hash,
            'reason': this.reason,
            'message': this.message
          }
        } else {
          data = {
            'resource_url': window.location.href,
            'hash': this.hash,
            'reason': this.reason
          }
        }
        ApiService.report(this.type.toLowerCase(), data).then(() => {
          this.$refs.modal.hide()
          this.$bvModal.show('text-modal_' + this.refId.toLowerCase())
        }).catch(() => {
          this.saving = false
        })
      })
    }
  }
}
</script>

<style scoped>
h3{
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 0;
    color: #0e0e0f;
    margin-bottom: 0;
}
p{
    padding-top: 5px;
    font-weight: 400;
    color: #999;
    font-size: 14px;
}
</style>
