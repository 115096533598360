<template>
  <div>
    <div class="drop-zone">
      <vue-dropzone
        :awss3="awss3"
        :options="dropzoneOptions"
        :include-styling="true"
        id="profile-dropzone"
        ref="profileDropzone"
        v-on:vdropzone-s3-upload-error="s3UploadError"
        v-on:vdropzone-s3-upload-success="s3UploadSuccess"
        v-on:vdropzone-removed-file="removeFile"
        v-on:vdropzone-thumbnail="checkDimension"
        v-on:vdropzone-sending="sending"
        v-on:vdropzone-error="displayError"
      >
        <slot name="activator"></slot>
        <div class="dropzone-custom-content">
          <b-img v-if="imageUrl" rounded="circle" :src='imageUrl' class="profile-photo-existing" />
           <span v-else class="fa-stack fa-5x profile-photo-existing" style="z-index: 1">
              <span class="fa-solid fa-circle fa-stack-2x" style="color: #2c3e50"/>
              <span class="fa-solid fa-user fa-stack-1x fa-inverse text-light"/>
          </span>
        </div>
      </vue-dropzone>
    </div>
    <p v-if="show" class="subheading alert alert-danger p-2 mb-0">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ApiService from '../../apiService'

export default {
  name: 'ProfileUpload',
  mounted () {
    const maxFiles = this.dropzoneOptions.maxFiles
    this.$refs.profileDropzone.dropzone.on('addedfile', function (file) {
      if (this.files.length > maxFiles) {
        this.removeFile(this.files[0])
      }
    })
    this.$refs.profileDropzone.dropzone.on('maxfilesexceeded', function (file) {
      this.removeFile(file)
    })
  },
  data () {
    return {
      awss3: {
        signingURL: ApiService.getTokenUrl('profile'),
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: {},
        sendFileToServer: false
      },
      dropzoneOptions: {
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 15,
        maxFiles: 1,
        acceptedFiles: 'image/bmp,image/gif,image/jp2,image/jpeg,image/png,image/svg+xml,image/tif,image/tiff,image/vnd.ms-photo,image/vnd.adobe.photoshop,image/webp,image/x-icon',
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        renameFile (file) {
          // eslint-disable-next-line no-return-assign
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        },
        accept (file, done) {
          file.acceptDimensions = done
          file.rejectDimensions = () => { done('Invalid dimension.') }
        }
      },
      show: false,
      errorMessage: this.$t('invalid_file')
    }
  },
  props: {
    value: null,
    imageUrl: String
  },
  components: {
    vueDropzone
  },
  methods: {
    s3UploadError () {
      this.show = true
      this.errorMessage = this.$t('invalid_file')
    },
    s3UploadSuccess (s3ObjectLocation) {
      this.show = false
      this.$emit('input', { s3ObjectLocation })
    },
    removeFile (file) {
      this.show = false
      this.$emit('input', null)
    },
    checkDimension (file, dataUrl) {
      if (file.width < 130 || file.height < 130) {
        file.rejectDimensions()
        this.errorMessage = (file.width < 130) ? this.$t('dimension.width', { 'minWidth': 130, 'imgWidth': file.width }) : this.$t('dimension.height', { 'minHeight': 130, 'imgHeight': file.height })
        this.show = true
      } else {
        file.acceptDimensions()
      }
    },
    sending (file) {
      if (!file.accepted) {
        // abort doesn't work. Setting the timeout is the only thing I've found that cancels the s3 request.
        file.xhr.timeout = 1
      }
    },
    displayError (file, message) {
      this.show = true
      this.errorMessage = message
    }
  }
}
</script>

<style>
  .drop-zone {
    z-index: 999;
    margin: auto;
    width: 164px;
  }
  .dz-size {
    margin-bottom: 0 !important;
    margin-top: 1rem;
  }
  .dz-message {
    color: #9990 !important;
    position: relative;
  }
  .dz-remove {
    top: initial !important;
    padding: 5px !important;
    left: 10px;
    margin: 0 !important;
    right: 10px;
  }
  .dz-details {
    text-align: center !important;
    padding: 1em !important;
  }
  .dz-preview {
    float: none !important;
    margin: 0 auto !important;
  }

  #profile-dropzone {
    background-color: rgba(255, 255, 255, 0);
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    letter-spacing: 0.2px;
    color: #000000;
    transition: background-color .2s linear;
    padding: 0;
    border-color: #ffffff00;
    overflow: hidden;
  }

  #profile-dropzone .dz-message {
    margin: 0
  }

  #profile-dropzone .dz-preview .dz-image img {
    border-radius: 50% !important
  }

  #profile-dropzone .dz-preview .dz-image img:not([src]) {
    width: 150px;
    height: 150px;
  }

  #profile-dropzone .dz-success-mark, #profile-dropzone .dz-error-mark {
    display: none;
    width: 75%;
  }

  #profile-dropzone .dz-error-message {
    display: none;
  }

  .dz-max-files-reached {
    pointer-events: none;
    cursor: default;
  }

</style>
