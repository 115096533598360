<template>
  <div id="message-container" :class="isQuickMessageBoxOpen() ? 'open' : 'closed cursor-pointer'" class="quick-message-box closed bg-gray d-none d-md-flex flex-col">
    <div @click.stop="openChatBox()" class="header px-2 position-relative d-flex bg-white align-items-center justify-content-between position-relative w-100">
      <div v-if="unreadMessages > 0 && !isQuickMessageBoxOpen()" class="ring-container">
        <div class="ringring border border-primary"></div>
        <div class="circle btn-primary border border-white text-white font-weight-bold">
          {{ unreadMessages }}
        </div>
      </div>
      <img v-if="sellerImagePath" alt="profile-picture" class="rounded-circle seller-image mr-2" :src='formatImageIcon(sellerImagePath)' />
      <div v-else class="seller-image mr-1 d-flex align-items-center justify-content-center">
        <div class="fa-stack fa-xl" style="font-size: 1.7rem;">
          <i class="fa-solid fa-circle fa-stack-2x text-gray"></i>
          <i class="fa-solid fa-user fa-stack-1x text-light"></i>
        </div>
      </div>
      <div ref="usernameContainer" class="w-100 username-container d-flex flex-col mr-1 align-items-start">
        <h5 v-if="!isQuickMessageBoxOpen()" ref="usernameAnimated" class="text-nowrap font-weight-bold m-0 overflow-hidden">{{$t('message') + ' ' + sellerUsername}}</h5>
        <div v-else>
          <h5 id="username" class="font-weight-bold m-0 overflow-hidden cursor-pointer" @click.stop="$router.push('/' + sellerUsername)">{{ sellerUsername }}</h5>
        </div>
        <span v-if="isUserOnline()" class="user-online mt-1 position-relative badge badge-success">{{ $t('online') }}</span>
      </div>
      <div>
        <i v-if="isQuickMessageBoxOpen()" @click.stop="setQuickMessageBoxOpen(false), textScrollOn()" class="fa-solid fa-angle-down cursor-pointer mr-2"></i>
        <div v-else class="py-2 rounded-circle plane-icon-button d-flex align-items-center justify-content-center" type="button"><i class="fa-solid fa-paper-plane fa-lg" aria-hidden="true" /></div>
      </div>
    </div>
    <div v-if="scrollTop < -100 && unreadMessages > 0" class="down-button bg-white position-absolute d-flex flex-col align-items-center justify-content-center cursor-pointer py-1" @click="scrollToEnd()">
      <small class="mb-0 font-weight-bold bg-primary rounded-circle text-white position-absolute notification">{{ unreadMessages }}</small>
      <i class="fa-solid fa-angle-down"></i>
    </div>
    <div v-if="isQuickMessageBoxOpen()" id="messageBox" @scroll="onScroll" class="message-chat-history w-100 d-flex flex-column-reverse pt-3">
      <div v-for="(message, index) in messages" :key="index">
        <SystemItem v-if="message.author_username === 'ANYTASKSYSTEM'" :message="message" />
        <ChatItem v-else :message="message" :showTranslated="message.showTranslated" :current-user="getUsername()" :quickMessage="true" @showTranslated="(data) => toggleShowTranslated(data, index)" @translateMessage="(data) => setTranslation(data, index)"/>
      </div>
      <b-alert v-if="errorSwitch" :show=true variant="danger" class="error mx-3 position-absolute">
        {{ errorMessage }}
      </b-alert>
    </div>
    <div v-if="isQuickMessageBoxOpen()" class="d-flex mt-auto py-2 my-1 px-2">
      <div contenteditable="true" :placeholder="$t('message_input_placeholder')" id="message-input" class="textAreaStyle p-3 pr-4 text-left flex-grow-1" @keyup="checkIfFilled" @keydown.enter.exact.prevent="sendMessage()"></div>
      <div v-if="textAreaFilled" class="align-self-center pl-2">
        <button v-if="!messageSending " class="btn btn-primary w-100 h-100 py-2 rounded-circle " type="button" @click.stop="sendMessage()"><i class="fa-solid fa-paper-plane" aria-hidden="true" /></button>
        <button v-else class="btn btn-primary w-100 h-100 rounded-circle " type="button">
          <b-spinner small label="small Spinner" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import { mapActions, mapGetters } from 'vuex'
import helper from '../../helper'
import SystemItem from '../Message/SystemItem.vue'
import ChatItem from '../Message/ChatItem.vue'
import threadMessagesCreatedSubscription from '../../graphql/threadMessagesCreatedSubscription'

export default {
  name: 'QuickMessageBox',
  components: {
    ChatItem,
    SystemItem
  },
  data () {
    return {
      textAreaFilled: false,
      unreadMessages: 0,
      scrollTop: 0,
      threadHash: '',
      errorSwitch: false,
      errorMessage: '',
      messages: [],
      messageSending: false,
      callingNext: false,
      firstContact: null,
      next: ''
    }
  },
  props: {
    sellerImagePath: String,
    sellerUsername: String,
    sellerHash: String,
    lastActive: String
  },
  mounted () {
    this.textScrollOn()
    this.isQuickMessageBoxOpen() && this.getDirectMessages()
  },
  methods: {
    ...mapActions([
      'setNewMessageCount',
      'setQuickMessageBoxOpen'
    ]),
    checkIfFilled () {
      const message = document.getElementById('message-input').innerText.replace(new RegExp(/(\n){3,}/, 'gim'), '\n\n')
      this.textAreaFilled = !!message.replace(/\s/g, '').length
    },
    textScrollOn () {
      setTimeout(() => {
        const containerWidth = this.$refs.usernameContainer.offsetWidth
        const usernameWidth = this.$refs.usernameAnimated.offsetWidth
        const duration = usernameWidth - containerWidth > 125 ? 17000 : usernameWidth - containerWidth > 75 ? 12000 : 7000
        if (usernameWidth > containerWidth) {
          this.$refs.usernameAnimated.animate([
            { transform: 'translateX(0px)', offset: 0 },
            { transform: 'translateX(0px)', offset: 0.2 },
            { transform: `translateX(calc(-100% + ${containerWidth}px))`, offset: 0.45 },
            { transform: `translateX(calc(-100% + ${containerWidth}px))`, offset: 0.55 },
            { transform: 'translateX(0px)', offset: 0.80 },
            { transform: 'translateX(0px)', offset: 1 }
          ], {
            duration: duration,
            iterations: Infinity
          })
        }
      }, 300)
    },
    setTranslation (data, index) {
      this.messages[index].translation = data
    },
    toggleShowTranslated (showTranslated, index) {
      this.messages[index].showTranslated = showTranslated
    },
    isUserOnline () {
      return helper.isUserOnline(this.lastActive)
    },
    scrollToEnd () {
      const container = document.getElementById('messageBox')
      container.scrollTop = 0
      this.unreadMessages = 0
    },
    openChatBox () {
      if (this.isLoggedIn && !this.isQuickMessageBoxOpen()) {
        this.setQuickMessageBoxOpen(true)
        this.unreadMessages = 0
        if (!this.threadHash && this.firstContact !== true) {
          this.getDirectMessages()
        }
      } else if (!this.isLoggedIn) {
        this.$bvModal.show('login')
      }
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scrollTop = scrollTop
      if (scrollTop > -50) {
        this.unreadMessages = 0
      }
      if (clientHeight + (~scrollTop) > scrollHeight - 50 && !this.callingNext && this.next !== null) {
        this.callingNext = true
        ApiService.getDirectMessagesNext(this.sellerUsername, this.messages[this.messages.length - 1].hash).then(response => {
          this.next = response.data.data.meta.next
          this.messages = this.messages.concat(response.data.data.results)
          this.callingNext = false
        })
      }
    },
    getDirectMessages () {
      ApiService.getDirectMessages(this.sellerUsername).then(response => {
        if (response.status === 200) {
          this.threadHash = response.data.data.meta.thread
          this.messages = response.data.data.results
          this.next = response.data.data.meta.next
          setTimeout(() => {
            this.scrollToEnd()
          }, 10)
          if (!this.$apollo.subscriptions.messagesCreated) {
            this.$apollo.addSmartSubscription('messagesCreated', {
              query: threadMessagesCreatedSubscription,
              variables () {
                return {
                  clientHash: this.getUserHash(),
                  threadHash: this.threadHash
                }
              },
              result ({ data }) {
                if (data.onCreateMessage) {
                  const payload = data.onCreateMessage
                  ApiService.getMessage(payload.thread_hash, payload.message_hash).then((resp) => {
                    this.messages.unshift(resp.data.data)
                    if (this.scrollTop < -100 || !this.isQuickMessageBoxOpen()) {
                      this.unreadMessages += 1
                    }
                  })
                }
              }
            })
          }
        } else if (response.status === 204) {
          this.firstContact = true
        }
      })
    },
    sendMessage () {
      const message = document.getElementById('message-input').innerText.replace(new RegExp(/(\n){3,}/, 'gim'), '\n\n')
      const isValidString = !!message.replace(/\s/g, '').length
      if (this.threadHash && isValidString) {
        this.messageSending = true
        ApiService.postMessage(this.threadHash, message).then(response => {
          this.errorMessage = ''
          this.errorSwitch = false
          this.messageSending = false
          this.getDirectMessages()
          document.getElementById('message-input').innerText = ''
          this.textAreaFilled = false
          this.scrollToEnd()
        }).catch((error) => {
          this.messageSending = false
          let errors = error.response.data.errors.errors
          this.errorMessage = errors[0]
          this.errorSwitch = true
        })
      } else if (isValidString) {
        this.messageSending = true
        ApiService.sendContactMessage(this.sellerHash, message, { type: 'direct', hash: null }).then(response => {
          document.getElementById('message-input').innerText = ''
          this.errorMessage = ''
          this.errorSwitch = false
          this.messageSending = false
          this.textAreaFilled = false
          this.firstContact = false
          this.getDirectMessages()
        }).catch((error) => {
          this.messageSending = false
          let errors = error.response.data.errors.errors
          this.errorMessage = errors[0]
          this.errorSwitch = true
        })
      }
    },
    formatImageIcon (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    }
  },
  destroyed () {
    if (this.$apollo.subscriptions.messagesCreated) {
      this.$apollo.subscriptions.messagesCreated.skip = true
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getUserHash', 'isQuickMessageBoxOpen']),
    isLoggedIn () {
      return this.$store.state.accessToken !== null
    }
  }
}
</script>

<style scoped lang="scss">
.quick-message-box {
  display: none;
  height: 400px;
  position: fixed;
  left: 15px;
  overflow: clip;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.15);
  box-shadow: 8px -8px 20px 2px rgba(0,0,0,.1);
  z-index: 999;
  transition: all .25s;
  animation: fadeIn .25s ease-in-out;
  bottom: 15px;

  &.closed {
    width: 280px;
    border-radius: 50px;
    border: 1px solid #dddddd;
    height: 70px;
    transition: all .25s;
    animation: fadeIn .25s ease-in-out;
    .header {
      height: 70px;
    }
  }

  &.open {
    width: 350px;
    height: 450px;
    border-radius: 15px;
    transition: all .25s;
    animation: fadeIn .25s ease-in-out;
    .header {
      height: unset;
      padding-block: 6px;
      .seller-image {
        z-index: 99;
      }
    }
  }

  [contenteditable=true]:empty:before{
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
  }

  .plane-icon-button {
    width: 55px;
    height: 55px;
    color: white;
    background-color: $brand-sky;
  }

  .down-button {
    z-index: 99;
    background: white;
    border-radius: 30px;
    right: 10px;
    width: 30px;
    height: 30px;
    bottom: 75px;
    border: 1px solid $task-light-grey;
    padding: 5px 12px;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.05);

    .notification {
      top: -10px;
      left: -10px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0 5px 2px rgba(0,0,0,0.05);
      border: 1px solid #F5F5F5 !important;
    }
  }

  .textAreaStyle {
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border: 1px solid $task-light-grey;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.05);
    font-size: 12px;
    color: #7c7c7d;
    border-radius: 25px;
    max-height: 100px;
    padding-block: 12px;
    padding-right: 40px !important;
    &:focus-visible {
      outline: none;
    }
  }

  .header {
    border-radius: 15px 15px 0 0;
    .seller-image {
      width: 55px;
      position: relative;
    }

    body {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .username-container {
      line-height: 1.5;
      overflow: hidden;
      padding: 0;
    }
  }

  .error {
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 0.8rem;
  }

  .ring-container {
    display: inline-block;
    position: absolute;
    top: -18px;
    left: 22px;
    z-index: 100;
    .circle {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 22px;
      height: 22px;
      top: 22px;
      left: 19px;
      font-size: 10px;
      padding-top: 3px;
    }
    .ringring {
      border-radius: 30px;
      border-width: 2px !important;
      height: 26px;
      width: 26px;
      position: absolute;
      left: 17px;
      top: 20px;
      animation: pulsate 1s ease-out;
      animation-iteration-count: infinite;
      opacity: 0.0
    }
  }
}
</style>
