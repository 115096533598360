import config from './config'
import store from './store'

export default {
  categoryName: 'helper',
  formatMobileUrl (filename, filetype) {
    if (filetype === 2) {
      return this.formatCloudinaryUrl(filename.replace(/\.([a-z0-9]{3,5})$/, '.jpg'), 'movie')
    } else {
      return this.formatCloudinaryUrl(filename, 'task')
    }
  },
  formatCloudinaryUrl (assetUrl, type) {
    let format = 'q_auto'
    let extension = 'jpg'
    let pos = config.cloudinary_domain.length

    switch (type) {
      case 'profile':
        format = 'c_fill,h_300,q_auto,w_300'
        break
      case 'profile_small':
        format = 'c_fill,h_100,q_auto,w_100'
        break
      case 'task':
        format = 'c_fill,h_220,q_auto,w_330'
        break
      case 'task_large':
        format = 'c_fill,h_425,q_auto,w_680'
        break
      case 'movie':
        format = 'c_fill,h_220,q_auto,w_330/c_scale,l_watermark:play,w_100'
        pos += 13
        break
      case 'movie_detail':
        format = 'c_fill,h_220,q_auto,w_330'
        pos += 13
        break
      case 'movie_large':
        format = 'c_fill,h_425,q_auto,w_680/c_scale,l_watermark:play,w_100'
        pos += 13
        break
      case 'movie_mp4':
        format = 'c_fill,h_220,q_auto,w_330'
        extension = 'mp4'
        pos += 13
        break
      case 'movie_mp4_large':
        format = 'c_fill,h_425,q_auto,w_680'
        extension = 'mp4'
        pos += 13
        break
    }

    return assetUrl.slice(0, pos) + format + assetUrl.slice(pos - 1, assetUrl.lastIndexOf('.') + 1) + extension
  },
  pauseVideoOnScroll () {
    let videos = document.getElementsByTagName('video')
    for (let i = 0; i < videos.length; i++) {
      let video = videos[i]
      if (!(video.getBoundingClientRect().top <= window.innerHeight * 0.75 && video.getBoundingClientRect().top > 0)) {
        video.pause()
      }
    }
  },
  luhnCheckDigit (code) {
    let validChars = '34679ACDEFGHJKMNPRTUVYWX_'
    let codeSliced = code.substring(0, code.length - 1)
    codeSliced = codeSliced.toUpperCase().trim()
    let checkDigit = code.substr(code.length - 1)
    if (isNaN(checkDigit)) {
      return false
    }
    let sum = 0
    let codeLength = codeSliced.length
    for (let i = 0; i < codeLength; i++) {
      let ch = codeSliced.charAt(codeLength - i - 1)
      if (validChars.indexOf(ch) < 0) {
        return false
      }
      let digit = ch.charCodeAt(0) - 48
      let weight
      if (i % 2 === 0) {
        weight = (2 * digit) - parseInt(digit / 5) * 9
      } else {
        weight = digit
      }
      sum += weight
    }
    sum = Math.abs(sum) + 10
    return (+sum + +checkDigit) % 10 === 0
  },
  reorderMetas () {
    if (navigator.userAgent.match(/\sPrerender\s/i)) {
      window.prerenderReady = false
      setTimeout(function () {
        let metas = document.getElementsByTagName('meta')
        const titleNode = document.getElementsByTagName('title')[0]
        for (let i = 0; i < metas.length; i++) {
          if (metas[i].getAttribute('property') && metas[i].getAttribute('property').substring(0, 3) === 'og:') {
            titleNode.parentNode.insertBefore(metas[i], titleNode.nextSibling)
          }
        }
        window.prerenderReady = true
      }, 200)
    }
  },
  dateDiffInDays (date) {
    return Math.ceil((new Date(date) - new Date()) / (24 * 60 * 60 * 1000))
  },
  displayStaticLastSeen (date) {
    return (this.dateDiffInDays(date) < -180)
  },
  isUserOnline (date, withinLastSeconds = 1000 * 60 * 60) {
    return new Date() - new Date(date) < withinLastSeconds
  },
  loadQuip (id) {
    return window.loadQuip(id)
  },
  currencyConversion (usd, currency, rate = null) {
    if (rate === null) {
      rate = store.getters.getCurrencyRate(currency)
    }

    if (currency === 'ETN') {
      return (usd / (rate === 0 ? 1 : rate)).toFixed(2) + ' ETN'
    }

    if (!store.getters.hasCurrency(currency)) {
      return (usd * (rate === 0 ? 1 : rate)) + ' ' + currency.toUpperCase()
    }

    return (usd * (rate === 0 ? 1 : rate)).toLocaleString(localStorage.getItem('language'), {
      style: 'currency',
      currency: rate === 0 ? 'USD' : currency
    })
  },
  getDisplayPrice (price, currency) {
    return this.currencyConversion(price, currency || store.getters.getCurrency())
  }
}
