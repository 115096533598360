const getDefaultUserState = () => {
  return {
    roles: [],
    hash: null,
    currency: 'USD',
    marketing: false,
    verification: 0,
    username: null,
    band: null,
    avatar: null,
    emailConfirmed: null,
    dob: null,
    giftCard: [],
    limits: [],
    favourites: [],
    paymentMethod: null,
    email: null,
    buyerOrders: null,
    cookieConsentGiven: false
  }
}

export default {
  state: getDefaultUserState(),
  getters: {
    getUser: (state) => () => {
      return state
    },
    getUserHash: (state) => () => {
      return state.hash
    },
    getUsername: (state) => () => {
      return state.username
    },
    getPaymentMethod: (state) => () => {
      return state.paymentMethod
    },
    getBand: (state) => () => {
      return state.band
    },
    hasRole: (state) => (role) => {
      return state.roles.includes(role)
    },
    getCurrency: (state) => () => {
      return state.currency
    },
    getVerification: (state) => () => {
      return state.verification
    },
    getMarketing: (state) => () => {
      return state.marketing
    },
    getFavourites: (state) => {
      return state.favourites
    },
    getDob: (state) => () => {
      if (state.dob !== null) {
        return state.dob.split('T')['0']
      }
      return state.dob
    },
    updateAvatar: (state, data) => {
      state.avatar = data
    },
    processRoles: (state) => (roles, params = null) => {
      for (let i = 0; i < roles.length; i++) {
        let role = roles[i] === 'ADMIN_DISPUTE_' ? 'ADMIN_DISPUTE_' + params.action : roles[i]
        if (state.roles.includes('ROLE_' + role.toUpperCase())) {
          return true
        }
      }
      return false
    },
    getGiftCard: (state) => () => {
      return state.giftCard
    },
    hasGiftCard: (state) => () => {
      if (state.giftCard.length === 0) {
        return false
      }
      const today = new Date()
      for (let i = 0; i < state.giftCard.length; i++) {
        let userBalance = state.giftCard[i]
        if (today < new Date(userBalance.date_expired)) {
          return true
        }
      }
      return false
    },
    isFavourite: (state) => (taskHash) => {
      return state.favourites.includes(taskHash)
    },
    isLoggedIn: (state, getters, rootState) => () => {
      return rootState.accessToken !== null && rootState.accessToken !== undefined
    }
  },
  mutations: {
    buildUser: (state, data) => {
      state.username = data.username
      state.avatar = data.avatar
      state.roles = data.roles
      state.hash = data.hash
      state.currency = data.currency
      state.marketing = data.marketing
      state.verification = data.verification
      state.band = data.band
      state.emailConfirmed = data.emailConfirmed
    },
    resetUserState: (state, data) => {
      Object.assign(state, getDefaultUserState())
    },
    setCurrency: (state, data) => {
      state.currency = data
    },
    setGiftCard: (state, data) => {
      state.giftCard = data
    },
    setLimits: (state, data) => {
      state.limits = data
    },
    setDob: (state, data) => {
      state.dob = data
    },
    setPaymentMethod: (state, data) => {
      state.paymentMethod = data
    },
    setFavourites: (state, data) => {
      state.favourites = data
    },
    addFavourite: (state, data) => {
      if (!state.favourites.includes(data)) {
        state.favourites.push(data)
      }
    },
    removeFavourite: (state, data) => {
      const index = state.favourites.indexOf(data)
      if (index > -1) {
        state.favourites.splice(index, 1)
      }
    },
    setHash: (state, data) => {
      state.hash = data
    },
    setEmail: (state, data) => {
      state.email = data
    },
    setBuyerOrders: (state, data) => {
      state.buyerOrders = data
    },
    setCookieConsentGiven: (state, data) => {
      state.cookieConsentGiven = data
    }
  },
  actions: {
    setCurrency ({ commit }, data) {
      localStorage.setItem('user-currency', data)
      commit('setCurrency', data)
    },
    buildUser ({ dispatch }) {
      dispatch('processJwt')
    },
    updateAvatar ({ commit }, data) {
      commit('updateAvatar', data)
    },
    resetUserState ({ commit }, data) {
      commit('resetUserState', data)
    },
    setGiftCard ({ commit }, data) {
      commit('setGiftCard', data)
    },
    setLimits ({ commit }, data) {
      commit('setLimits', data)
    },
    setDob ({ commit }, data) {
      commit('setDob', data)
    },
    setPaymentMethod ({ commit }, data) {
      commit('setPaymentMethod', data)
    },
    setFavourites ({ commit }, data) {
      commit('setFavourites', data)
    },
    addFavourite ({ commit }, data) {
      commit('addFavourite', data)
    },
    removeFavourite ({ commit }, data) {
      commit('removeFavourite', data)
    },
    setHash ({ commit }, data) {
      commit('setHash', data)
    },
    setEmail ({ commit }, data) {
      commit('setEmail', data)
    },
    setBuyerOrders ({ commit }, data) {
      commit('setBuyerOrders', data)
    },
    setCookieConsentGiven ({ commit }, data) {
      commit('setCookieConsentGiven', data)
    }
  }
}
