<template>
  <div v-if="subCategories.length > 1" class="category-dropdown-button d-inline-block" ref="content">
    <a :href="'/category/' + categoryHref" rel="noopener" @mouseover="checkOverFlow" :class="{ selected: hover }" class="px-1 px-md-2 px-lg-3 py-1 font-weight-normal">
      {{ categoryName }}
    </a>
    <div v-if="showSubMenu" @mouseover="hover = true" @mouseleave="hover = false"
         :style="setPosition()" class="dropdown-content position-absolute border-left border-right border-bottom bg-white">
      <div v-for="(group, i) in getGroupedCategory" :key="i" class="group mx-2 px-2">
        <div>
          <h5 class="text-left mb-2"> {{ group.name }}</h5>
          <ul class="text-left mb-3">
            <li @click.prevent="setCategoryFilter({name: category.name, nameEnglish: category.name_en, slug: category.slug, parentCategory: categoryName, parentCategoryEnglish: categoryNameEnglish}), setSearchOverlay(true), hideSubMenu()" v-for="(category) in group.childNodes" :key="category.hash" class="dropdown-link">
              <a :href="'/category/' + category.slug" rel="noopener">
                {{ category.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="category-dropdown-button d-inline-block" ref="content">
    <a :href="'/category/' + subCategories[0].slug" @click.prevent="setCategoryFilter({name: subCategories[0].name, nameEnglish: subCategories[0].name_en, slug: subCategories[0].slug}), setSearchOverlay(true)" :class="{ selected: hover }" class="px-1 px-md-2 px-lg-3 py-1 font-weight-normal">
      {{ categoryName }}
    </a>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CategoryDropDownButton',
  props: {
    categoryName: {
      type: String,
      required: true
    },
    categoryNameEnglish: {
      type: String,
      required: true
    },
    categoryHref: {
      type: String,
      required: true
    },
    subCategories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      numberOfGroups: this.subCategories.filter((subcategory) => subcategory.divider === true).length,
      hover: false,
      align: 'left',
      showSubMenu: true,
      wrapped: false
    }
  },
  mounted () {
    this.checkOverFlow()
    window.addEventListener('resize', this.checkOverFlow)
  },
  watch: {
    'categoryBarWidth': function () {
      this.checkOverFlow()
    }
  },
  methods: {
    ...mapActions([
      'setCategoryFilter',
      'setSearchOverlay'
    ]),
    setPosition () {
      let alignX = this.align === 'left' ? 'left:0;' : this.align === 'right' ? 'right:0;' : 'left:50%;transform: translate(-50%, 0%);'
      let positionY = this.wrapped ? 'top:72px;' : 'top:36px;'
      let colCount = null

      colCount = this.numberOfGroups >= 7 ? 'column-count:4;' : 'column-count:2;'

      return alignX + positionY + colCount
    },
    hideSubMenu () {
      this.showSubMenu = false
      setTimeout(() => {
        this.showSubMenu = true
      }, 20)
    },
    checkOverFlow () {
      const el = this.$refs.content.getBoundingClientRect()
      const centerPoint = ((el.right - el.left) / 2) + el.left
      const categoriesNavOffsetLeft = (window.innerWidth - this.categoryBarWidth) / 2
      if (centerPoint < (this.categoryBarWidth * 0.36) + categoriesNavOffsetLeft) {
        this.align = 'left'
      } else if (centerPoint > (this.categoryBarWidth * 0.63) + categoriesNavOffsetLeft) {
        this.align = 'right'
      } else {
        this.align = 'center'
      }

      if (this.$refs.content.offsetTop > 0) {
        this.wrapped = true
      } else {
        this.wrapped = false
      }
    }
  },
  computed: {
    ...mapState({ categoryBarWidth: state => state.search.categoryBarWidth, categoryFilter: state => state.search.categoryFilter }),
    getGroupedCategory: function () {
      const dataTree = []
      const hashTable = Object.create(null)
      let lastHashDivider

      this.subCategories.forEach((categoryData) => {
        hashTable[categoryData.hash] = { ...categoryData, childNodes: [] }
      })

      this.subCategories.forEach((categoryData) => {
        if (lastHashDivider && !categoryData.divider) {
          hashTable[lastHashDivider].childNodes.push(hashTable[categoryData.hash])
        } else {
          lastHashDivider = categoryData.hash
          dataTree.push(hashTable[categoryData.hash])
        }
      })

      return dataTree
    }
  }
}
</script>

<style lang="scss" scoped>
  .category-dropdown-button {
    box-sizing: border-box;

    padding: 6px 0;
    a {
      box-sizing: border-box;
      font-size: 14px;
      color: rgb(124, 124, 125);
      padding: 6px 0;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      border-bottom: #00acf8 2px solid;
      max-height: 36px;

      .dropdown-content {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .dropdown-content {
    padding: 40px 50px;
    visibility: hidden;
    opacity: 0;
    color: black;
    border-top: 1px solid #dee2e6;
    z-index: 10;
    transition: all .2s ease-in-out;
    border-radius: 0 0 5px 5px;
    width: max-content;
    min-width: 580px;
    display: block !important;

    .group {
      break-inside: avoid;

      h5 {
        font-weight: bold;
        opacity: 7;
      }

      ul {
        list-style-type: none;
        max-height: unset !important;
        padding-left: 0 !important;
      }
    }
  }

  .btn {
    font-weight: normal !important;
    color: rgb(124, 124, 125);
    border: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 0 !important;
  }

  .btn:hover,
  .btn.active:hover,
  .btn.active.focus,
  .btn.active:focus,
  .btn.focus,
  .btn.focus:active,
  .btn:active:focus,
  .btn:focus {
    background-color: #f8f9fa !important;
    border-top: 3px solid transparent !important;
    color: rgb(124, 124, 125) !important;
    outline: 0 !important;
    outline-offset: 0 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .dropdown-link {
    h5 {
      font-weight: 600;
      opacity: 0.7;
    }
    a {
      color: inherit; /* blue colors for links too */
      text-decoration: inherit; /* no underline */
      font-weight: 400 !important;
      font-size: 14px !important;
      /* opacity: 0.7; */
      display: block;
      width: 100%;
      padding: 4px 6px;
      &:hover {
        opacity: 1;
        background: #eeeeee;
      }
    }
  }

</style>
