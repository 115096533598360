<template>
  <div class="bg-light border-bottom border-top p-2 pb-3 p-sm-3 tv-ad d-flex justify-content-center">
    <div class="container align-items-sm-center d-flex flex-column flex-sm-row">
      <div class="text-secondary mb-2 mb-sm-0 mr-sm-4 mr-lg-5">{{ $t('tv_advert_modal_copy2') }}</div>
      <div class="px-2 align-items-center d-flex justify-content-between flex-grow-1">
        <img alt="NBC" :src="require('@/assets/Home/TvAds/NBC.svg')">
        <img alt="ABC" :src="require('@/assets/Home/TvAds/ABC.svg')">
        <img alt="CNN" :src="require('@/assets/Home/TvAds/CNN.svg')">
        <img alt="ESPN" :src="require('@/assets/Home/TvAds/ESPN.svg')">
        <img class="d-none d-sm-block" alt="Discovery Channel" :src="require('@/assets/Home/TvAds/DiscoveryChannel.svg')">
        <img class="d-none d-md-block" alt="Hulu" :src="require('@/assets/Home/TvAds/Hulu.svg')">
        <img class="d-none d-lg-block" alt="Viacom" :src="require('@/assets/Home/TvAds/Viacom.svg')">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TvAd'
}
</script>
<style lang="scss" scoped>
.tv-ad img {
  max-height: 30px;
  max-width: 75px;
}
</style>
