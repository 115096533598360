import Config from '../config'

class Tracker {
  enabled = Config.tracking_enabled === 'true'
  googleTagManager = Config.google_tag_manager

  constructor () {
    window.dataLayer = window.dataLayer || []
  }

  isEnabled () {
    return this.enabled && this.googleTagManager !== ''
  }

  sellerMakeOffer (eventName, data = {}) {
    this.tagEvent(eventName, data)
  }

  viewJobBoard (eventName, data = {}) {
    this.tagEvent(eventName, data)
  }

  createJobSection (eventName) {
    this.tagEvent(eventName)
  }

  buyerPurchaseJob (eventName, data = {}) {
    this.tagEvent(eventName, data)
  }

  tagEvent (eventName, data) {
    if (this.isEnabled()) {
      window.dataLayer.push(Object.assign({
        'event': eventName
      }, data))
    }
  }
}

export default {
  install: function (Vue) {
    const tracker = new Tracker()
    Object.defineProperty(Vue.prototype, '$tracker', { value: tracker })
  }
}
