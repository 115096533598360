<template>
  <div class="main-container w-100 py-0">
    <div class="top-categories">
      <div class="pb-3 px-2 d-flex tabs">
        <h2 class="tab-button" v-if="isLoggedIn && hasSuggestedTasks" :class="activeTab === 'suggested' && 'active'"  @click="activeTab = 'suggested'">{{ $t('task_showcase.suggested_tasks') }}</h2>
        <h2 class="tab-button" v-if="hasFeaturedTasks" :class="activeTab === 'featured' && (!isLoggedIn || !hasRecentTasks) ? 'homepage-title' : activeTab === 'featured' ? 'active' : ''" @click="activeTab = 'featured', !featuredTasks.length ? getFeatured() : null">{{ $t('task_showcase.featured_tasks') }}</h2>
        <h2 class="tab-button" v-if="isLoggedIn && hasRecentTasks" :class="activeTab === 'recent' && 'active'" @click="activeTab = 'recent'">{{ $t('task_showcase.recently_viewed') }}</h2>
      </div>
      <div v-if="activeTab === 'suggested' && isLoggedIn && hasSuggestedTasks" class="container px-0 p-0 carousel-container">
        <div class="carousel d-flex px-0">
          <div class="card-item w-25 px-2 my-2 p-0" v-for="(task, index) in suggestedTasks" v-bind:key="'s-' + index">
            <Card
              :carouselImage="task" :username="task.user.username" :slug="task.slug"
              :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
              :imageIcon="task.user.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
              :price="task.packages[0].price" :deliveryTime="task.packages[0].delivery" :hash="task.hash" :position="index + 1" :sellerInfo="task.user" :select="isFavourite(task.hash)"
              :primaryCategorySlug="task.categories[0].slug" :isHeroTask="task.hero" :isFeaturedTask="true" queryID="suggested"
            />
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'featured' && hasFeaturedTasks" class="container px-0 p-0 carousel-container">
        <div class="carousel d-flex px-0">
          <div class="card-item w-25 px-2 my-2 p-0" v-for="(task, index) in featuredTasks" v-bind:key="'f-' + index">
            <Card
              :carouselImage="task" :username="task.user.username" :slug="task.slug"
              :user_rating="task.user.review_average" :user_count="task.user.review_count" :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
              :imageIcon="task.user.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
              :price="task.packages[0].price" :deliveryTime="task.packages[0].delivery" :hash="task.hash" :position="index + 1" :sellerInfo="task.user" :select="isFavourite(task.hash)"
              :primaryCategorySlug="task.categories[0].slug" :isHeroTask="task.hero" :isFeaturedTask="true"
            />
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'recent' && isLoggedIn && hasRecentTasks" class="container px-0 p-0 carousel-container">
        <div class="carousel d-flex px-0">
          <div class="card-item w-25 px-2 my-2 p-0" v-for="(task, index) in recentTasks" v-bind:key="'r-' + index">
            <Card
              :carouselImage="task" :username="task.user.username" :slug="task.slug"
              :user_rating="task.user.review_average" :user_count="task.user.review_count" :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
              :imageIcon="task.user.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
              :price="task.packages[0].price" :deliveryTime="task.packages[0].delivery" :hash="task.hash" :position="index + 1" :sellerInfo="task.user" :select="isFavourite(task.hash)"
              :primaryCategorySlug="task.categories[0].slug" :isHeroTask="task.hero" :isFeaturedTask="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '../Task/Card.vue'
import helper from '../../helper'
import apiService from '../../apiService'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TaskShowcase',
  data () {
    return {
      activeTab: 'featured',
      suggestedTasks: [],
      hasSuggestedTasks: false,
      featuredTasks: [],
      hasFeaturedTasks: false,
      recentTasks: [],
      hasRecentTasks: false,
      limit: 4
    }
  },
  props: {
    location: {
      type: String,
      required: true
    }
  },
  components: {
    Card
  },
  mounted () {
    this.getTasks()
  },
  watch: {
    isLoggedIn () {
      this.getTasks()
    }
  },
  methods: {
    ...mapActions(['setSearchOverlay']),
    getTasks () {
      if (this.location === 'taskPage') {
        this.getSuggested()
      } else if (this.location === 'home') {
        this.getFeatured()
        this.isLoggedIn && this.getLastViewed()
        this.activeTab = 'featured'
      }
    },
    getLastViewed () {
      apiService.getLastViewed(this.limit).then((resp) => {
        this.recentTasks = resp.data.data.map((task) => task.gig)
        this.hasRecentTasks = !!this.recentTasks.length
      }).catch(() => {
      })
    },
    getSuggested () {
      apiService.getSuggested(this.limit).then((resp) => {
        this.recentTasks = resp.data.data.last_viewed
        this.hasRecentTasks = !!this.recentTasks.length
        this.suggestedTasks = resp.data.data.recommended
        if (this.suggestedTasks.length) {
          this.hasSuggestedTasks = true
          this.activeTab = 'suggested'
        } else {
          this.activeTab = this.location === 'home' ? 'featured' : 'recent'
        }
      }).catch(() => {
      })
    },
    getFeatured () {
      apiService.getFeaturedTasks().then((resp) => {
        this.featuredTasks = resp.data.data
        this.hasFeaturedTasks = !!this.featuredTasks.length
      }).catch(() => {
      })
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    }
  },
  computed: {
    ...mapGetters([
      'isFavourite'
    ]),
    isLoggedIn () {
      return this.$store.state.accessToken !== null
    }
  }
}
</script>

<style lang="scss" scoped>

.tabs {
  max-width: unset !important;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: scrollbar;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab-button {
    cursor: pointer;
    font-family: "Jaldi", Arial, Helvetica, sans-serif;
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
    font-size: 1.4rem;
    color: $light-grey;
    margin-right: 25px;
    white-space: nowrap;
    &.homepage-title {
      font-size: 1.7rem !important;
      color: #293c49;
      font-weight: bold;
      font-family: "Jaldi", Arial, Helvetica, sans-serif;
      margin-bottom: -6px !important;
    }
    &.active {
      font-weight: bold;
      text-decoration: underline;
      color: #293c49;
    }
    &:first-of-type {
      margin-left: 20px;
      @include xl-up {
        margin-left: 0;
      }
    }
  }
}

.carousel-container {
  max-width: unset !important;
  -ms-overflow-style: none;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .carousel {
    min-width: 1120px;
    .card-item {
      &:first-of-type {
        margin-left: 20px !important;
      }
      &:last-of-type {
        margin-right: 20px !important;
      }
      max-width: 270px;
      @include lg-up {
        max-width: unset;
      }
      @include xl-up {
        &:first-of-type {
          margin-left: 0 !important;
        }
        &:last-of-type {
          margin-right: 0 !important;
        }
      }
    }
  }
}
</style>
