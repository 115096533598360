<template>
  <div class="alert small alert-secondary mx-auto" role="alert">
    {{ message.body }}
  </div>
</template>

<script>
export default {
  name: 'SystemItem',
  props: {
    message: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@include md-up {
  .alert {
    width: 60%;
  }
}
</style>
