<template>
  <div class="banner">
    <div class="banner-fg w-100 h-100 d-flex flex-column-reverse flex-lg-row align-items-center justify-content-center">
      <div class="d-flex flex-column justify-content-center h-100 left">
        <h1 class="text-white text-center text-sm-left mb-0">{{ $t('home.banner.title_1') }}</h1>
        <h1 class="text-white text-center text-sm-left mb-4 mb-sm-3">{{ $t('home.banner.title_2') }}</h1>
        <h5 class="text-white d-none d-sm-block text-left pr-5 mb-0">{{ $t('home.banner.subtitle_1') }}</h5>
        <div class="d-none d-sm-flex align-items-center mb-4 justify-content-start">
          <h5 class="text-white text-left">{{ $t('home.banner.subtitle_2') }}</h5>
          <img height="24" src="../../assets/Crypto/etn.svg" alt="ETN" class="mx-2" style="width: 24px; height: 24px; margin-bottom: 3px;"/>
          <i alt="Bitcoin" class="fa-brands fa-bitcoin fa-xl text-white mr-1" style="margin-bottom: 2px;"></i>
          <img src="../../assets/Crypto/xrp-white.svg" alt="XRP" title="XRP" style="width: 24px; color: white; margin-bottom: 3px; margin-left: 3px;"/>
          <img src="../../assets/Crypto/bnb_logo.svg" alt="BNB" title="BNB" style="width: 24px; color: white; margin-bottom: 3px; margin-left: 7px;"/>
        </div>
        <div class="button-container d-flex flex-col flex-sm-row mb-5 mb-lg-0">
          <b-button @click="bannerButtonTrack('search'), setSearchOverlay(true)" class="rounded-pill py-2 py-sm-3 px-sm-5 mb-3 mb-sm-0 mr-sm-3" variant="primary">{{ $t('home.banner.search_button') }}</b-button>
          <b-button @click="bannerButtonTrack('list'), isLoggedIn ? $router.push({ name: 'task_or_job', params: { referrer: 'home_banner' } }) : listJobLogin()" class="rounded-pill py-2 py-sm-3 px-sm-5" variant="outline-light">{{ $t('home.banner.list_button') }}</b-button>
        </div>
      </div>
      <div class="right">
        <img class="d-none d-sm-block" src="../../assets/Home/Banners/faces_banner.png" alt="banner-images-faces">
        <img class="d-sm-none mt-3" src="../../assets/Home/Banners/faces_banner_mobile.png" alt="banner-images-faces-mob">
      </div>
    </div>
    <AnimatedGradientBackground />
  </div>
</template>

<script>
import config from '../../config'
import { mapActions } from 'vuex'
import AnimatedGradientBackground from '../Global/AnimatedGradientBackground'

export default {
  components: { AnimatedGradientBackground },
  methods: {
    ...mapActions([
      'setSearchOverlay'
    ]),
    listJobLogin () {
      sessionStorage.setItem('listJobLogIn', 'true')
      this.$bvModal.show('login')
    },
    bannerButtonTrack (type) {
      if (type === 'list') {
        this.$tracker.tagEvent('bannerClickListJob')
      } else {
        this.$tracker.tagEvent('bannerClickSearchTask')
      }
    }
  },
  computed: {
    config () {
      return config
    },
    isLoggedIn () {
      return this.$store.state.accessToken !== null && this.$store.state.accessToken !== undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;

  .banner-fg {
    position: relative;
    z-index: 100;
    padding-inline: 20px;

    .left {
      width: 100%;

      h1 {
        width: 100%;
        text-align: center;
        font-size: clamp(10px, 10vw, 45px);
        font-weight: bold;
      }

      .button-container {
        .btn {
          font-size: 4.3vw;
        }
      }
    }

    .right {
      width: 100%;

      img {
        width: 100%;
      }
    }

    @include sm-up {
      padding-inline: 50px;
      .left {
        margin-top: -70px;

        h1 {
          font-size: 6.5vw;
        }

        h5 {
          font-size: 2.5vw;
        }

        .button-container {
          width: 75%;

          .btn {
            font-size: 2.5vw;
          }
        }
      }
      .right {
        width: 100%;

        img {
          margin-left: 60px;
          width: 110%;
        }
      }
    }

    @include lg-up {
      .left {
        margin-top: 0;
        margin-block: 10%;
        padding-left: 50px;
        width: unset;

        h1 {
          font-size: clamp(45px, 4vw, 60px);
          font-weight: bold;
        }

        h5 {
          font-size: clamp(15px, 1.3vw, 20px);
        }

        .button-container {
          width: unset;

          .btn {
            font-size: clamp(15px, 1.2vw, 20px);
            width: unset;
          }
        }
      }
      .right {
        width: 45%;
        max-width: 850px;

        img {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
