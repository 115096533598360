<template>
  <div v-if="!isLoggedIn" class="d-flex">
    <b-button v-b-modal.login pill variant="primary" class="d-none account-buttons sign-in px-3 ml-2 d-lg-block" type="submit">{{ $t("sign_in") }}</b-button>
    <b-button v-b-modal.signup pill variant="outline-primary" class="d-none account-buttons px-3 ml-2 d-lg-block" type="submit">{{ $t("join_only") }}</b-button>
    <button class="border-0 d-lg-none bg-transparent user-icon-button text-right p-0" @click="showMobileLoginMenu = !showMobileLoginMenu" @blur="handleLoginBlur()">
      <i  class="fa-regular text-right fa-user fa-xl text-dark"></i>
    </button>
    <div v-if="showMobileLoginMenu" class="d-flex flex-col login-menu-mobile border">
      <b-button v-b-modal.login pill variant="primary" class="account-buttons-mob sign-in mb-2 py-2" type="submit">{{ $t("sign_in") }}</b-button>
      <b-button v-b-modal.signup pill variant="outline-primary" class="account-buttons-mob py-2" type="submit">{{ $t("join_only") }}</b-button>
    </div>
  </div>
  <div v-else>
    <b-nav-item-dropdown right class="my-0 ml-1">
      <template slot="button-content" class="p-0" >
        <img id="my-account-img" v-if="$store.state.user.avatar && $store.state.user.avatar !== 'null'" :src="formatProfilePhoto($store.state.user.avatar)" class="rounded-circle my-account-img" />
        <span v-else class="fa-stack fa-lg my-account-img">
          <i class="fa-solid fa-circle fa-stack-2x text-gray"></i>
          <i class="fa-solid fa-user fa-stack-1x text-light"></i>
        </span>
        <div v-if="getUserMenuNotifications() > 0" class="ring-container">
          <div class="ringring border border-primary"></div>
          <div class="circle btn-primary border border-white text-white font-weight-bold">
            {{ getUserMenuNotifications() }}
          </div>
        </div>
        <span id="my-account-name" class="pl-1 text-dark">{{ $t('hi') }} {{ user.username }}</span>
      </template>

      <!-- dropdown menu -->
      <b-dropdown-item class="p-0" :to="{ name: 'accountDashboard' }">
        {{ $t('dashboard.my_dashboard') }}
      </b-dropdown-item>
      <b-dropdown-item class="p-0" :to="{ name: 'view_my_purchases' }">
        {{ $t('view_orders_purchased_heading_copy') }}
        <span v-if="getNewDeliveriesCount() > 0" class="badge badge-info rounded-circle ml-2 d-flex justify-content-center align-items-center">{{ getNewDeliveriesCount() }}</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="hasRole('ROLE_SELLER') || hasRole('ROLE_PRE_SELLER')" :to="{ name: 'view_my_sales' }" class="p-0 sales d-flex">
        {{ $t('view_sales_heading') }}
        <span v-if="getNewOrdersCount() > 0" class="badge badge-info rounded-circle ml-2 d-flex justify-content-center align-items-center">{{ getNewOrdersCount() }}</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="hasRole('ROLE_SELLER') || hasRole('ROLE_PRE_SELLER')" class="p-0" :to="{ name: 'myTasks' }">{{ $t('my_tasks_title') }}</b-dropdown-item>
      <b-dropdown-item class="p-0 mail d-flex" :to="{ name: 'inbox' }">
        {{ $t('inbox') }}
        <span v-if="getNewMessageCount() > 0" class="badge badge-info rounded-circle ml-2 d-flex justify-content-center align-items-center">{{ getNewMessageCount() }}</span>
      </b-dropdown-item>
      <b-dropdown-item :to="{ name: 'job-view' }">
        {{ $t('job.my_account.title') }}
        <span v-if="getNewOffers().count > 0" class="badge pt-1 badge-info rounded-circle ml-2 d-flex justify-content-center align-items-center">{{ getNewOffers().count }}</span>
      </b-dropdown-item>
      <b-dropdown-item class="p-0" :to="{ name: 'accountProfile' }">{{ $t('account_profile') }}</b-dropdown-item>
      <b-dropdown-item v-if="$store.state.user.band" class="p-0" :to="{ name: 'accountLimits' }">{{ $t('account_limits.title') }}</b-dropdown-item>
      <b-dropdown-item v-if="hasRole('ROLE_SELLER') || hasRole('ROLE_PRE_SELLER')" class="p-0" :to="{ name: 'billing' }">{{ $t('billing_details_title') }}</b-dropdown-item>
      <b-dropdown-item class="p-0" :to="{ name: 'settings' }">{{ $t('notification_settings') }}</b-dropdown-item>
      <b-dropdown-item class="p-0" :to="{ name: 'saved-tasks' }">{{ $t('saved_tasks_heading') }}</b-dropdown-item>
      <b-dropdown-divider class="p-0"></b-dropdown-divider>
      <b-dropdown-item :to="{ name: 'job_board' }" link-class="align-items-center">
        {{ $t('job.board.title') }}
        <span class="badge badge-pill badge-primary ml-2">BETA</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="hasRole('ROLE_SELLER') || hasRole('ROLE_PRE_SELLER')" class="p-0" :to="{ name: 'task_or_job', params: { referrer: 'user_menu' }}">{{ $t('task_create') }}</b-dropdown-item>
      <b-dropdown-item v-if="!hasRole('ROLE_SELLER')" class="p-0" :to="{ name: 'BecomeSeller' }">{{ $t('become_seller_button_join') }}</b-dropdown-item>
      <template v-if="hasRole('ROLE_ADMIN')">
        <b-dropdown-divider class="p-0"></b-dropdown-divider>
        <b-dropdown-item class="p-0" :to="{ name: 'admin' }">{{ $t('admin_dashboard') }}</b-dropdown-item>
      </template>
      <b-dropdown-item class="p-0" target="_blank" :href='supportUrl'>{{ $t('help_support') }}</b-dropdown-item>
      <b-dropdown-divider class="p-0"></b-dropdown-divider>
      <b-dropdown-item class="p-0" @click="signOut"><strong>{{ $t('sign_out') }}</strong></b-dropdown-item>
      <!-- dropdown menu -->
    </b-nav-item-dropdown>
    <OrderNotificationModal ref="order-notification-modal"/>
  </div>
</template>

<script>
import helper from '../../helper'
import { mapActions, mapGetters, mapState } from 'vuex'
import notificationsCreatedSubscription from '../../graphql/notificationsCreatedSubscription'
import ApiService from '../../apiService'
import store from '../../store'
import OrderNotificationModal from '../Modal/OrderNotificationModal'

export default {
  name: 'UserMenu',
  data () {
    return {
      showMobileLoginMenu: false,
      supportUrl: 'https://support.anytask.com/'
    }
  },
  components: { OrderNotificationModal },
  watch: {
    $route (to, from) {
      let routeName = this.$route.name
      if (localStorage.getItem('accessToken') && routeName !== 'privacy_policy' && routeName !== 'intellectual_property' && routeName !== 'terms_conditions' && routeName !== 'anytask_protection') {
        this.termsRequired()
        this.newsletterModal()
      }
    }
  },
  mounted () {
    this.adminCheck()
    let routeName = this.$route.name
    if (localStorage.getItem('accessToken') && routeName !== 'privacy_policy' && routeName !== 'intellectual_property' && routeName !== 'terms_conditions' && routeName !== 'anytask_protection') {
      this.processJwt().then(() => {
        this.termsRequired()
        this.newsletterModal()
      })
    }
    this.homePage = routeName === 'home' || routeName === 'meet_the_sellers'
  },
  methods: {
    ...mapActions([
      'processJwt',
      'setMessageThreads',
      'signOut',
      'setNewMessageCount',
      'setNewOrdersCount',
      'setNewOffers',
      'setNewDeliveriesCount'
    ]),
    handleLoginBlur () {
      setTimeout(() => {
        this.showMobileLoginMenu = false
      }, 130)
    },
    formatProfilePhoto (img) {
      if (typeof img !== 'string') {
        return ''
      }
      return helper.formatCloudinaryUrl(img, 'profile_small')
    },
    adminCheck () {
      if (this.isLoggedIn) {
        let vm = this
        this.$store.dispatch('parseJwt').then(function (resp) {
          vm.getUnreadMessages()
          vm.$apollo.addSmartSubscription('notificationCreated', {
            query: notificationsCreatedSubscription,
            variables () {
              return {
                clientHash: this.getUserHash()
              }
            },
            result ({ data }) {
              if (data.onCreateNotification) {
                vm.getUnreadMessages()
              }
            }
          })
        })
      }
    },
    getUnreadMessages () {
      if (this.isLoggedIn && this.errorPage !== '500') {
        ApiService.getUnreadMessages().then(response => {
          this.setNewMessageCount(response.data.data.unread)
          this.setNewOrdersCount(response.data.data.new_orders)
          this.setNewDeliveriesCount(response.data.data.new_deliveries)
          this.setNewOffers({ count: response.data.data.offers, hashes: response.data.data.offers_hashes })
          if (response.data.data.unread > this.getNewMessageCount()) {
            this.$bvToast.show('message-toast')
          }
          if (typeof response.data.data.threads !== 'undefined') {
            this.setMessageThreads(response.data.data.threads)
          }
          if (response.data.data.new_orders >= 1) {
            this.$refs['order-notification-modal'].setTitle(this.$t('new_order_modal.new_order'))
            this.$refs['order-notification-modal'].setContent(this.$t('new_order_modal.congratulations'))
            this.$refs['order-notification-modal'].setLink('/my-account/sales#awaiting-acceptance')
            this.$refs['order-notification-modal'].show()
          } else if (response.data.data.new_deliveries >= 1) {
            this.$refs['order-notification-modal'].setTitle(this.$t('new_order_modal.new_delivery'))
            this.$refs['order-notification-modal'].setContent(this.$t('new_order_modal.congratulations_delivery'))
            this.$refs['order-notification-modal'].setLink('/my-account/orders/receive/' + response.data.data.orders_delivered[0] + '#delivered')
            this.$refs['order-notification-modal'].show()
          }
        }).catch(() => { })
      }
    },
    termsRequired () {
      if (this.$store.state.termsRequired) {
        this.$swal({
          title: this.$t('terms_conditions'),
          html: this.$t('accept_terms_update', { terms_link: '/terms-and-conditions' }),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('accept_button'),
          cancelButtonText: this.$t('sign_out'),
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
          preConfirm: (result) => {
            if (result === true) {
              let data = {
                terms_accepted: true
              }
              return ApiService.patchUser(data).then((dataUser) => {
                localStorage.setItem('accessToken', dataUser.data.data.token)
                this.processJwt()
              }).catch((error) => {
                this.$swal.showValidationMessage(
                  error.response.data.error_message
                )
              })
            }
          }
        }).then((result) => {
          if (result.value === true) {
            this.$swal(this.$t('terms_conditions'), this.$t('terms_accepted'), 'success')
          } else {
            store.dispatch('signOut')
          }
        })
      }
    },
    newsletterModal () {
      if (!this.$store.state.user.marketing && !localStorage.getItem('profile_incomplete')) {
        this.$bvModal.show('newsletter')
      }
    }
  },
  computed: {
    ...mapGetters(['getNewMessageCount', 'getUserHash', 'getNewOrdersCount', 'getNewDeliveriesCount', 'getUserMenuNotifications', 'hasRole', 'getNewOffers']),
    ...mapState(['user']),
    isLoggedIn () {
      return this.$store.state.accessToken !== null
    }
  }
}
</script>

<style lang="scss" >
.account-nav {

  .account-buttons {
    padding-block: 10px !important;

    &.sign-in:hover {
      background-color: #3f9dd2 !important;
    }
  }
  .user-icon-button {
    font-size: 1rem !important;
    width: 68px;
  }

  .login-menu-mobile {
    z-index: 100;
    position: absolute;
    background-color: #FFF;
    padding: 20px 20px;
    top: 60px;
    right: 0;
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(28, 27, 27, 0.25);

    .account-buttons-mob {
      width: 165px !important;
      font-size: 1rem !important;
    }
  }
  .dropdown-menu {
    padding: 10px 10px!important;
    margin-top: 15px;
    border-radius: 10px !important;
    z-index: 10;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.15);
    box-shadow: 0 4px 12px 0 rgba(0,0,0,.2);
    right:0;
    min-width: 200px;
    background-clip: unset;
    li {
      &:hover {
        background-color: #eeeeee;
      }
      .dropdown-divider {
        margin: 0;
        padding: 0 !important;
      }
      .dropdown-item {
        padding: 7px 3px !important;
        font-size: 0.9rem;
        display: flex !important;
        &:active {
          color: unset;
        }
        &:hover {
          background-color: unset !important;
        }

        span {
          min-width: 24px;
        }
      }
      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }

  .my-account-img {
    width: 40px !important;
    height: 40px !important;
    margin-inline: 5px;
  }
}

.ring-container {
  display: inline-block;
  position: absolute;
  top: -4px;
  left: -8px;
  .circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 22px;
    left: 25px;
    font-size: 10px;
    padding-top: 3px;
  }
  .ringring {
    border-radius: 30px;
    border-width: 2px !important;
    height: 26px;
    width: 26px;
    position: absolute;
    left: 23px;
    top: 20px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0
  }
}

@include md-up {
  .ring-container {
    top: 2px;
    left: 2px;
  }
}

@media (max-width: $breakpoint-md - 1) {
  .ring-container {
    .circle {
      top: 24px;
      left: 27px;
      font-size: 9px;
      padding-top: 5px;
    }
    .ringring {
      left: 25px;
      top: 22px;
    }
  }
}

@keyframes pulsate {
  0% {transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {transform: scale(1.2, 1.2); opacity: 0.0;}
}

@media (max-width: 450px) {
  #my-account-name {
    display: none;
  }
}
</style>
