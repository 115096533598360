<template>
  <div ref="autocompleteContainer" @click="scrollToTop" ></div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch/vue2/es'
import { connectSearchBox } from 'instantsearch.js/es/connectors'
import { autocomplete } from '@algolia/autocomplete-js'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { mapActions, mapState } from 'vuex'
import config from '../../config'

import '@algolia/autocomplete-theme-classic'

import algoliasearch from 'algoliasearch/lite'

export default {
  name: 'AutocompleteSearch',
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  beforeMount () {
    if (this.$route.query.query) {
      let query = this.$route.query.query
      let category = null
      for (let i = 0; i < this.categories.length; i++) {
        for (let index = 0; index < this.categories[i].child.length; index++) {
          if (!this.categories[i].child[index].divider && this.$route.query.query.includes(this.categories[i].child[index].name_en)) {
            let subcategory = this.categories[i].child[index]
            if (this.$route.query.query.includes(this.categories[i].parent.name_en + ' Other')) {
              category = { name: subcategory.name, slug: subcategory.slug, nameEnglish: subcategory.name_en, parentCategory: this.categories[i].parent.name, parentCategoryEnglish: this.categories[i].parent.name_en }
              query = this.$route.query.query.replace(this.categories[i].parent.name_en + ' Other', '')
              if (query[query.length - 1] === ' ') {
                query = query.slice(0, query.length - 1)
              }
            } else if (!subcategory.slug.includes('other')) {
              category = { name: subcategory.name, slug: subcategory.slug, nameEnglish: subcategory.name_en, parentCategory: this.categories[i].parent.name, parentCategoryEnglish: this.categories[i].parent.name_en }
              query = this.$route.query.query.replace(subcategory.name_en, '')
              if (query[query.length - 1] === ' ') {
                query = query.slice(0, query.length - 1)
              }
            }
          }
        }
      }
      this.advancedSearchInputValue = query
      sessionStorage.setItem('searchQuery', query)
      this.categoryFilterSelected = category && { ...category, overrideWatch: true }
    }
  },
  mounted () {
    const { instantSearchInstance } = this
    const self = this
    function setInstantSearchUiState (query) {
      if ((query && query.length >= self.minSearchCharacters) || !query) {
        instantSearchInstance.setUiState((uiState) => ({
          ...uiState,
          [config.advanced_search.index_name]: {
            ...uiState[config.advanced_search.index_name],
            page: 1,
            query: self.categoryFilter ? query + ' ' + self.getCategoryFilterName : query
          }
        }))
      }
    }
    const searchClient = algoliasearch(
      this.$store.getters.getAdvanceSearch().app_id,
      this.$store.getters.getAdvanceSearch().public_key
    )
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'instantsearch',
      limit: 3,
      transformSource ({ source }) {
        return {
          ...source,
          onSelect ({ item }) {
            sessionStorage.setItem('searchQuery', item.label)
            setInstantSearchUiState(item.label)
          }
        }
      }
    })
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: config.advanced_search.index_name + '_query_suggestions',
      getSearchParams () {
        return recentSearchesPlugin.data.getAlgoliaSearchParams({
          hitsPerPage: 6
        })
      },
      transformSource ({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect ({ item }) {
            sessionStorage.setItem('searchQuery', item.query)
            setInstantSearchUiState(item.query)
          },
          getItems (params) {
            return source.getItems(params)
          }
        }
      }
    })

    this.autocompleteInstance = autocomplete({
      container: this.$refs.autocompleteContainer,
      placeholder: this.$t('home.search.placeholder'),
      detachedMediaQuery: 'none',
      openOnFocus: true,
      plugins: [recentSearchesPlugin, querySuggestionsPlugin],
      initialState: { query: this.advancedSearchInputValue },
      onSubmit ({ state }) {
        sessionStorage.setItem('searchQuery', state.query)
        setInstantSearchUiState(state.query)
        self.setSearchOverlay(true)
      },
      onReset () {
        setInstantSearchUiState({ query: '' })
      },
      onStateChange ({ prevState, state }) {
        if (state.isOpen) {
          document.getElementsByClassName('aa-search-box-container')[0].classList.add('queries-open')
          document.getElementsByClassName('aa-Form')[0].classList.add('queries-open')
          document.getElementsByClassName('aa-ClearButton')[0].classList.add('queries-open')
        } else {
          document.getElementsByClassName('aa-search-box-container')[0].classList.remove('queries-open')
          document.getElementsByClassName('aa-Form')[0].classList.remove('queries-open')
          document.getElementsByClassName('aa-ClearButton')[0].classList.remove('queries-open')
        }
        sessionStorage.setItem('searchQuery', state.query)
        setInstantSearchUiState(state.query)
        self.setSearchOverlay(true)
      }
    })
    this.categoryFilterSelected && this.setCategoryFilter(this.categoryFilterSelected)
    document.getElementsByClassName('aa-ClearButton')[0].innerHTML = this.$t('home.search.clear')
  },
  data () {
    return {
      advancedSearchInputValue: '',
      categoryFilterSelected: '',
      minSearchCharacters: config.advanced_search.min_characters
    }
  },
  props: {
    categories: Array
  },
  methods: {
    ...mapActions([
      'setSearchOverlay',
      'setCategoryFilter'
    ]),
    updateQuery () {
      this.autocompleteInstance.setQuery(sessionStorage.getItem('searchQuery') ? sessionStorage.getItem('searchQuery') : '')
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  },
  watch: {
    categoryFilter () {
      if (!this.categoryFilter || !('overrideWatch' in this.categoryFilter)) {
        this.updateQuery()
      }
    },
    $route (to) {
      if (to.query.query) {
        this.updateQuery()
      }
    }
  },
  computed: {
    ...mapState({ categoryFilter: state => state.search.categoryFilter }),
    getCategoryFilterName () {
      return this.categoryFilter.name === 'Other' ? this.categoryFilter.parentCategoryEnglish + ' ' + this.categoryFilter.name : this.categoryFilter.nameEnglish
    }
  },
  beforeDestroy () {
    this.autocompleteInstance && this.autocompleteInstance.destroy()
  }
}
</script>

<style lang="scss">
.aa-Form {
  .aa-ClearButton {
    display: none;
    p {
      margin: 0;
    }

    &.queries-open {
      display: flex;
      align-items: center;
      padding-right: 15px;
    }
  }

  &:focus-within {
    .aa-ClearButton {
      display: flex;
      align-items: center;
      padding-right: 15px;
      p {
        margin: 0;
      }
    }
  }
}

.aa-ItemContentTitle {
  &:has(mark) {
    font-weight: normal !important;
  }
  font-weight: normal !important;
  mark {
    font-weight: 500;
    padding: 0;
  }
}
</style>
