<template>
  <span v-show="this.text" :id="this.id" :class="this.class">{{ $t(this.text) }}</span>
</template>

<script>
export default {
  name: 'ObjectLabel',
  props: {
    object: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      text: null,
      id: null,
      class: 'badge badge-primary'
    }
  },
  mounted () {
    if (this.type === 'new') {
      this.labelNew()
    } else if (this.type === 'hero') {
      this.labelHero()
    }
  },
  methods: {
    labelNew () {
      if (this.object.review_count === 0) {
        this.text = 'label_new_task'
        this.class = 'badge badge-primary d-inline-flex px-2'
      }
    },
    labelHero () {
      this.text = 'hero_task'
      this.class = 'hero-task badge text-white border-0 position-relative px-2'
    }
  }
}
</script>

<style lang="scss">
  .hero-task.badge {
    background: linear-gradient(90deg, rgba(189, 149, 46, 1) 0%, rgba(233, 205, 102, 1) 100%);
    &:after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.2);
      width: 30px;
      height: 100%;
      top: 0;
      filter: blur(5px);
      animation: shine 5s linear infinite;
    }
  }
</style>
