import apiService from '../../apiService'

export default {
  state: {
    settings: {},
    settingsLoaded: false,
    lastUpdated: null
  },
  getters: {
    getSettings: (state) => () => {
      return state.settings
    },
    getLastUpdated: (state) => () => {
      return state.lastUpdated
    },
    isSettingLoaded: (state) => () => {
      return state.settingsLoaded
    },
    isExpired: (state) => () => {
      if (state.lastUpdated === null) {
        return true
      }

      if (Object.keys(state.settings).length === 0 && state.settings.constructor === Object) {
        return true
      }

      return state.lastUpdated < new Date(Date.now() - 30 * 60 * 1000).getTime()
    },
    getAdvanceSearch: (state) => () => {
      return state.settings.advance_search
    }
  },
  mutations: {
    setSettings (state, data) {
      sessionStorage.setItem('settings', JSON.stringify(data))
      state.settings = data
    },
    setLastUpdated (state, data) {
      state.lastUpdated = data
    },
    setSettingsLoaded (state, data) {
      state.settingsLoaded = data
    }
  },
  actions: {
    buildSettings ({ commit, state }) {
      if (sessionStorage.getItem('settings') !== null) {
        commit('setSettings', JSON.parse(sessionStorage.getItem('settings')))
        commit('setLastUpdated', state.settings.last_updated)
        commit('setSettingsLoaded', true)
      }
    },
    callSettings ({ commit }) {
      return apiService.getSettings().then((response) => {
        response.data.data.last_updated = new Date().getTime()
        commit('setSettings', response.data.data)
        commit('setSettingsLoaded', true)
      }).catch(() => {
      })
    },
    setSettings ({ commit }, data) {
      commit('setSettings', data)
      commit('setLastUpdated', new Date().getTime())
      commit('setSettingsLoaded', true)
    },
    setLastUpdated ({ commit }, data) {
      commit('setLastUpdated', data)
    }
  }
}
