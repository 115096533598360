<template>
  <div class="d-flex flex-row currency-container">
    <div class="w-100">
      <p class="currency-warning pt-1 px-2 mb-3 text-center w-100" v-html="$t('currency_selector_warning')"/>
    </div>
    <div v-for="currency in getCurrencyRates()" :key="currency.code" @click="setItem(currency.code)"
         class="currency-item d-flex flex-column align-items-center" :class="{'active': selected === currency.code}">
      <h6 class="text-center m-0">{{ currency.code }}</h6>
      <span class="text-center">{{ currency.name }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ApiService from '@/apiService'

export default {
  name: 'CurrencySelector',
  data () {
    return {
      selected: 'USD'
    }
  },
  mounted () {
    if (localStorage.getItem('user-currency') !== null) {
      this.selected = localStorage.getItem('user-currency')
    }
    this.setCurrency(this.selected)
  },
  methods: {
    ...mapActions([
      'setCurrency'
    ]),
    setItem (value) {
      this.selected = value

      if (this.$store.state.accessToken !== null) {
        ApiService.patchUser({ 'currency': value }).then(response => {
          localStorage.setItem('accessToken', response.data.data.token)
          localStorage.setItem('refreshToken', response.data.data.refresh_token)
          this.$store.commit('updateAccessToken', response.data.data.token)
          this.$store.commit('updateRefreshToken', response.data.data.refresh_token)
        }).catch(() => { })
      }

      this.setCurrency(this.selected)
      this.$emit('updated')
    },
    getSelected () {
      return this.selected
    }
  },
  computed: {
    ...mapGetters(['getCurrencyRates'])
  }
}
</script>

<style lang="scss" scoped>
  .currency-container {
    .currency-warning {
      font-size: 0.85rem;
    }
    .currency-item {
      transition: all .25s;
      cursor: pointer;
      width: 33%;
      margin-bottom: 12px;
      &:hover {
        h6 {
          font-weight: bold;
        }
        span {
          color: $brand-sky;
          transition: all .25s;
        }
      }
      &.active {
        h6 {
          font-weight: bold;
        }
        span {
          color: $brand-sky;
        }
      }
      h6 {
        font-size: 1.3rem;
        line-height: 24px;
        @include sm-up {
          font-size: 1rem;
          line-height: 18px;
        }
        color: $brand-denim;
      }
      span {
        font-size: 8px;
        line-height: 10px;
        color: $breadcrumbs-grey;
      }
    }
  }
</style>
