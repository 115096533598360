/**
 * Px
 */
import config from '../../config'

window.__at_px = {
  img: null,
  id: '__at_px',
  do_not_track: false,
  u: null,
  r: null,
  d: null,
  t: null,
  v: null,
  h: null,
  g: null,
  x: null,

  /**
   * Initialise the Px
   *
   * @param {string} api Base URL for the px img host
   * @param {?string} h User hash
   */
  init: function (api, h = null) {
    // Check for DoNotTrack
    this.doNotTrack()
    if (this.do_not_track || config.tracking_enabled === 'false') {
      return
    }

    this.img = api + '/px'

    // Populate H
    this.h = h

    // Check we have a G
    this.initG()
  },

  doNotTrack: function () {
    if (window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack) {
      this.do_not_track = true
    }
  },

  /**
   * Fire the Px
   *
   * @param {string} t Event type
   * @param {?string} v Event value
   * @param {?string} u URL
   * @param {?string} r Referer URL
   */
  fire: function (t = 'view', v = null, u = null, r = null) {
    // Check for DoNotTrack
    if (this.do_not_track || config.tracking_enabled === 'false') {
      return
    }

    this.u = u || window.location.href
    this.r = r || document.referrer
    this.d = (new Date()).toISOString()
    this.t = t
    this.v = v
    this.x = Math.random().toString(36).slice(2)

    let src = this.img + '?u=' + encodeURIComponent(this.u)
    src += '&d=' + encodeURIComponent(this.d)
    src += '&t=' + encodeURIComponent(this.t)
    src += '&g=' + encodeURIComponent(this.g)
    src += '&x=' + encodeURIComponent(this.x)

    if (this.r) {
      src += '&r=' + encodeURIComponent(this.r)
    }

    if (this.h) {
      src += '&h=' + encodeURIComponent(this.h)
    }

    if (this.v) {
      src += '&v=' + encodeURIComponent(this.v)
    }

    let px = document.createElement('img')
    px.classList.add(this.id)
    px.src = src
    px.width = 1
    px.height = 1
    document.body.append(px)
  },

  /**
   * Initialise the G
   */
  initG: function () {
    if (this.g === null) {
      this.g = this.getCookie(this.id)
      if (this.g === null) {
        this.g = this.generateGuid()
      }
    }

    // Always set the cookie (if exists, it resets the expiry period)
    this.setCookie(this.id, this.g)
  },

  /**
   * Generate a Guid (Uuid v4)
   *
   * @returns {string}
   */
  generateGuid: function () {
    let dt = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  },

  /**
   * Get a cookie
   *
   * @param {string} cookieName
   *
   * @returns {?string}
   */
  getCookie: function (cookieName) {
    const name = cookieName + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }

    return null
  },

  /**
   * Set a cookie
   *
   * @param {string} name
   * @param {string} value
   */
  setCookie: function (name, value) {
    let date = new Date()
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
    document.cookie = name + '=' + value + ';path=/;expires=' + date.toUTCString()
  }
}
