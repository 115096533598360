<template>
  <div>
    <div v-if="!consentGiven && showBanner" class="consent-overlay h-100 d-flex flex-col flex-md-row w-100 fixed-bottom justify-content-end align-items-md-end">
      <div class="bg-light mx-4 my-4 px-5 d-flex flex-column flex-md-row justify-content-between align-items-md-center py-3 py-md-4">
        <div class="d-flex flex-column mb-2 mb-md-0 pr-1 py-2">
          <p class="text-center font-weight-bold text-muted text-md-left mb-1 mr-md-3 mb-md-0">{{ $t('cookie_banner.banner_text') }}</p>
        </div>
        <div class="d-flex flex-column flex-lg-row">
          <b-button @click="showBanner = false, $bvModal.show('cookieSettings')" variant="outline-primary" size="md" class="rounded-pill px-5 mb-2 mb-lg-0 font-weight-bold mx-lg-2">
            {{ $t('cookie_banner.cookie_settings_button') }}
          </b-button>
          <b-button @click="updateConsent()" variant="primary" size="md" class="rounded-pill px-5 font-weight-bold">
            {{ $t('cookie_banner.accept_button') }}
          </b-button>
        </div>
      </div>
    </div>
    <b-modal id="cookieSettings" centered ref="my-modal" hide-footer hide-header>
      <template #default="{ close }">
        <div class="d-flex flex-col p-4">
          <h3 class="mb-3">
            {{ $t('cookie_banner.cookie_settings.title') }}
          </h3>
          <p class="pb-3 text-muted text-center">
            {{ $t('cookie_banner.cookie_settings.text') }}
          </p>
          <div class="flex-col mb-3 mx-3">
            <div class="d-flex justify-content-between w-100">
              <p class="text-dark">{{ $t('cookie_banner.cookie_settings.essential') }} <small>{{ $t('cookie_banner.cookie_settings.required') }}</small></p>
              <p class="font-weight-bold text-muted mr-2">{{ $t('cookie_banner.cookie_settings.always_active') }}</p>
            </div>
            <div class="d-flex justify-content-between w-100">
              <p class="text-dark">{{ $t('cookie_banner.cookie_settings.marketing') }}</p>
              <b-form-checkbox size="lg" v-model="marketing" name="marketing" switch>
              </b-form-checkbox>
            </div>
          </div>
          <div class="flex-row w-100 justify-content-between">
            <b-button @click="updateConsent(), $bvModal.hide('cookieSettings')" class="rounded-pill py-2 my-2 flex-grow-1" variant="outline-primary">
              <div>{{ $t('cookie_banner.cookie_settings.save_settings') }}</div>
            </b-button>
            <b-button @click="updateConsent(), $bvModal.hide('cookieSettings')" class="rounded-pill py-2 my-2 ml-2 flex-grow-1" variant="primary">
              <div>{{ $t('cookie_banner.cookie_settings.allow_all') }}</div>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import config from '../../config'
export default {
  name: 'CookieBanner',
  data () {
    return {
      consentGiven: JSON.parse(localStorage.getItem('cookieConsent')),
      showBanner: true,
      marketing: true
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'cookieSettings' && !localStorage.getItem('cookieConsent')) {
        this.showBanner = true
      }
    })
    if (this.consentGiven) {
      this.updateConsent()
    }
  },
  methods: {
    ...mapActions(['setCookieConsentGiven']),
    updateConsent () {
      const consent = {
        'ad_storage': localStorage.getItem('cookieConsent') ? this.consentGiven.ad_storage : this.marketing ? 'granted' : 'denied',
        'ad_user_data': localStorage.getItem('cookieConsent') ? this.consentGiven.ad_user_data : this.marketing ? 'granted' : 'denied',
        'ad_personalization': localStorage.getItem('cookieConsent') ? this.consentGiven.ad_personalization : this.marketing ? 'granted' : 'denied',
        'analytics_storage': localStorage.getItem('cookieConsent') ? this.consentGiven.analytics_storage : this.marketing ? 'granted' : 'denied'
      }
      if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'userData',
          'user': {
            'hash': this.$store.state.user.hash,
            'email': this.$store.state.user.email
          }
        })
        window.dataLayer.push({
          'event': 'enrichment',
          'enrichment': {
            'user_type': !this.isLoggedIn() ? 'guest' : this.hasRole('ROLE_SELLER') || this.hasRole('ROLE_PRE_SELLER') ? 'seller' : 'buyer',
            'buyer_orders': this.$store.state.user.buyerOrders,
            'user_agent': window.navigator.userAgent,
            'mobile': window.navigator.userAgent.toLowerCase().indexOf('mobile') > -1
          }
        })
        this.gtag('consent', 'update', consent)
        window.dataLayer.push({
          'event': 'ConsentUpdate',
          'consent': consent
        })
      }
      if (!localStorage.getItem('cookieConsent')) {
        localStorage.setItem('cookieConsent', JSON.stringify({
          ad_storage: this.marketing ? 'granted' : 'denied',
          ad_user_data: this.marketing ? 'granted' : 'denied',
          ad_personalization: this.marketing ? 'granted' : 'denied',
          analytics_storage: this.marketing ? 'granted' : 'denied'
        }))
        this.consentGiven = JSON.parse(localStorage.getItem('cookieConsent'))
      }
      this.setCookieConsentGiven(true)
    },
    gtag () {
      window.dataLayer.push(arguments)
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isLoggedIn', 'hasRole'])
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
  border-radius: 15px !important;
}

.consent-overlay {
  z-index: 1060;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
