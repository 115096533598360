export default {
  anytasks_name: process.env.VUE_APP_NAME,
  anytasks_legal: 'AnyTask Ltd',
  api_url: process.env.VUE_APP_API_URL,
  frontend_url: process.env.VUE_APP_FRONTEND_URL,
  task_search_results_amount_to_show_default: process.env.VUE_APP_RESULTS_PER_PAGE, // Divisible by 6
  cloudinary_domain: process.env.VUE_APP_CLOUDINARY_DOMAIN, // Must include trailing slash
  error_pages: ['500', '404', '503'],
  google_tag_manager: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
  google_tag_manager_auth: process.env.VUE_APP_GOOGLE_TAG_MANAGER_AUTH,
  google_tag_manager_preview: process.env.VUE_APP_GOOGLE_TAG_MANAGER_PREVIEW,
  google_tag_manager_cookies_win: process.env.VUE_APP_GOOGLE_TAG_MANAGER_COOKIES_WIN,
  zendesk_widget_id: process.env.VUE_APP_ZENDESK_WIDGET_ID,
  tracking_enabled: process.env.VUE_APP_TRACKING_ENABLED,
  my_etn: process.env.VUE_APP_MY_ETN_URL,
  recapture: process.env.VUE_APP_RECAPTURE,
  etn_deeplink: {
    connecting: process.env.VUE_APP_QR_DEEPLINK,
    app: process.env.VUE_APP_ETN_APP_DEEPLINK
  },
  social: {
    twitter: 'anytaskofficial',
    facebook: 'anytaskofficial',
    linkedin: 'showcase/anytaskofficial',
    instagram: 'anytaskofficial',
    youtube: 'anytask',
    pinterest: 'AnyTaskOfficial'
  },
  etn_vendor: {
    polling_url: process.env.VUE_APP_ETN_VENDOR_POLLING_URL,
    polling_time: process.env.VUE_APP_ETN_VENDOR_POLLING_TIME,
    reauth_time: process.env.VUE_APP_ETN_VENDOR_REAUTH_TIME
  },
  etn_under_maintenance: process.env.VUE_APP_ETN_UNDER_MAINTENANCE === 'true',
  payment: {
    crypto: {
      polling_time: process.env.VUE_APP_CRYPTO_POLLING_TIME
    }
  },
  beta: process.env.VUE_APP_BETA,
  unread_messages_check_ms: process.env.VUE_APP_UNREAD_MESSAGES_CHECK_MS,
  currency_rate_check_ms: process.env.VUE_APP_CURRENCY_RATE_CHECK_MS,
  task_results: {
    max_displayed_pages: process.env.VUE_APP_MAX_DISPLAYED_PAGES
  },
  advanced_search: {
    index_name: process.env.VUE_APP_ADVANCED_SEARCH_INDEX,
    query_index: process.env.VUE_APP_ADVANCED_SEARCH_QUERY_INDEX,
    min_characters: process.env.VUE_APP_ADVANCED_SEARCH_MIN_CHARS,
    max_characters: process.env.VUE_APP_ADVANCED_SEARCH_MAX_CHARS
  },
  settingsCallInterval: process.env.VUE_APP_SETTINGS_CALL_INTERVAL,
  locale_default: 'en',
  locale_translated: {
    'en': {
      flag: 'us',
      name: 'English'
    },
    'ar': {
      flag: 'sa',
      name: 'العربية',
      popup: {
        title: 'هل تريد منا ترجمة AnyTask.com إلى اللغة العربية',
        content: '؟ يرجى أخذ بعين الإعتبار أن 99 ٪ من المشترين يتحدثون الإنجليزية، لذلك ستظل المهام والبحث باللغة الإنجليزية.',
        button: 'ترجمة إلى العربية'
      }
    },
    'de': {
      flag: 'de',
      name: 'Deutsch',
      popup: {
        title: 'Möchtest du, dass wir AnyTask.com für dich auf Deutsch übersetzen?',
        content: 'Hinweis: 99 % der Käufer sind englischsprachig, daher bleiben die Aufgaben und die Suche auf Englisch.',
        button: 'Ins Deutsche übersetzen'
      }
    },
    'es': {
      flag: 'es',
      name: 'Español',
      popup: {
        title: '¿Te gustaría traducir AnyTask.com en Español?',
        content: 'Ten en cuenta que el 99% de los compradores son de habla Inglesa, por lo que las tareas permanecerán en Inglés.',
        button: 'Traducir al español'
      }
    },
    'fr': {
      flag: 'fr',
      name: 'Français',
      popup: {
        title: 'Souhaitez-vous que nous traduisions AnyTask.com en Français? ',
        content: 'Veuillez noter que 99% des acheteurs sont anglophones, les tâches et la recherche resteront donc en anglais.',
        button: 'Traduire en français'
      }
    },
    'nl': {
      flag: 'nl',
      name: 'Nederlands',
      popup: {
        title: 'Wilt u dat wij AnyTask.com naar het Nederlands vertalen?',
        content: 'Let er op dat 99% van de kopers Engels spreekt, dus taken en opzoeken blijft in het Engels.',
        button: 'Vertalen naar het Nederlands'
      }
    },
    'he': {
      flag: 'il',
      name: 'עברית',
      popup: {
        title: 'האם תרצו שנתרגם את AnyTask.com ל- עברית?',
        content: 'שימו לב, 99% מהקונים דוברי אנגלית, כך שהמשימות והחיפוש יישארו באנגלית.',
        button: 'תרגם לעברית'
      }
    },
    'hi': {
      flag: 'in',
      name: 'हिन्दी',
      popup: {
        title: 'क्या आप चाहते हैं कि हम AnyTask.com का हिन्दी में',
        content: 'अनुवाद करें? कृपया ध्यान दें, 99% बायर अंग्रेजी बोलने वाले हैं, इसलिए टास्क और सर्च अंग्रेजी में ही रहेंगे।',
        button: 'हिंदी में अनुवाद करें'
      }
    },
    'id': {
      flag: 'id',
      name: 'Bahasa Indonesia',
      popup: {
        title: 'Terjemahkan AnyTask.com ke dalam Bahasa Indonesia?',
        content: 'Perlu diketahui, 99% pembeli berbicara dalam bahasa Inggris, jadi tugas dan pencarian akan tetap dalam bahasa Inggris.',
        button: 'Terjemahkan ke Bahasa Indonesia'
      }
    },
    'it': {
      flag: 'it',
      name: 'Italiano',
      popup: {
        title: 'Vuoi tradurre AnyTask.com in Italiano?',
        content: 'Sei pregato di notare che il 99% dei compratori parla inglese, perciò gli incarichi e le ricerche resteranno in inglese.',
        button: 'Traduci in italiano'
      }
    },
    'ja': {
      flag: 'jp',
      name: '日本語',
      popup: {
        title: 'AnyTask.comの日本語への翻訳を希望しますか？',
        content: '買い手の99％は英語を使用していますので、タスクと検索は英語のままとなります。',
        button: '日本語に翻訳'
      }
    },
    'kh': {
      flag: 'kh',
      name: 'ភាសាខ្មែរ',
      popup: {
        title: 'តើអ្នកចង់ឲ្យយើងបកប្រែ AnyTask ទៅជា ភាសាខ្មែរ ទេ?',
        content: 'សូមចំណាំថា ៩៩ភាគរយនៃអ្នកទិញរបស់យើងនិយាយភាសាអង់គ្លេស ដូចនេះកិច្ចការ និងការស្វែងរកនឹងនៅតែជាភាសាអង់គ្លេសដដែល',
        button: 'បកប្រែជាភាសាខ្មែរ'
      }
    },
    'ko': {
      flag: 'kr',
      name: '한국어',
      popup: {
        title: '저희가 AnyTask.com를 한국어(으)로 번역하도록 하시겠습니까? ',
        content: '주의하세요, 구매자의 99%는 영어를 사용하므로, 과업과 검색은 영어로 남게 될 것입니다.',
        button: '한국어로 번역'
      }
    },
    'pt': {
      flag: 'pt',
      name: 'Português',
      popup: {
        title: 'Gostarias de traduzir o AnyTask.com para Português?',
        content: 'Nota que 99% dos compradores falam inglês, portanto, as tarefas e a pesquisa permanecerão em inglês.',
        button: 'Traduz para o português'
      }
    },
    'pt-br': {
      flag: 'br',
      name: 'Português do Brasil',
      popup: {
        title: 'Você gostaria de traduzir a AnyTask.com para Português do Brasil?',
        content: 'Observe que 99% dos Clientes são falantes do inglês, portanto, Tarefas e pesquisas permanecerão em inglês.',
        button: 'Traduz para o Português do Brasil'
      }
    },
    'ro': {
      flag: 'ro',
      name: 'Română',
      popup: {
        title: 'Ai vrea să traducem AnyTask.com în Română?',
        content: 'Nu uita, 99% din cumpărătorii noștri sunt vorbitori de limbă engleză, așa că serviciile și căutările vor rămâne în limba engleză.',
        button: 'Traduceți în română'
      }
    },
    'ru': {
      flag: 'ru',
      name: 'Русский',
      popup: {
        title: 'Хотите ли вы, чтобы мы перевели AnyTask.com на Русский? ',
        content: 'Обратите внимание, что 99% покупателей говорят по-английски, поэтому задачи и поиск останутся на английском языке.',
        button: 'Traduceți în rusă'
      }
    },
    'zh': {
      flag: 'cn',
      name: '中文 (中国)',
      popup: {
        title: '您希望我们能将 AnyTask.com 翻译成 中文 (中国) 吗？',
        content: '请注意，99%的买家使用英语，所以任务和搜索都会使用英语。',
        button: '翻译成简体中文'
      }
    },
    'lk': {
      flag: 'lk',
      name: 'Sinhalese',
      popup: {
        title: 'AnyTask.com අපි සිංහල භාෂාවට පරිවර්තනය කිරීමට ඔබට අවශ්‍යද?',
        content: 'ගැනුම්කරුවන්ගෙන් 99% ක් පමණ ප්‍රතිශතයක් ඉංග්‍රීසි භාෂාව භාවිතා කරන්නන් බැවින්, සියලු කාර්යයන් සහ සෙවුම් යන්ත්‍රය ඉංග්‍රීසි භාෂාවෙන් පවතින බව කරුණාවෙන් සලකන්න.',
        button: ''
      }
    },
    'th': {
      flag: 'th',
      name: 'ไทย',
      popup: {
        title: 'คุณอยากให้เราแปลภาษา AnyTask.com เป็น ไทย หรื',
        content: 'หรือไม่ โปรดทราบว่าร้อยละ 99 ของผู้ซื้อเป็นผู้ใช้ภาษาอังกฤษในการสนทนา ดังนั้นงานและการค้นหาจะยังคงไว้เป็นภาษาอังกฤษ',
        button: 'แปลเป็นภาษาไทย'
      }
    },
    'tr': {
      flag: 'tr',
      name: 'Türkçe',
      popup: {
        title: '\tAnyTask.com\'ı Türkçe diline çevirmemizi ister misin?',
        content: 'Lütfen unutma, alıcıların %99\'u İngilizce konuşmaktadır, bu nedenle hizmetler ve arama işlemi İngilizce olarak kalacaktır.',
        button: 'Türkçeye çevir'
      }
    },
    'ur': {
      flag: 'pk',
      name: 'اردو',
      popup: {
        title: 'کیا آپ چاہے گے کہ ہم AnyTask.com کو آپ کیلئے اردو میں ترجمہ کریں؟',
        content: ' اد رکھیں کہ 99 فیصد خریدار انگریزی بولنے والے ہوتے ہیں، اس لئے ٹاسک اور سرچ انگریزی میں ہی رہینگے۔',
        button: 'اردو میں ترجمہ کریں'
      }
    },
    'vn': {
      flag: 'vn',
      name: 'Tiếng Việt',
      popup: {
        title: 'Bạn có muốn chúng tôi dịch AnyTask.com sang Tiếng Việt không?',
        content: 'Xin lưu ý rằng 99% người mua đều nói Tiếng Anh, vì vậy các công việc và tìm kiếm vẫn bằng Tiếng Anh.',
        button: 'Dịch sang tiếng việt nam'
      }
    }
  }
}
