<template>
  <div class="outer-card" >
    <a @click.prevent="setCategoryFilter({name: title, slug: slug, nameEnglish: titleEnglish}), setSearchOverlay(true)" :href="route" >
      <div v-lazy-container="{ selector: 'img' }">
        <img style="position: relative" :alt="title" class="card-img" :data-src="image" :width="imgWidth" :height="imgHeight">
        <div class="card-title">
          <h4 class="text-left">{{title}}</h4>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CategoryCard',
  props: {
    route: String,
    imgWidth: Number,
    imgHeight: Number,
    image: String,
    title: String,
    titleEnglish: String,
    slug: String
  },
  methods: {
    ...mapActions([
      'setCategoryFilter',
      'setSearchOverlay'
    ])
  }
}
</script>

<style lang="scss" scoped>

.card-title {
  color: #fff;
  z-index: 5;
  position: absolute;
  text-align: left;
  text-decoration: none;
  top:0;
  left: 0;
  padding: 20px 20px !important;
}

a:hover{
  text-decoration: none;
}

.card-img {
  object-fit: cover;
  opacity: 0.9;
}
.outer-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  width: 170px;
}
.outer-card:after {
  content: '';
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 80%) !important;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  pointer-events: none;
  transition: all .2s;
}
.outer-card:hover:after {
  background: rgba(0, 0, 0, 0.7);
}

@include xl-up {
 .outer-card {
   transition: all .2s ease-in-out;
   &:hover {
     scale: 104%;
   }
 }
}
</style>
