export default {
  state: {
    searchOverlayOpen: false,
    filtersOpen: false,
    activeFilters: [],
    categoryFilter: null,
    categoryBarWidth: null
  },
  getters: {
    getCategoryFilter: (state) => () => {
      return state.categoryFilter
    }
  },
  mutations: {
    addActiveFilter: (state, data) => {
      !state.activeFilters.includes(data) && state.activeFilters.push(data)
    },
    removeActiveFilter: (state, data) => {
      state.activeFilters.splice(state.activeFilters.indexOf(data), 1)
    },
    clearActiveFilters: (state, data) => {
      state.activeFilters = []
    },
    setSearchOverlay: (state, data) => {
      if (!state.searchOverlayOpen && state.searchOverlayOpen !== data) {
        window.history.pushState(null, '')
      }
      state.searchOverlayOpen = data
    },
    setFiltersOpen: (state, data) => {
      window.scrollTo(0, 0)
      state.filtersOpen = data
    },
    setCategoryFilter: (state, data) => {
      state.categoryFilter = data
      sessionStorage.setItem('categoryFilter', JSON.stringify(state.categoryFilter))
    },
    setCategoryBarWidth: (state, data) => {
      state.categoryBarWidth = data
    }
  },
  actions: {
    setSearchOverlay ({ commit }, data) {
      commit('setSearchOverlay', data)
    },
    removeActiveFilter ({ commit }, data) {
      commit('removeActiveFilter', data)
    },
    addActiveFilter ({ commit }, data) {
      commit('addActiveFilter', data)
    },
    clearActiveFilters ({ commit }, data) {
      commit('clearActiveFilters', data)
    },
    setFiltersOpen ({ commit }, data) {
      commit('setFiltersOpen', data)
    },
    setCategoryFilter ({ commit }, data) {
      commit('setCategoryFilter', data)
    },
    setCategoryBarWidth ({ commit }, data) {
      commit('setCategoryBarWidth', data)
    }
  }
}
