import axios from 'axios'
import https from 'https'
import config from './config'
import store from './store'
import router from './router'
import { cacheAdapterEnhancer } from 'axios-extensions'

const HTTP = axios.create({
  baseURL: config.api_url,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
})

let locale = navigator.languages.toString()

if (localStorage.getItem('language')) {
  locale = localStorage.getItem('language') + ',' + navigator.languages.filter(e => e !== localStorage.getItem('language'))
} else {
  locale = 'en,' + navigator.languages.filter(e => e !== 'en')
}

const HTTPIgnoreNetworkErrors = axios.create({
  baseURL: config.api_url,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
})

const HTTPcached = axios.create(
  {
    baseURL: config.api_url,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    }),
    headers: { 'Cache-Control': 'no-cache', 'Accept-Language': locale },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter)
  })

const HTTPcsv = axios.create(
  { baseURL: config.api_url,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    }),
    responseType: 'blob'
  }
)

let token = null
if (localStorage.getItem('accessToken')) {
  token = 'Bearer ' + localStorage.getItem('accessToken')
}
let tokenRefreshing = false

function catchErrors (error, networkErrorCheck = true) {
  if (!error.response && networkErrorCheck) {
    const uri = router.currentRoute
    if (uri.name !== '500') {
      router.push('/500').then(() => {
        history.replaceState({ urlPath: '/500' }, '', uri.path)
      })
    }
    return
  } else if (error.config && error.response && error.response.status === 401 && (error.response.config.url === '/login/refresh' || error.response.config.url === config.api_url + '/login/refresh')) {
    // Failed token refresh
    localStorage.setItem('loggedOut', 'true')
    store.dispatch('signOut')
    return Promise.reject(error)
  } else if (error.config && error.response && error.response.status === 401 && localStorage.getItem('refreshToken') !== null) {
    // Unauthorised but we have a refresh token
    if (error.config.headers['Authorization'] === token && tokenRefreshing === false) {
      tokenRefreshing = true
      store.dispatch('fetchNewAccessToken').then(() => {
        token = 'Bearer ' + localStorage.getItem('accessToken')
        tokenRefreshing = false
      })
    }
    error.config.headers['Authorization'] = token
    const routeName = router.currentRoute.name
    if (store.state.termsRequired && routeName !== 'privacy_policy' && routeName !== 'intellectual_property' && routeName !== 'terms_conditions' && routeName !== 'anytask_protection') {
      router.push('/')
      return
    }
    return HTTP(error.config)
  } else if (error.response.status === 301) {
    if (navigator.userAgent.match(/\sPrerender\s/i)) {
      const titleNode = document.getElementsByTagName('title')[0]
      let metaTag = document.createElement('meta')
      metaTag.name = 'prerender-status-code'
      metaTag.content = '301'
      titleNode.parentNode.insertBefore(metaTag, titleNode.nextSibling)

      metaTag = document.createElement('meta')
      metaTag.name = 'prerender-header'
      metaTag.content = 'Location: ' + config.frontend_url + error.response.data.error_message
      titleNode.parentNode.insertBefore(metaTag, titleNode.nextSibling)
    } else {
      router.push(error.response.data.error_message)
    }
    return
  } else if (error.response.status === 404 && error.response.config.method === 'get' && router.currentRoute.name !== 'meet_the_sellers_article') {
    // Route not found
    store.state.pageNotFound = true
    const uri = router.currentRoute
    router.push('/404')
    history.replaceState({ urlPath: '/404' }, '', uri.path)
    return
  } else if (error.response.status === 410 && error.response.config.method === 'get') {
    // Content deleted
    store.state.pageNotFound = true
    const uri = router.currentRoute
    router.push('/410')
    history.replaceState({ urlPath: '/410' }, '', uri.path)
    return
  } else if (error.response.status === 500) {
    const uri = router.currentRoute
    if (uri.name !== '500') {
      router.push('/500')
      history.replaceState({ urlPath: '/500' }, '', uri.path)
    }
    return
  } else if (error.response.status === 503) {
    // Maintenance enabled
    store.state.pageNotFound = true
    const uri = router.currentRoute
    router.push('/503')
    history.replaceState({ urlPath: '/503' }, '', uri.path)
    return
  } else if (localStorage.getItem('accessToken') && !localStorage.getItem('refreshToken')) {
    // Clean up any invalid token in the storage
    localStorage.setItem('loggedOut', 'true')
    store.dispatch('signOut')
    return Promise.reject(error)
  }

  return Promise.reject(error)
}

/**
 * Catch & handle common error responses
 */
HTTP.interceptors.request.use((config) => {
  if (localStorage.getItem('accessToken')) {
    token = 'Bearer ' + localStorage.getItem('accessToken')
  }
  return config
})
HTTPIgnoreNetworkErrors.interceptors.request.use((config) => {
  if (localStorage.getItem('accessToken')) {
    token = 'Bearer ' + localStorage.getItem('accessToken')
  }
  return config
})
HTTPcached.interceptors.request.use((config) => {
  if (localStorage.getItem('accessToken')) {
    token = 'Bearer ' + localStorage.getItem('accessToken')
  }
  return config
})
HTTPcsv.interceptors.request.use((config) => {
  if (localStorage.getItem('accessToken')) {
    token = 'Bearer ' + localStorage.getItem('accessToken')
  }
  return config
})

HTTP.interceptors.response.use((response) => {
  return response
}, (error) => {
  return catchErrors(error)
})
HTTPIgnoreNetworkErrors.interceptors.response.use((response) => {
  return response
}, (error) => {
  return catchErrors(error, false)
})
HTTPcached.interceptors.response.use((response) => {
  return response
}, (error) => {
  return catchErrors(error)
})
HTTPcsv.interceptors.response.use((response) => {
  return response
}, (error) => {
  return catchErrors(error)
})

export default {

  showError (e) {
    console.log(e)
  },

  /**
   * Do login check
   *
   * @param {Object} loginData  Username and Password
   */
  loginCheck (loginData) {
    return HTTP.post('/login/check', loginData, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      } })
  },

  /**
   * Do SSO login check
   */
  loginCheckSso (sso, sig) {
    return HTTP.get('/login/sso/check?sso=' + sso + '&sig=' + sig, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      } })
  },

  /**
   * Request a new token
   *
   * @param {String} refreshToken  The refresh token
   */
  loginRefresh (refreshToken) {
    return HTTP.post('/login/refresh', { refresh_token: refreshToken }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      } })
  },

  /**
   * Register a new user
   *
   * @param {Object} user  Username and Password
   */
  register (user) {
    return HTTP.post('/user', user, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      } })
  },
  confirmEmail () {
    return HTTP.head('/user/email/confirm', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  sendContactMessage (userHash, message, action) {
    const data = { ...{ 'message': message }, ...{ 'action': action.type, 'hash': action.hash } }

    return HTTP.post('/message/' + userHash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  /**
   * Returns all categories
   */
  getAllCategories (forceLocale = null) {
    if (forceLocale !== null) {
      return HTTPcached.get('categories?_locale=' + forceLocale, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Accept-Language': forceLocale
        }
      })
    }
    return HTTPcached.get('categories')
  },

  /**
   * Returns a category and it's children
   *
   * @param {String} slug  The category slug
   */
  getCategoryAndItsChildren (slug) {
    return HTTP.get('categories/' + slug, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      }
    })
  },

  /**
   * Returns tasks matching the given criteria
   *
   * @param {Number} perPage         The amount of tasks per page
   * @param {Number} currentPage     The current page
   * @param {Array}  terms           The search terms (exact match phrases encased in double quotes (“for example”))
   * @param {String} delivery        The maximum delivery time in hours
   * @param {String} priceFrom       The minimum price
   * @param {String} priceTo         The maximum price
   * @param {String} sellerVerified  The verification of a seller ('any', 'photo' 'id'). ('any' returns user that are verified by any method, empty string ignores this param)
   * @param {Array}  languages       The iso of seller languages
   * @param {Array}  categories      The slugs of the categories we want to return
   * @param {String} sort            Sort the response ('', 'date.desc', 'price.asc', 'price.desc', 'review.desc')
   */
  getTasks (perPage, currentPage, terms, delivery, priceFrom, priceTo, sellerVerified, languages, categories, sort) {
    let params = this.buildGetTaskParameters(perPage, currentPage, terms, delivery, priceFrom, priceTo, sellerVerified, languages, categories, sort)
    return HTTP.get('gigs' + params, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      }
    })
  },

  /**
   * Returns tasks matching the given criteria, expects the url parameters to already be encoded
   *
   * @param {String} params  The filter parameters
   * @param status
   */
  getTasksWithPrebuildParams (params, status = 1) {
    return HTTP.get('gigs' + params + '&status=' + status, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  /**
   * Return tasks matching the username
   *
   * @param {String} user  The user's username
   */
  getTasksByUser (user) {
    if (token !== null) {
      return HTTP.get('gigs/' + user, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
    } else {
      return HTTP.get('gigs/' + user, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Accept-Language': locale
        } })
    }
  },

  /**
   * Return a specific task by a specific user
   *
   * @param {String} user     The user's username
   * @param {String} taskSlug  The slug of the task
   */
  getTaskByUser (user, taskSlug) {
    if (token !== null) {
      return HTTP.get('gigs/' + user + '/' + taskSlug, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
    } else {
      return HTTP.get('gigs/' + user + '/' + taskSlug, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Accept-Language': locale
        } })
    }
  },

  getTaskByHash (hash) {
    return HTTP.get('gigs/edit/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  getTotals () {
    return HTTP.head('/user', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  /**
   * Return profile stats of a specific user
   *
   * @param {String} user     The user's username
   * @param {String} taskSlug  The slug of the task
   */
  getSellerStats (user, taskSlug) {
    if (token !== null) {
      return HTTP.get('gigs/' + user + '/' + taskSlug + '/stats', {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
    } else {
      return HTTP.get('gigs/' + user + '/' + taskSlug + '/stats', {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Accept-Language': locale
        } })
    }
  },

  /**
   * Return langauges that are enabled
   *
   * @param {String} sort  Sort the array (at time of writing only option is 'task_count.disc')
   */
  getAllLanguages (sort) {
    let params = ''

    if (sort) {
      params = ('?sort=' + sort)
    }
    return HTTPcached.get('languages' + params)
  },

  /**
   * Returns parameters in the correct format for getTasks
   *
   * @param {Number} perPage         The amount of tasks per page
   * @param {Number} currentPage     The current page
   * @param {Array}  terms           The search terms (exact match phrases encased in double quotes (“for example”))
   * @param {String} delivery        The maximum delivery time in hours
   * @param {String} rating          The min star rating filter
   * @param {String} priceFrom       The minimum price
   * @param {String} priceTo         The maximum price
   * @param {Array}  languages       The iso of seller languages
   * @param {Array}  categories      The slugs of the categories we want to return
   * @param {String} sort            Sort the response ('', 'date.desc', 'price.asc', 'price.desc', 'review.desc')
   * @param {Object}  advanceSearch   advanceSearch object
   */
  buildGetTaskParameters (perPage, currentPage, terms, delivery, priceFrom, priceTo, rating, languages, categories, sort, advanceSearch = {}) {
    let encodedCategories
    let encodedTerms
    let params = []

    params.push('per_page=' + (perPage === '' ? '24' : perPage))
    params.push('page=' + (currentPage === '' ? '1' : currentPage))

    if (terms.length !== 0) {
      encodedTerms = []
      terms.forEach(function (item) {
        if (item[0] === '"' && item[item.length - 1] === '"') {
          encodedTerms.push('%22' + item.replace(/"/g, '') + '%22')
        } else {
          encodedTerms.push(item)
        }
      })
      params.push('terms=' + encodedTerms.join('%20'))
    }
    if (delivery) {
      params.push('delivery=' + delivery)
    }
    if (priceFrom) {
      params.push('price_from=' + priceFrom)
    }
    if (priceTo) {
      params.push('price_to=' + priceTo)
    }
    if (rating) {
      params.push('rating=' + rating)
    }
    if (languages.length !== 0) {
      params.push('languages=' + languages.join(','))
    }
    if (categories.length !== 0) {
      encodedCategories = []
      categories.forEach(function (item) {
        encodedCategories.push(item.replace(/\//g, '%2F'))
      })
      params.push('categories=' + encodedCategories.join(','))
    }

    for (const [key, value] of Object.entries(advanceSearch)) {
      if (key === 'exact_words[]') {
        value.split(',').forEach(function (item) {
          params.push('exact_words[]=' + item)
        })
      } else {
        params.push(key + '=' + value)
      }
    }

    params.push('sort=' + (sort === '' ? 'date.desc' : sort))

    params = '?' + (params.join('&'))
    return params
  },

  /**
   * Return sso forward link
   */
  loginSso () {
    return HTTP.get('login/sso', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      }
    })
  },

  /**
   * Return task favourites
   */
  favouriteTasks () {
    return HTTP.get('gigs/favourite', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  /**
   * Adds task from favourites
   * @param {string} hash The task hash
   */
  addFavouriteTask (hash) {
    return HTTP.put('gigs/favourite/' + hash, '', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  /**
   * Remove task from favourites
   * @param {string} hash The task hash
   */
  removeFavouriteTask (hash) {
    return HTTP.delete('gigs/favourite/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  shoppingBasketPost (taskHash, taskPackage, quantity = 1) {
    if (token !== null) {
      return HTTP.post('shopping/basket', {
        'quantity': quantity,
        'gig': taskHash,
        'gig_package': taskPackage
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      }
      )
    } else {
      return HTTP.post('shopping/basket', {
        'quantity': quantity,
        'gig': taskHash,
        'gig_package': taskPackage
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Accept-Language': locale
        } })
    }
  },
  shoppingBasketGet (basketHash = null) {
    let params = basketHash ? ('?hash=' + basketHash) : ''
    return HTTP.get('shopping/basket' + params, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  shoppingBasketPut (data, hash) {
    return HTTP.put('shopping/basket/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  shoppingBasketDelete (hash) {
    return HTTP.delete('shopping/basket/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getPaymentOptions () {
    return HTTP.get('payments', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  paymentSetting (hash) {
    return HTTP.get('payments/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      } })
  },
  paymentAuth (hash) {
    return HTTP.get('payments/' + hash + '/authentication', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    }
    )
  },
  paymentAuthPost (hash, data) {
    return HTTP.post('payments/' + hash + '/authentication', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    }
    )
  },
  paymentAuthUpdate (hash, data) {
    return HTTP.put('payments/' + hash + '/authentication', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    }
    )
  },
  paymentPurchase (hash, data) {
    return HTTP.post('payments/' + hash + '/purchase', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  paymentPolling (hash, data) {
    return HTTP.post('payments/' + hash + '/polling', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  orderNotes (hash, data) {
    return HTTP.post('/order/' + hash + '/note', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    }
    )
  },

  /**
   * Post a new task
   * @param {string} data The task to be created
   */
  createTask (data) {
    return HTTP.post('gigs', JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  createCustomTask (hash, data) {
    return HTTP.post('/gigs/custom-quote/' + hash, JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getCustomTaskCheck (hash) {
    return HTTP.get('/gigs/custom-quote/' + hash + '/check',
      {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
  },
  gigActivation (slug, data) {
    return HTTP.patch('gigs/' + slug, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  deleteTask (taskHash) {
    return HTTP.delete('gigs/' + taskHash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  editTask (taskSlug, data, username) {
    return HTTP.patch('gigs/' + username + '/' + taskSlug, JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  editTaskHash (hash, data) {
    return HTTP.patch('gigs/' + hash, JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getOrders (page = 1, invoiceType, perPage = 6, hash = null) {
    let userHash = ''

    if (hash !== null) {
      userHash = '&hash=' + hash
    }

    return HTTP.get('/order?per_page=' + perPage + '&page=' + page + '&invoice_type=' + invoiceType + userHash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  createOrdersFilters (data) {
    let filterData = ''

    if (data.fromDate) {
      filterData = filterData + '&from_date=' + data.fromDate
    }

    if (data.toDate) {
      filterData = filterData + '&to_date=' + data.toDate
    }

    if (data.fromDateCompleted) {
      filterData = filterData + '&completion_from_date=' + data.fromDateCompleted
    }

    if (data.toDateCompleted) {
      filterData = filterData + '&completion_to_date=' + data.toDateCompleted
    }

    if (data.status !== undefined) {
      filterData = filterData + '&order_status=' + data.status
    }

    if (data.terms) {
      filterData = filterData + '&terms=' + data.terms
    }

    if (data.paymentType) {
      filterData = filterData + '&payment_type=' + data.paymentType
    }

    if (data.giftCardSwitch) {
      filterData = filterData + '&tmp_gift_code_switch=' + data.giftCardSwitch
    }

    if (data.campaign) {
      filterData = filterData + '&campaign=' + data.campaign
    }

    if (data.user_ip) {
      filterData = filterData + '&user_ip=' + data.user_ip
    }

    if (data.duplicates) {
      filterData = filterData + '&view_duplicates=' + data.duplicates
    }

    return filterData
  },

  getAllOrders (page = 1, data, perPage = 6, searchAll = true) {
    return HTTP.get('/order?per_page=' + perPage + '&page=' + page + '&search_all=' + searchAll + this.createOrdersFilters(data),
      {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
  },
  exportOrders (data) {
    let createFilter = this.createOrdersFilters(data)

    if (createFilter.length !== 0) {
      createFilter = '?' + createFilter.slice(1)
    }

    return HTTPcsv.get('/order/export' + createFilter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
  },
  getOrder (hash) {
    return HTTP.get('/order/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getOrdersByStatus (page = 1, invoiceType, status, perPage = 6, hash = null) {
    let userHash = ''
    if (hash !== null) {
      userHash = '&hash=' + hash
    }

    return HTTP.get('/order?per_page=' + perPage + '&page=' + page + '&invoice_type=' + invoiceType + '&order_status=' + status + userHash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postOrders (hash, data) {
    return HTTP.post('/order/' + hash + '/status', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postOrderRequirement (terms, hash, message, assets) {
    let formData = {
      'agree_terms': terms,
      'message': message,
      'assets': assets
    }
    return HTTP.post('/order/' + hash + '/requirement', formData, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  getRequirements (hash) {
    return HTTP.get('/order/' + hash + '/requirement', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getRequirementAttachment (hash, attachmentHash) {
    return HTTP.get('/order/' + hash + '/requirement/' + attachmentHash + '/download', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getOrderTimeline (hash) {
    return HTTP.get('/order/timeline/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getOrderEtnPayment (hash) {
    return HTTP.get('/order/' + hash + '/payment', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  /**
   * Patch a user profile
   *
   * @param {Object} data  The user data to be updated
   * @param withOutToken
   */
  patchUser (data, withOutToken = false) {
    const url = withOutToken ? 'user?token=0' : 'user'
    return HTTP.patch(url, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getUser () {
    return HTTP.get('user', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  updateUserPhoto (photo) {
    return HTTP.post('user/photo', { 'photo': photo }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  report (reportType, data) {
    return HTTP.post('report/' + reportType, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  getConversionRate () {
    return HTTPIgnoreNetworkErrors.get('/exchange', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      } })
  },
  getInbox (page, perPage, sortBy, type = null, typeSearch = null) {
    let params = ['page=' + page, 'per_page=' + perPage]

    if (sortBy) {
      params.push('sort=' + sortBy)
    }

    if (type) {
      params.push('type=' + type)
    }

    if (typeSearch) {
      params.push('type_search=' + typeSearch)
    }

    return HTTP.get('/message/threads?' + params.join('&'), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getThreadInfo (hash) {
    return HTTP.get('/message/thread/' + hash + '/info', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getDirectMessages (username) {
    return HTTP.get('/message/direct/' + username, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getDirectMessagesNext (username, next) {
    return HTTP.get(`/message/direct/${username}?next=${next}`, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getMessages (hash) {
    return HTTP.get('/message/thread/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getMessage (threadHash, threadMessageHash) {
    return HTTP.get('/message/thread/' + threadHash + '/' + threadMessageHash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getUnreadMessages () {
    return HTTPIgnoreNetworkErrors.get('message/unread', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postMessage (hash, message, file) {
    let formData = {
      'files': file,
      'message': message
    }
    return HTTP.post('/message/thread/' + hash, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getMessageAttachment (hash) {
    return HTTP.get('/message/attachment/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getTasksAdmin (perPage, page, status, terms, orderCount = 0, shadowBan = 1, weighting = false, anytaskheros = false) {
    let orderCountUrl = ''
    if (orderCount) {
      orderCountUrl = 'order_count=true&'
    }

    let weightingUrl = ''
    if (weighting) {
      weightingUrl = 'weighting=true&'
    }

    let anytaskHeroUrl = ''
    if (anytaskheros) {
      anytaskHeroUrl = 'hero=true&'
    }

    return HTTP.get('/gigs?' + orderCountUrl + weightingUrl + anytaskHeroUrl + 'per_page=' + perPage + '&page=' + page + '&sort=date.asc' + '&status=' + status + '&terms=' + terms + '&shadow_ban=' + shadowBan + '&filter_away=0', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  confirmToken (confirmationToken) {
    return HTTP.patch('token/' + confirmationToken + '/accept', '', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  uploadGiftCards (data) {
    return HTTP.post('gift-card/import', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  downloadGiftCards () {
    return HTTP.get('gift-card/export', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  submitReview (hash, data) {
    return HTTP.post('order/' + hash + '/review', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getDeliveryAttachment (hash) {
    return HTTP.get('order/asset/' + hash + '/download', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  groupOrders (hash) {
    return HTTP.get('order/' + hash + '/assets/publish', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getSavedAssets (hash) {
    return HTTP.get('order/' + hash + '/assets/pending', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  submitRevision (hash, data) {
    return HTTP.post('order/' + hash + '/revision', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  deleteSavedDeliveryAsset (hash) {
    return HTTP.delete('order/asset/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },

  disputeCreate (hash, data) {
    return HTTP.post('dispute/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  // Admin
  adminApproveTask (hash, data) {
    return HTTP.patch('gigs/edit/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  adminUserSearch (perPage, page, data) {
    const paramsData = { ...{
      'per_page': perPage,
      'page': page
    },
    ...data }

    return HTTP.get('user/search', {
      params: paramsData,
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getUserAdmin (hash) {
    return HTTP.get('user?hash=' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getRolesAdmin () {
    return HTTP.get('role', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postRoles (hash, data) {
    return HTTP.patch('user/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getDispute (action, hash) {
    return HTTP.get('dispute/' + action + '/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  adminDisputesSearch (action, perPage, page, sort, terms, evidence) {
    return HTTP.get('dispute/' + action + '?per_page=' + perPage + '&page=' + page + '&terms=' + terms + '&sort=' + sort + '&evidence=' + evidence, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  disputePatch (hash, data) {
    return HTTP.patch('dispute/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  resetPassword (data) {
    return HTTP.post('user/forgot-password', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      }
    })
  },
  sendResetPasswordEmail (email) {
    return HTTP.get('user/forgot-password/' + email, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': locale
      }
    })
  },
  getTransactionHistory (type, page) {
    return HTTP.get('user/transactions/' + type + '?page=' + page, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getTaskNeedingApproval () {
    return HTTP.get('gigs/pending', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getTaskNeedingApprovalWithHash (hash) {
    return HTTP.get('gigs/pending/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getRewards (perPage, page) {
    return HTTP.get('reward/' + '?per_page=' + perPage + '&page=' + page, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getRewardAllData (perPage, page, hash, paid = 0) {
    return HTTP.get('reward/' + hash + '/data' + '?per_page=' + perPage + '&page=' + page + '&paid=' + paid, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  submitReward (hash, dataHash, data) {
    return HTTP.patch('reward/' + hash + '/data/' + dataHash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getBillingDetails () {
    return HTTP.get('user/billing', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postBillingDetails (data) {
    return HTTP.post('user/billing', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  putBillingDetails (data) {
    return HTTP.put('user/billing', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getInvoices (hash) {
    return HTTPcached.get('order/' + hash + '/invoices', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  downloadInvoices (hash, type, format, refunded) {
    return HTTP.get('order/' + hash + '/invoices/' + type + '/' + format + ((refunded) ? '?refunded=1' : ''), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getSettings () {
    return HTTP.get('settings', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getTranslation (data, locale) {
    const payload = {
      'input': data,
      'target': locale
    }

    return HTTPcached.post('translate', payload, { headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'Authorization': token,
      'Accept-Language': locale }
    })
  },
  getFeaturedTasks () {
    return HTTPcached.get('featured/task', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postFeaturedTask (items) {
    return HTTP.post('featured/task', { 'items': items }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  deleteFeaturedTask (taskHash) {
    return HTTP.delete('featured/task/' + taskHash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  patchFeaturedTask (items) {
    return HTTP.patch('featured/task', { 'items': items }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  closeAccount (hash, reason) {
    return HTTP.delete('user/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token
      },
      data: {
        'reason': reason
      }
    })
  },
  getReports (type, perPage, page, action = null) {
    let status = ''

    if (action) {
      status = '&status=' + action
    }

    return HTTP.get('report' + '?per_page=' + perPage + '&page=' + page + '&type=' + type + status, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  submitReportSolution (hash, data) {
    return HTTP.patch('report/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getUserBalance () {
    return HTTP.get('user/balance', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  checkGiftCard (code) {
    return HTTP.post('gift-card/check', { 'code': code }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  createUploadPolicyToken (type, hash = null) {
    let formData = ''
    if (hash !== null) {
      formData = new FormData()
      formData.append('hash', hash)
    }
    return HTTP.post('token/upload-policy/' + type, formData, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  saveDeliveryAssets (hash, data) {
    return HTTP.post('order/' + hash + '/assets', {
      'assets': data
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  addToGallery (data, userHash) {
    let userHashQuery = ''
    if (userHash !== null) {
      userHashQuery = '?user=' + userHash
    }
    return HTTP.post('gallery/task' + userHashQuery, {
      'asset': data
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getTokenUrl (type) {
    return config.api_url + '/token/upload-policy/' + type
  },
  editReview (reviewHash, data) {
    return HTTP.patch('review/' + reviewHash, JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getReview (orderHash) {
    return HTTP.get('review/' + orderHash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getUsernameSuggestions () {
    return HTTP.get('user/suggested/username', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getDelayedPaymentsAdmin () {
    return HTTP.get('order/payments?stats=true', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getDelayedPaymentsProcessedAdmin (page = 1, perPage = 25) {
    return HTTP.get('order/payments/processed?page=' + page + '&per_page=' + perPage, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  approveDeclinePayment (hash, data) {
    return HTTP.post('order/' + hash + '/payment', JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  shadowBanUser (hash, value) {
    let data = {
      'shadow_ban': !value ? 1 : 0
    }
    return HTTP.patch('user/shadow/ban/' + hash, JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  approveDeclineCustomQuote (hash, data) {
    return HTTP.patch('gigs/custom-quote/' + hash + '/action', JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  customQuoteEdit (hash, data) {
    return HTTP.patch('gigs/custom-quote/' + hash, JSON.stringify(data), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getNotifications () {
    return HTTP.get('notifications', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getPreSellerRole () {
    return HTTP.get('user/add/pre/seller', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getUserBands () {
    return HTTP.get('user/verified', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getQrCodeData () {
    return HTTP.get('user/qr/sign-up', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  adminPatchUser (hash, data) {
    return HTTP.patch('user/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  updateUserBalance (userHash, hash, amount, expires) {
    const date = new Date(expires)
    return HTTP.patch(
      '/user/' + userHash + '/' + hash + '/balance',
      JSON.stringify({
        'amount': amount,
        'date_expired': {
          'date': {
            'year': date.getFullYear(),
            'month': date.getMonth() + 1,
            'day': date.getDate()
          },
          'time': {
            'hour': date.getHours(),
            'minute': date.getMinutes()
          }
        }
      }),
      {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      }
    )
  },
  logOutOfDevices () {
    return HTTP.delete('user/remove/devices', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getReviewsAdmin (perPage, page, terms, sort) {
    return HTTP.get('review?per_page=' + perPage + '&page=' + page + '&terms=' + terms + '&sort=' + sort, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  adminDeleteReview (hash, reason) {
    return HTTP.delete('review/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      },
      data: {
        reason: reason
      }
    })
  },
  getInboxAdmin (page, perPage, hash) {
    return HTTP.get('/message/threads?per_page=' + perPage + '&page=' + page + '&hash=' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  submitUserTracker (type, value = '') {
    return HTTP.get('user/track/' + type + '/' + value, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  adminGetFeedback (filter) {
    return HTTP.get('/feedback?' + filter, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postFeedback (type, data) {
    return HTTP.post('/feedback/' + type, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getSearchTotal (params) {
    return HTTP.head('gigs' + params + '&status=1', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getLastViewed (perPage = 4) {
    return HTTP.get('/gigs/last-viewed?per_page=' + perPage, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getSuggested (perPage = 4) {
    return HTTP.get('/user/suggested/tasks?per_page=' + perPage, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  exportPayments (type, data) {
    let filter = ''

    if (data.length) {
      filter = '?' + data.join('&')
    }

    return HTTPcsv.get('/payments/' + type + '/export' + filter,
      {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
  },
  getUserEtnPayment (hash, page, perPage) {
    return HTTP.get('/user/' + hash + '/payments?page=' + page + '&per_page=' + perPage,
      {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': token,
          'Accept-Language': locale
        }
      })
  },
  getWidgets () {
    return HTTP.get('/user/widgets', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  removeWidget (name) {
    return HTTP.delete('/user/widgets/' + name, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  updateWidgets (names) {
    return HTTP.post('/user/widgets', names, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getTotalOrdersByStatus (type) {
    return HTTP.get('/order/stats/status?type=' + type, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getJobListing (page, perPage, order = null) {
    let parameters = '?page=' + page + '&per_page=' + perPage
    if (order) {
      parameters += '&order=' + order
    }

    return HTTP.get('/jobs' + parameters, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getJob (hash, isOwner = false) {
    let parameters = ''
    if (isOwner) {
      parameters = '?owner=1'
    }

    return HTTP.get('/jobs/' + hash + parameters, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  offerJob (hash, data) {
    return HTTP.post('/jobs/' + hash + '/offer', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  cancelOffer (hash, offerHash) {
    return HTTP.delete('/jobs/' + hash + '/offer/' + offerHash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  purchaseJobOffer (jobHash, offerHash) {
    return HTTP.get('/jobs/' + jobHash + '/offer/' + offerHash + '/accept', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  createJob (data) {
    return HTTP.post('/jobs', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  editJob (hash, data) {
    return HTTP.patch('/jobs/' + hash, data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  actionRequestReviewJob (hash) {
    return HTTP.get('/jobs/' + hash + '/request-review', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getJobNotes (hash) {
    return HTTP.get('/jobs/' + hash + '/notes', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getOwnJobs (status, page) {
    return HTTP.get('/jobs/owner?status=' + status + '&page=' + page, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getOffers (page) {
    return HTTP.get('/jobs/owner/offer?page=' + page, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  removeJob (hash) {
    return HTTP.delete('/jobs/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  repostJob (hash) {
    return HTTP.get('/jobs/' + hash + '/repost', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getAdminJobListing (data, page = 1) {
    const searchParams = new URLSearchParams(data)
    searchParams.append('page', page.toString())

    return HTTP.get('/jobs?' + searchParams.toString(), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getAdminJobMonitor (data, page = 1) {
    const searchParams = new URLSearchParams(data)
    searchParams.append('page', page.toString())

    return HTTP.get('/jobs/monitor?' + searchParams.toString(), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getAdminJob (hash) {
    return HTTP.get('/jobs/' + hash + '?review=1', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postJobResponseReview (hash, data) {
    return HTTP.post('/jobs/' + hash + '/response-review', data, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getJobOffers (hash) {
    return HTTP.get('/jobs/' + hash + '/offers', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getAdminJobUser (username, page) {
    return HTTP.get('/jobs/owner?username=' + username + '&page=' + page, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getAdminWatchedList (data, page) {
    const searchParams = new URLSearchParams(data)
    searchParams.append('page', page.toString())

    return HTTP.get('/watched?' + searchParams.toString(), {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postAdminWatchedList (hash, reason) {
    return HTTP.post('/watched', {
      user: hash,
      reason: reason
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  patchAdminWatchedList (hash) {
    return HTTP.patch('/watched/' + hash, {}, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  getAdminWatchedListNotes (hash) {
    return HTTP.get('/watched/' + hash + '/notes', {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  postAdminWatchedListNote (hash, message) {
    return HTTP.post('/watched/' + hash + '/notes', { 'message': message }, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  },
  removeAdminWatchedList (hash) {
    return HTTP.delete('/watched/' + hash, {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': token,
        'Accept-Language': locale
      }
    })
  }
}
