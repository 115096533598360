<template>
  <div>
    <div @click="mobileCurrency = true" class="d-flex align-items-center">
      <i class="fa-solid fa-globe fa-lg mr-2"></i>
      <span class="text-secondary">{{ getCurrency() }}</span>
    </div>
    <div v-if="mobileCurrency" id="currency-selector-mobile" class="position-absolute">
      <span class="position-absolute close font-weight-normal" @click="mobileCurrency = false">&times;</span>
      <div class="title d-flex flex-row align-items-center">
        <span class="mx-auto font-weight-bold">{{ $t('select_currency') }}</span>
      </div>
      <currency-selector @updated="mobileCurrency = false" class="wrapper mx-auto px-4 pt-4 pb-2"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import CurrencySelector from '../Navbar/CurrencySelector'

export default {
  name: 'CurrencyButton',
  data () {
    return {
      mobileCurrency: false
    }
  },
  components: { CurrencySelector },
  computed: {
    ...mapGetters(['getCurrency'])
  }
}
</script>

<style lang="scss" scoped>
#currency-selector-mobile {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8f9fa;
  .title {
    padding: 23px 0 22px;
  }
  .close {
    top: 16px;
    right: 20px;
    font-size: 36px;
  }
  .wrapper {
    height: 87vh;
    overflow: scroll;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    width: 97%;
    h6 {
      font-size: 1.35rem;
      line-height: 24px;
    }
  }
  .currency-warning {
    font-size: 80%;
  }
}
</style>
