<template>
    <b-container fluid class="profile">
      <b-alert show variant="warning" class="mt-4 mb-4 w-100" v-if="user.username !== username && away">
        {{awayText}}
      </b-alert>
      <!-- Profile -->
        <b-row v-if="!fetchingProfile">
          <h1 class="text-center font-weight-bold my-3 px-3 p-sm-0 py-0 py-md-3 w-100">{{ $t('users_tasks', { username: user.username }) }}</h1>
        </b-row>
        <div class="row" v-if="!fetchingProfile">
            <!-- User Info -->
            <div class="col-sm-12 col-md-5 col-lg-3">
                <!-- User Profile -->
                <Profile class="mb-3" :username="user.username" :verify="user.verification_type" :rating="user.review_average" :reviews="user.review_count"
                         :shortDescription="shortDescription" :buttons="true" :image_path="user.photo_path" :admin="admin" :hash="user.hash" popover_id="profile"
                         :online="user.last_active ? isUserOnline(user.last_active) : false" :tasks="hasTasks" :last-active="user.last_active"/>
                <!-- Location + Join Date -->
                <InfoBoxSimple class="mb-3 profile-description" type="text"
                               :location="user.country_iso" :join="($t('month.' + (joinedDate.getMonth() + 1))) + ' ' + joinedDate.getFullYear()" />
                <!-- Languages -->
                <InfoBoxSimple v-if="user.languages.length > 0" class="mb-3 profile-languages" :heading="$t('user_language')" type="list" :content="user.languages"/>
            </div>

            <!-- User Tasks + Reviews -->
            <div class="col-sm-12 col-md-7 col-lg-9 ">
                <!-- Tasks -->
                <!-- If Tasks > 0 -->
                <div class="row" v-if="tasks.length > 0">
                    <div class="cols-12 col-lg-4 col-md-6 col-sm-6 card-item px-sm-2 h-100 my-1 my-sm-0" v-for="(task, index) in tasks" :key="task.hash">
                        <!-- Shown tasks -->
                        <Card v-if="index < amountToShow"
                              class="mb-3 mx-auto" :carouselImage="task" :slug="task.slug" :username="user.username"
                              :isFeaturedTask="false"
                              :deliveryTime="task.packages[0].delivery"
                              :taskImage="formatMobileUrl(task.assets[0]['path'], task.assets[0]['type'])"
                              :user_rating="user.review_average" :user_count="user.review_count" :user_online="false"
                              :imageIcon="user.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
                              :price="task.packages[0].price" :hash="task.hash" :select="task.favourite" :title="task.title" :position="index + 1" :sellerInfo="user" :location="'profile-' + user.username"
                              :primaryCategorySlug="task.categories[0].slug" :isHeroTask="task.hero"/>
                        <Card v-else :class="hide"
                              class="mb-3 mx-auto" :carouselImage="task" :slug="task.slug" :username="user.username"
                              :isFeaturedTask="false"
                              :deliveryTime="task.packages[0].delivery"
                              :taskImage="task.assets[0]['path']"
                              :user_rating="user.review_average" :user_count="user.review_count" :user_online="false"
                              :imageIcon="user.photo_path" :description="task.title" :rating="task.review_average" :count="task.review_count"
                              :price="task.packages[0].price" :hash="task.hash" :select="task.favourite" :position="index + 1" :sellerInfo="user" :location="'profile-' + user.username"
                              :primaryCategorySlug="task.categories[0].slug" :isHeroTask="task.hero"/>
                    </div>
                </div>
                <!-- Else -->
                <div v-else class="row px-3 pt-3">
                    <div class="mx-auto no-tasks w-100 mb-3 text-left">
                        <h6>{{ $t('profile_user_has_no_tasks') }}</h6>
                    </div>
                </div>
                <!-- Show More Button -->
                <b-button variant="primary" v-if="tasks.length > amountToShow" id="toggle" class="mt-2 mb-5" v-on:click="toggle">{{ toggleText }}</b-button>
                <div ref="reviews">
                  <Reviews
                    :average-rating="user.review_average.toFixed(2)"
                    :review-count="user.review_count"
                    :reviews="user.reviews"
                    class="mb-4"
                    :reviews-title="$t('seller_reviews')"
                    v-if="user.reviews.length > 0"
                  />
                </div>
            </div>
        </div>

        <!-- Loading Spinner -->
        <div v-else class="pb-5">
            <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"></b-spinner>
        </div>
    </b-container>
</template>

<script>
import Profile from '../components/MyAccount/UserProfile.vue'
import InfoBoxSimple from '../components/Task/InfoBoxSimple'
import Card from '../components/Task/Card'
import Reviews from '../components/Task/Reviews'
import ApiService from '../apiService.js'
import helper from '../helper'
import { mapActions } from 'vuex'
export default {
  name: 'profile',
  data () {
    return {
      fetchingProfile: true,
      amountToShow: 6,
      hide: 'hide',
      toggleText: this.$t('button_text_show_more'),
      tasks: [],
      joinedDate: '',
      reviews: [],
      user: {},
      shortDescription: null,
      username: localStorage.getItem('username'),
      away: false,
      awayText: '',
      admin: false,
      hasTasks: false
    }
  },
  components: {
    Profile,
    Card,
    InfoBoxSimple,
    Reviews
  },
  metaInfo () {
    return {
      link: [
        {
          rel: 'canonical',
          href: this.user ? window.location.protocol + '//' + window.location.host + '/' + this.user.username : ''
        }
      ]
    }
  },
  mounted () {
    this.getTasksByUser()
    this.sellerProfileCheck()
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  methods: {
    ...mapActions([
      'parseJwt'
    ]),
    formatMobileUrl (filename, filetype) {
      return helper.formatMobileUrl(filename, filetype)
    },
    sellerProfileCheck () {
      this.admin = this.$store.getters.hasRole('ROLE_ADMIN')
    },
    toggle () {
      if (this.hide === 'hide') {
        this.hide = ''
        this.toggleText = this.$t('button_text_show_less')
      } else {
        this.hide = 'hide'
        this.toggleText = this.$t('button_text_show_more')
      }
    },
    getTasksByUser () {
      const data = this.$route.params.username
      this.fetchingProfile = true
      ApiService.getTasksByUser(data).then(response => {
        this.tasks = response.data.data.gigs
        this.hasTasks = this.tasks.length > 0
        this.user = response.data.data.user
        this.joinedDate = new Date(this.user.member_since)
        this.fetchingProfile = false
        this.shortDescription = this.user.summary
        let returnDate = this.user.returning_date !== null ? new Date(this.user.returning_date) : null
        let today = new Date()
        if (this.user.away && !returnDate) {
          this.away = true
          this.awayText = this.$t('away_notice.no_date')
        } else if (this.user.away && returnDate > today) {
          this.away = true
          this.awayText = this.$t('away_notice.date', { 'date': returnDate.toLocaleDateString() })
        }
      }).then(() => {
        if (this.$route.hash === '#reviews') {
          this.scrollToReviews()
        }
      }).catch(() => { })
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    },
    scrollToReviews () {
      window.scrollTo({ top: this.$refs.reviews.getBoundingClientRect().top + window.scrollY - 120, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped>

    @media (max-width: 768px) {
      profile, InfoBoxSimple {
          margin: unset;
      }
    }
    h2 {
      color: #0e0e0f;
      font-size: 20px;
      font-weight: bold;
      text-align: left;
    }

    .hide {
      display: none;
    }
    .no-tasks {
      color: lightslategray;
    }

</style>

<style>

    .profile .profile-description .card-body {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .profile .profile-description .card-body ul {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 8px !important;
    }
    .profile-description p:last-of-type, .profile-languages ul:last-of-type {
        margin-bottom: 0;
    }
    .profile-languages ul li:last-of-type {
        padding-bottom: 0 !important;
    }
</style>
