export default {
  state: {
    currencyRates: []
  },
  getters: {
    getCurrencyRates: (state) => () => {
      return state.currencyRates
    },
    getCurrencyRate: (state) => (currency) => {
      if (state.currencyRates[currency] === undefined) {
        return 0
      }
      return state.currencyRates[currency].rate
    },
    hasCurrency: (state) => (currency) => {
      return (state.currencyRates[currency] !== undefined)
    }
  },
  mutations: {
    setCurrencyRates: (state, data) => {
      state.currencyRates = data
    }
  },
  actions: {
    setCurrencyRates ({ commit }, data) {
      commit('setCurrencyRates', data)
    }
  }
}
