<template>
  <footer class="footer">
    <div class="container-fluid text-left pt-5 pb-3 d-flex flex-col align-items-center">
      <div class="row w-100 d-flex justify-content-center mb-4">
        <div v-if="categories.length > 0" class="mr-lg-3 col-12 col-sm-12 col-md-3 col-lg-2">
          <CollapsibleList :heading="$t('categories')" :list="categories" type="category" url="/category/"/>
        </div>
        <div class="mr-lg-3 col-12 col-sm-12 col-md-3 col-lg-2">
          <CollapsibleList :heading="$t('about')" :list="aboutLinks" url="/"/>
        </div>
        <div class="mr-lg-3 col-12 col-sm-12 col-md-3 col-lg-2">
          <CollapsibleList :heading="$t('support')" :list="supportLinks" url="/"/>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-auto">

          <h4 class="mb-0 pl-md-0 pl-2 mb-sm-0 text-left">{{ $t('social_media') }}</h4>
          <a target="_blank" class="ml-2 ml-md-0" :href="'https://www.facebook.com/' + social.facebook"><i class="fa-brands fa-2x fa-facebook" aria-hidden="true"></i></a>
          <a target="_blank" class="ml-2" :href="'https://twitter.com/' + social.twitter"><i class="fa-brands fa-2x fa-x-twitter" aria-hidden="true"></i></a>
          <a target="_blank" class="ml-2" :href="'https://www.instagram.com/' + social.instagram"><i class="fa-brands fa-2x fa-instagram" aria-hidden="true"></i></a>
          <a target="_blank" class="ml-2" :href="'https://www.linkedin.com/' + social.linkedin"><i class="fa-brands fa-2x fa-linkedin" aria-hidden="true"></i></a>
          <div class="d-inline d-lg-inline d-md-block ml-2 ml-md-0 mt-2">
            <a target="_blank" class="ml-0 ml-lg-2" :href="'https://www.youtube.com/' + social.youtube"><i class="fa-brands fa-2x fa-youtube" aria-hidden="true"></i></a>
            <a target="_blank" class="ml-2" :href="'https://www.pinterest.co.uk/' + social.pinterest"><i class="fa-brands fa-2x fa-pinterest" aria-hidden="true"></i></a>
          </div>
          <h4 class="mt-3 mb-0 pl-md-0 pl-2 mb-sm-0 text-left">Pay with</h4>
          <img v-lazy="require('@/assets/Payments/etn.svg')" class="card-payments ml-2 ml-md-0 mr-2" alt="ETN" title="ETN"/>
          <i title="MasterCard" class="fa-brands fa-2x mr-2 fa-cc-mastercard" aria-hidden="true"></i>
          <i title="Visa" class="fa-brands fa-2x mr-2 fa-cc-visa" aria-hidden="true"></i>
          <i title="AMEX" class="fa-brands fa-2x mr-2 fa-cc-amex" aria-hidden="true"></i>
          <i title="Bitcoin" class="fa-brands fa-2x mr-2 fa-bitcoin" aria-hidden="true"></i>
          <img v-lazy="require('@/assets/Crypto/xrp.svg')" class="card-payments" alt="XRP" title="XRP" style="width: 24px; margin-bottom: 1px"/>
          <img v-lazy="require('@/assets/Crypto/bnb_logo_grey.svg')" class="card-payments" alt="BNB" title="BNB" style="width: 24px; margin-bottom: 1px; margin-left: 7px;"/>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 text-center d-flex flex-column" :class="{ 'col-lg-5': categories.length === 0, 'col-lg-3': categories.length > 0 }">
        <div class="mb-1">
          <img alt="AnyTask" v-lazy="require('@/assets/AnyTask.svg')" class="logo mt-4 mb-1 my-md-0" width="150" height="56">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CollapsibleList from '../../components/Global/Footer/CollapsibleList.vue'
import config from '../../config'

export default {
  name: 'Footer',
  components: { CollapsibleList },
  props: {
    categories: Array
  },
  data () {
    return {
      anyTasksLegal: config.anytasks_legal,
      social: config.social,
      electroneumUrl: 'https://electroneum.com',
      supportLinks: [
        {
          name: this.$t('become_seller_button_join'),
          slug: 'become-seller'
        },
        {
          name: this.$t('what_is_anytask_link'),
          slug: 'what-is-anytask'
        },
        {
          name: this.$t('help_support'),
          link: 'https://support.anytask.com'
        },
        {
          name: this.$t('toolkit_link'),
          slug: 'toolkit'
        },
        {
          name: this.$t('how_payment_work'),
          slug: 'how-payments-work'
        },
        {
          name: this.$t('writing_your_brief.title'),
          slug: 'writing-your-brief'
        }
      ],
      aboutLinks: [
        {
          name: this.$t('meet_the_sellers_link'),
          slug: 'meet-the-sellers'
        },
        {
          name: this.$t('news_link'),
          link: 'https://news.anytask.com'
        },
        {
          name: this.$t('intellectual_property_claims'),
          slug: 'intellectual-property'
        },
        {
          name: this.$t('privacy_policy'),
          slug: 'privacy-policy'
        },
        {
          name: this.$t('terms_of_service'),
          slug: 'terms-and-conditions'
        },
        {
          name: this.$t('anytask_protection'),
          slug: 'anytask-protection'
        },
        {
          name: this.$t('sitemap'),
          slug: 'sitemap'
        }
      ]
    }
  }
}
</script>

<style scoped>
.card-payments {
  width: 25px;
  height: 26px;
  vertical-align: bottom;
}

.footer {
  background-color: #fafafa;
  border-top: 1px solid #e5e5e5;
  color: #777 !important;
  bottom: 0;
}

.footer .s-m-links .fa:hover {
  color: #44aae1;
}

.footer .anytask-footer {
  flex: 1;
}

.footer .powered-by-logo img {
  width: 140px;
}

.footer .powered-by-logo p {
  position: absolute;
  right: 0;
  top: -14px;
}

.footer .logo {
  width: 150px;
}

.legal {
  font-size: 11px;
  line-height: 120%;
}

.footer .powered-by-logo p {
  color: #999;
  font-size: 11px;
  letter-spacing: 0;
}

a {
  color: #777 !important;
}

h4 {
  font-size: 15px;
  color: #555;
  font-weight: bold;
  padding-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  float: left;
  margin-right: 10px;
}
</style>
