<template>
  <div id="app" v-if="categoriesFetched && $store.getters.isSettingLoaded()">
    <ais-instant-search :index-name="config.advanced_search.index_name" :search-client="getSearchClient()" :routing="routing" :insights="true">
      <CookieBanner />
      <ais-configure clickAnalytics="true" />
      <div class="sticky-top">
       <Header v-if="useFullTemplate" :categories="categories" id="NavBar" class="sticky-top"/>
      </div>
      <b-toast id="message-toast" :title="$t('message')" header-class="secondary text-white" to="/my-account/inbox"
               :auto-hide-delay="30000" static :solid=true @click="viewMessages" variant="secondary"
               style="z-index:999;">
        {{ $t('new_message') }}
      </b-toast>
      <AdvancedSearch :class="!searchOverlayOpen && 'd-none'" class="search-overlay"/>
      <router-view id="content" :class="searchOverlayOpen && 'd-none'"/>
      <Footer v-if="useFullTemplate" :categories="categories" />

    </ais-instant-search>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Header from './views/Layout/HeaderItem'
import Footer from './views/Layout/FooterItem'
import apiService from './apiService.js'
import config from './config'
import algoliaSearch from 'algoliasearch'
import { history as historyRouter } from 'instantsearch.js/es/lib/routers'
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings'
import 'instantsearch.css/themes/satellite.css'
import AdvancedSearch from './components/AdvancedSearch/AdvancedSearch.vue'
import CookieBanner from './components/Global/CookieBanner'

export default {
  categoryName: 'app',
  components: { Header, Footer, AdvancedSearch, CookieBanner },

  metaInfo () {
    return {
      meta: [
        {
          name: 'description',
          content: this.$t('meta_description')
        }
      ]
    }
  },
  data () {
    return {
      searchClient: null,
      routing: {
        router: historyRouter({
          push (url) {
            window.history.replaceState(window.location.href, '', url)
          }
        }),
        stateMapping: singleIndexMapping(config.advanced_search.index_name)
      },
      categories: [],
      categoriesFetched: false,
      categoriesFooter: [],
      meta: {},
      useFullTemplate: true
    }
  },
  mounted () {
    setInterval(() => {
      this.callSettings()
    }, Number(config.settingsCallInterval) * 60 * 60 * 1000)
    if (config.currency_rate_check_ms !== undefined && config.currency_rate_check_ms !== '0') {
      window.setInterval(() => {
        this.getCurrencyRatesMethod()
      }, config.currency_rate_check_ms)
    }

    // For when page first loads
    this.getCurrencyRatesMethod()
    this.getAllCategories({})
    this.getBrowserLanguage()
    let vm = this

    this.setUTM()
    this.parseJwt().then(function (r) {
      if (config.tracking_enabled === 'true') {
        window.__at_px.init(config.api_url, r ? r.hash : null)
        window.__at_px.fire()
      }
      if (r && r.hash) {
        vm.setHash(r.hash)
        vm.setEmail(r.email)
        apiService.getUser().then(r => {
          vm.setGiftCard(r.data.data.user_balance)
          vm.setLimits(r.data.data.user_limit)
          vm.setFavourites(r.data.data.favourite_tasks)
          vm.setDob(r.data.data.birthday)
          vm.setBuyerOrders(r.data.data.buyer_order_count)
          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'loginStatus': true,
              'registeredIntent': r.data.data.account_type === 1 ? 'buyer' : r.data.data.account_type === 0 ? 'seller' : null,
              'confirmedIntent':
                r.data.data.gig_count > 0 && r.data.data.buyer_order_count > 0 ? 'confirmedBuyerAndSeller' : r.data.data.gig_count > 0 ? 'confirmedSeller' : r.data.data.buyer_order_count > 0 ? 'confirmedBuyer' : null
            })
          }
        })
      } else if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'loginStatus': false
        })
      }
    })
  },
  watch: {
    $route (to, from) {
      if (to.name !== from.name) {
        this.meta = to.meta
        this.getAllCategories(this.meta)
      }

      if (config.tracking_enabled === 'true') {
        // Px on route change
        window.__at_px.fire(
          'view',
          null,
          window.location.protocol + '//' + window.location.hostname + to.fullPath,
          window.location.protocol + '//' + window.location.hostname + from.fullPath
        )
      }
      if (this.$route.query.query) {
        this.setSearchOverlay(true)
      } else {
        this.setSearchOverlay(false)
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchAccessToken',
      'parseJwt',
      'setCurrencyRates',
      'setGiftCard',
      'setLimits',
      'setDob',
      'setFavourites',
      'setSearchOverlay',
      'setEmail',
      'setHash',
      'setBuyerOrders',
      'callSettings'
    ]),
    viewMessages () {
      this.$bvToast.hide('message-toast')
    },
    getCurrencyRatesMethod () {
      apiService.getConversionRate().then(response => {
        this.setCurrencyRates(response.data.data)
      })
    },
    getAllCategories (meta) {
      if (!meta.hasOwnProperty('categorise') || meta.categorise === true) {
        apiService.getAllCategories().then((data) => {
          let categories = []
          data.data.data.forEach(function (item) {
            let childCategory = []
            item.child_categories.forEach(function (item) {
              if (item.gig_count !== 0 || item.divider === true) {
                childCategory.push(item)
              }
            })
            if (childCategory.length !== 0) {
              let parentCategory = {
                'parent': item,
                'child': childCategory
              }
              categories.push(parentCategory)
            }
          })

          // To check meta not been updated while first load
          if (!this.meta.hasOwnProperty('categorise') || this.meta.categorise === true) {
            this.categories = categories
          }
          this.categoriesFetched = true
          if (this.$store.getters.isSettingLoaded()) {
            document.getElementById('preload-vue-screen').remove()
          }
        }).catch(() => {
        })
      } else {
        this.categoriesFetched = true
        this.categories = []
      }
    },
    getBrowserLanguage () {
      if (window.localStorage.getItem('language-selected') !== null) {
        return
      }
      let lang = navigator.language.split('-')[0]

      if (lang !== 'en' && lang in config.locale_translated) {
        this.$swal({
          title: config.locale_translated[lang].popup.title,
          text: config.locale_translated[lang].popup.content,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: config.locale_translated[lang].popup.button,
          cancelButtonText: 'Continue in English',
          showLoaderOnConfirm: true,
          preConfirm: (value) => {
            window.localStorage.setItem('language-selected', 'yes')
            if (value) {
              window.localStorage.setItem('language', lang)
              window.location.reload()
              return false
            }
          }
        }).then(() => {
          window.localStorage.setItem('language-selected', 'yes')
        })
      }
    },
    getSearchClient () {
      const options = this.$store.getters.getAdvanceSearch()
      if (options === undefined || options.app_id === undefined || options.public_key === undefined) {
        return {
          search (requests) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: ''
              }))
            })
          }
        }
      }

      if (this.searchClient) {
        return this.searchClient
      }

      const algoliaClient = algoliaSearch(options.app_id, options.public_key)
      const self = this
      this.searchClient = {
        ...algoliaClient,
        search (requests) {
          if (!self.searchOverlayOpen) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: ''
              }))
            })
          }

          return algoliaClient.search(requests)
        }
      }

      return this.searchClient
    },
    setUTM () {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('utm_source') || urlParams.has('utm_medium') || urlParams.has('utm_campaign') || urlParams.has('utm_term') || urlParams.has('utm_content')) {
        localStorage.setItem('utm', JSON.stringify({
          utm_source: urlParams.get('utm_source'),
          utm_medium: urlParams.get('utm_medium'),
          utm_campaign: urlParams.get('utm_campaign'),
          utm_term: urlParams.get('utm_term'),
          utm_content: urlParams.get('utm_content')
        }))
      }
    }
  },
  computed: {
    ...mapState({ searchOverlayOpen: state => state.search.searchOverlayOpen }),
    config () {
      return config
    }
  },
  created () {
    this.fetchAccessToken()
  }
}
</script>

<style lang="scss">
@import url('//fonts.googleapis.com/css?family=Jaldi:400,700&display=swap');
@import url('//fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('//cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css');
@import 'assets/CSS/_variables.scss';

#app {
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $brand-denim;
  background-color: $background-grey;
  overflow-x: clip;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.search-overlay {
  z-index: 111;
  padding-top: 10px;
}

#content {
  min-height: 60vh;
}

.header-item {
  z-index: 49 !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}

#content:after, .nav-bar:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  z-index: 2;
}

#app.sidebar-open #content:after, #app.sidebar-open .nav-bar:after {
  visibility: visible;
  opacity: 1;
}

#app.sidebar-open #mySidenav {
  left: 0;
}

.no-scroll {
  overflow: hidden;
}

#message-toast__toast_outer {
  position: fixed;
  z-index: 99;
  right: 1rem;
  bottom: 0;
}

.primary {
  background-color: $brand-sky !important;
}
.secondary {
  background-color: $brand-denim !important;
}

#message-toast .close {
  color: #fff;
  opacity: 1;
}

#message-toast .toast-header {
  background-color: $brand-denim;
}

#message-toast a {
  color: $brand-denim !important;
}
</style>
