<template>
  <div>
    <h1 class="font-weight-bold">{{ title }}</h1>
    <div class="line-holder mt-4 mb-3" v-if="includeLine">
      <div class="line w-100" />
      <div class="line glow-line" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'h1-tangerine',
  props: {
    title: {
      type: String,
      required: true
    },
    includeLine: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.line-holder {
  overflow: hidden;
  height: 20px;
}

.line {
  height: 2px;
  background: rgb(240, 127, 19);
  background: linear-gradient(90deg, $brand-tangerine 0%, $brand-scarlet 100%);
  border-radius: 4px;
}

.glow-line {
  filter: blur(4px);
  border-radius: 4px;
  height: 2px;
  width: 99%;
  margin: 0 auto;
  opacity: .9;
}
</style>
