export default {
  state: {
    trackingData: {
      'user_review_count': null,
      'user_review_average': null,
      'seller_response': null,
      'brand': null,
      'seller_acceptance': null,
      'category': null,
      'image': 0,
      'video': 0,
      'review_count': 0,
      'review_average': 0,
      'seller_iso': null,
      'seller_date': null
    }
  },
  getters: {
    getTrackingData: (state) => () => {
      return state.trackingData
    }
  },
  mutations: {
    setTrackingData (state, trackingData) {
      state.trackingData = trackingData
    }
  },
  actions: {
    clearTrackingData (context) {
      context.commit('setTrackingData', {
        'user_review_count': null,
        'user_review_average': null,
        'seller_response': null,
        'seller_acceptance': null,
        'category': null,
        'brand': null,
        'image': 0,
        'video': 0,
        'review_count': 0,
        'review_average': 0,
        'seller_iso': null,
        'seller_date': null
      })
    }
  }
}
