import gql from 'graphql-tag'

const threadMessagesCreatedSubscription = gql`
    subscription Message($clientHash: String, $threadHash: String) {
      onCreateMessage(client_hash: $clientHash, thread_hash: $threadHash) {
        thread_hash,
        message_hash
      }
    }
`

export default threadMessagesCreatedSubscription
