<template>
  <div v-if="show" class="position-fixed confetti">
    <div class="wrapper">
      <div v-for="i in 150" :key="i" :class="'confetti-' + i"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confetti',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style lang="scss">
.confetti {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
  z-index: 999;
  height: 100vh;
  .wrapper {
    position: relative;
    min-height: 100%;
  }
  [class|="confetti"] {
    position: absolute;
  }
}

$colors: ($brand-scarlet, $brand-tangerine, $brand-sky);
@for $i from 0 through 150 {
  $w: random(20);
  $l: random(90);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l+"%");
    opacity: random() + 0.5;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
  }
  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l+random(15)+"%");
    }
  }
}
</style>
