import gql from 'graphql-tag'

const notificationsCreatedSubscription = gql`
    subscription notifications($clientHash: String) {
      onCreateNotification(client_hash: $clientHash) {
        client_hash
      }
    }
`

export default notificationsCreatedSubscription
