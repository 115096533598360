export default {
  state: {
    completedDelayedPayments: []
  },
  getters: {
    hasPaymentBeenCompleted: (state) => (hash) => {
      return state.completedDelayedPayments.includes(hash)
    },
    getPaymentsBeenCompleted: (state) => {
      return state.completedDelayedPayments
    }
  },
  mutations: {
    setCompletedDelayedPayments: (state, data) => {
      state.completedDelayedPayments = data
    }
  },
  actions: {
    setCompletedDelayedPayments ({ commit }, data) {
      commit('setCompletedDelayedPayments', data)
    }
  }
}
