<template>
    <div class="first-time-user-modal">
      <b-modal id="user-profile" :title="$t('user_first_time_title')" ref="user-profile" size="md" no-close-on-esc  hide-footer>
        <b-form @submit="onSubmit">
          <!-- Username -->
          <div v-if="errors.username.length > 0">
            <div v-for="(error, index) in errors.username" :key="index" class="alert alert-danger py-1 px-3 my-2">
              <i class="fa-solid fa-triangle-exclamation"></i>
              {{ error }}
            </div>
          </div>

          <div class="field-heading pt-2">{{$t('choose_username')}}</div>
          <b-form-input
            id="username-field"
            v-model="form.username"
            type="text"
            required
            :placeholder="$t('username')"
            class="mt-2"
            @input="errors.username = []"
            @keypress="usernameInputCheck"
            v-on:blur="changeText"
          ></b-form-input>

          <p class="mt-2" >
            {{$t('suggestion')}}
            <span v-if="!showSuggestions" class="spinner-border spinner-border-sm ml-2"/>
            <span class="pr-1" v-else v-for="(username, index) in usernameSuggestionArray" :key="index">
                  <a v-on:click="addUsername(username)" class="text-primary">{{username}}</a>
                  {{index !== 2 ? ' / ' : '' }}
                </span>
          </p>

          <!-- account type -->
          <div v-if="displayAccountType">
            <div class="field-heading mt-3">{{$t('account_type.header')}}</div>
            <div v-if="errors.account_type.length > 0">
              <div v-for="(error, index) in errors.account_type" :key="index" class="alert alert-danger py-1 px-3 my-2">
                <i class="fa-solid fa-triangle-exclamation"></i>
                {{ error }}
              </div>
            </div>
            <b-form-group required class="mt-2">
              <b-form-radio v-model="form.account_type" @change="errors.account_type = []" class="radio" value="0">{{$t('account_type.seller')}}</b-form-radio>
              <b-form-radio v-model="form.account_type" @change="errors.account_type = []" class="radio" value="1">{{$t('account_type.buyer')}}</b-form-radio>
            </b-form-group>
            <small v-show="form.account_type === '0'">
              Please note that the cryptoasset services provided by the AnyTask Platform are outside the scope of the jurisdiction and protection of the Financial Ombudsman Service and the Financial Services Compensation Scheme.
            </small>
          </div>
          <b-button v-if="savingForm" disabled type="submit" class="w-100 mt-3" variant="primary">
            <b-spinner class="" small label="Large Spinner" />
          </b-button>
          <b-button v-else type="submit" class="w-100 mt-3" variant="primary">{{ buttonType === 'purchase' ? $t('continue_payment') : $t('complete')}}</b-button>
        </b-form>
      </b-modal>
    </div>
</template>

<script>
import ApiService from '../../apiService'
import { mapActions } from 'vuex'
import config from '../../config'
import store from '../../store'
export default {
  name: 'FirstTimeUserModal',
  props: {
    ip: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      displayAccountType: true,
      buttonType: localStorage.getItem('signUpType'),
      showSuggestions: false,
      form: {
        username: '',
        account_type: null
      },
      errors: {
        username: [],
        account_type: []
      },
      usernameSuggestionArray: [],
      savingForm: false,
      show: true
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'user-profile') {
        store.dispatch('signOut')
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'user-profile') {
        this.usernameSuggestions()
        if (localStorage.getItem('signUpType') === 'purchase' || localStorage.getItem('signUpType') === 'purchaseTask') {
          this.displayAccountType = false
          this.form.account_type = 1
        }
      }
    })
    if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'registrationDetails'
      })
    }
  },
  methods: {
    ...mapActions([
      'processJwt'
    ]),
    usernameSuggestions () {
      ApiService.getUsernameSuggestions().then((data) => {
        this.usernameSuggestionArray = data.data.data.slice(0, 3)
        this.showSuggestions = true
      })
    },
    addUsername (username) {
      this.form.username = username
    },
    onSubmit (evt) {
      evt.preventDefault()
      this.savingForm = true
      this.validation()
      if (this.errors.username.length < 3 && this.errors.account_type.length < 1) {
        this.errors = {
          username: [],
          account_type: []
        }
        ApiService.patchUser(this.form).then((data) => {
          if (config.tracking_enabled === 'true') {
            window.__at_px.fire('register', this.form.account_type === 1 ? 'buyer' : 'seller')
          }

          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'registrationCompletion',
              'registeredIntent': !this.form.account_type || this.form.account_type === '0' ? 'seller' : 'buyer'
            })
          }

          if (config.tracking_enabled === 'true') {
            let el = document.createElement('img')
            el.src = 'https://tags.w55c.net/rs?id=b53fd3098e2044a5b75179dd870a2c45&t=marketing&sku=' + (!this.account_type || this.account_type === '0' ? 'seller' : 'buyer')
            document.body.append(el)
          }

          localStorage.setItem('accessToken', data.data.data.token)
          this.processJwt().then(() => {
            this.$store.dispatch('removeLoginError').then(() => {
              setTimeout(() => {
                localStorage.removeItem('profile_incomplete')
                if (localStorage.getItem('signUpType') === 'purchase') {
                  localStorage.setItem('signUpType', 'purchaseTask')
                } else if (this.$store.state.user.band !== null && this.form.account_type === '0') {
                  localStorage.setItem('signUpType', 'firstTimeSeller')
                }
                window.location.reload()
              }, 250)
            })
          })
        }).catch((error) => {
          let errors = error.response.data.errors.children
          if (errors.username.errors) {
            document.getElementById('username-field').className += ' border border-danger'
            errors.username.errors.forEach(function (child) {
              this.errors.username.push(child)
            }.bind(this))
          }
          if (errors.account_type.errors) {
            document.getElementById('username-field').className += ' border border-danger'
            errors.account_type.errors.forEach(function (child) {
              this.errors.account_type.push(child)
            }.bind(this))
          }
          this.savingForm = false
        })
      } else {
        this.savingForm = false
        window.scrollTo(0, 0)
      }
    },
    usernameInputCheck (evt) {
      if (/[^A-Za-z0-9]+/g.exec(evt.key)) {
        evt.preventDefault()
      } else if (this.form.username.length === 15) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    validation () {
      let els = document.getElementsByClassName('border-danger')
      while (els.length > 0) {
        els[0].classList.remove('border')
        els[0].classList.remove('border-danger')
      }

      if (this.form.username.length === 0) {
        this.errors.username = [this.$t('first_time_login_error_username_null')]
      } else if (this.form.username.length < 3) {
        this.errors.username = [this.$t('first_time_login_error_username_too_short')]
      } else if (this.form.username.length > 15) {
        this.errors.username = [this.$t('first_time_login_error_username_too_long')]
      } else {
        this.errors.username = []
      }

      if (this.form.account_type === null) {
        this.errors.account_type = [this.$t('first_time_login_error_account_type_not_selected')]
      } else {
        this.errors.account_type = []
      }
    },
    toggleModal () {
      this.$bvModal.hide('user-profile')
    },
    changeText () {
      this.form.username = this.form.username.toLowerCase()
    }
  }
}
</script>

<style scoped>
  .radio {
    padding-left:  1.5rem !important;
  }
</style>

<style>
    #user-profile .modal-body {
        padding: 16px !important;
    }
    #user-profile .modal-title {
      width: 100%;
      text-align: center;
      font-size: 1.6em;
    }
</style>
